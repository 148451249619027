import * as types from './types';

const initialState = {};

function userInfo(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default userInfo;
