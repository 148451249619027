import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Input, OrfiumButton, Select } from 'components';
import { createNumberMask } from 'redux-form-input-masks';
import './PaymentForm.scss';
import { getAvailableText } from '../utils';

const currencyMask = createNumberMask({
  prefix: 'US$ ',
  decimalPlaces: 2,
  locale: 'en-US',
});

class PaymentForm extends React.PureComponent {
  handleSubmit = values => {
    this.props.onSubmit({ values });
  };

  render() {
    const {
      handleSubmit,
      submitting,
      initialValues,
      error,
      valid,
      isWireTransferPayment,
    } = this.props;
    return (
      <Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="AdvancedFilterForm__padding">
            <div className="AdvancedFilterForm__account">
              <Field
                component={Select}
                label="Account"
                name="account_id"
                options={initialValues.accounts.map(acc => ({
                  value: acc.id,
                  text: getAvailableText(acc),
                }))}
                aligment="left"
                padded
              />
            </div>
            <div className="AdvancedFilterForm__payment">
              <Field
                component={Input}
                label="Payment"
                name="payment_amount"
                type="tel"
                placeholder="0,00"
                aligment="left"
                {...currencyMask}
              />
            </div>
            <Field hidden component={Input} label="" name="max_amount" />
          </div>
          {error && <div className="AdvancedFilterForm__error">{error}</div>}
          <p>Note: Orfium processes payment requests within three (3) business days.</p>
          <div className="AdvancedFilterForm__footer">
            <OrfiumButton type="submit" disabled={!valid || submitting || isWireTransferPayment}>
              Request
            </OrfiumButton>
          </div>
        </Form>
      </Fragment>
    );
  }
}

PaymentForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object.isRequired,
  isWireTransferPayment: PropTypes.bool.isRequired,
};

PaymentForm.propTypes = {
  ...PaymentForm.reduxProps,
  onSubmit: PropTypes.func.isRequired,
};

export default PaymentForm;
