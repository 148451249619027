import { SIDEBAR_ASSET_FETCH_REQUEST } from './types';
import { fetchSidebarAssetsFailure, fetchSidebarAssetsSuccess } from './actions';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ALBUM } from 'utils/constants';
import { collectionAPI, folderAPI } from 'api';
import { parseQueryParamsFromURL } from 'utils/common';

const fetchAllSidebarAssetsEpic = action$ =>
  action$.pipe(
    ofType(SIDEBAR_ASSET_FETCH_REQUEST),
    switchMap(({ payload: { params, assetType } }) => {
      const api = assetType === ALBUM ? collectionAPI : folderAPI;
      return from(api.bulk.get(params)).pipe(
        mergeMap(({ next, results }) =>
          of(
            fetchSidebarAssetsSuccess({
              items: results,
              hasMore: !!next,
              params: parseQueryParamsFromURL(next),
              assetType,
            })
          )
        ),
        catchError(() => of(fetchSidebarAssetsFailure(assetType)))
      );
    })
  );

export default {
  fetchAllSidebarAssetsEpic,
};
