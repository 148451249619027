/* eslint-disable prettier/prettier */
import CustomRangeFilterModal from './CustomRangeFilterModal';
import { connect } from 'react-redux';
import { getAppliedFiltersDisplayShow } from 'ducks/ui/appliedFiltersDisplay';

const mapStateToProps = (state) => ({
	shouldResetEarningsPeriodFilter: !getAppliedFiltersDisplayShow(state),
});

export default connect(mapStateToProps, null)(CustomRangeFilterModal);
