import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { negativeCSSclass, amountWithCommas } from './utils';

const SummaryTableListViewCollumnHeaders = props => (
  <React.Fragment>
    <TableHeader label="Month" sortKey="month" {...props} />
    <TableHeader
      label="Gross Earnings"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Transaction Fees"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Orfium Share"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Net Earnings"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Withdrawals"
      sortKey={null}
      className="EarningsTables__cell--centered EarningsTables__lightMediumGrey"
      {...props}
    />
    <TableHeader
      label="End Balance"
      sortKey={null}
      className="EarningsTables__cell--right EarningsTables__mediumGrey"
      {...props}
    />
  </React.Fragment>
);

const SummaryTableListViewColumns = ({ asset: summary }) => (
  <React.Fragment>
    <td>{summary.month}</td>
    <td className="EarningsTables__cell--centered">{amountWithCommas(summary.gross_earnings)}</td>
    <td className={`EarningsTables__cell--centered ${negativeCSSclass(summary.transaction_fees)}`}>
      {amountWithCommas(-summary.transaction_fees) || 0}
    </td>
    <td className={`EarningsTables__cell--centered  ${negativeCSSclass(summary.orfium_share)}`}>
      {amountWithCommas(-summary.orfium_share) || 0}
    </td>
    <td className="EarningsTables__cell--centered">{amountWithCommas(summary.net_earnings)}</td>
    <td
      className={`EarningsTables__cell--centered EarningsTables__lighterGrey ${negativeCSSclass(
        summary.withdrawals
      )}`}
    >
      {amountWithCommas(-summary.withdrawals) || 0}
    </td>
    <td className="EarningsTables__cell--right EarningsTables__lightGrey">
      {amountWithCommas(summary.end_balance)}
    </td>
  </React.Fragment>
);

SummaryTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.summary).isRequired,
};

export { SummaryTableListViewCollumnHeaders, SummaryTableListViewColumns };
