import { omit, without } from 'lodash';
import * as types from './types';

const initialState = {
  byKeyId: {},
  keys: [],
};

function modals(state = initialState, action) {
  switch (action.type) {
    case types.ADD_MODAL: {
      const { type, props } = action.payload;
      const uniqueId = type;

      return {
        byKeyId: {
          ...state.byKeyId,
          [uniqueId]: {
            type,
            props,
          },
        },
        keys: [...state.keys, uniqueId],
      };
    }
    case types.DELETE_MODAL:
      return {
        byKeyId: omit(state.byKeyId, action.payload.modalId),
        keys: without(state.keys, action.payload.modalId),
      };
    default:
      return state;
  }
}

export default modals;
