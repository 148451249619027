import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReportIcons } from 'components';
import { SummaryRevenueFilterForm } from 'components/Forms/FiltersForm';
import { EarningByBase } from 'pages/utils';
import {
  EARNINGS_SUMMARY_LIST_TABLE_VIEW,
  EARNINGS_SUMMARY_FOOTER_TABLE_VIEW,
  FORMS,
  EARNINGS_ENDPOINT,
  FIXED_EARNINGS_PARAMS,
} from 'utils/constants';

import './EarningsSummaryPage.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Summary</div>
    </div>
    <div className="EarningsSummaryPage__center">
      <span className="EarningsSummaryPage__filters">
        <SummaryRevenueFilterForm form={FORMS.EARNINGS_SUMMARY} url={EARNINGS_ENDPOINT.SUMMARY} />
      </span>
      <span className="EarningsSummaryPage__report_icons">
        <ReportIcons url={EARNINGS_ENDPOINT.SUMMARY} />
      </span>
    </div>
  </Fragment>
);

const EarningsSummaryPage = props => {
  // Because I use paginated reducer for no paginated data, I need to pass the initial Params before the the fetchMore function.
  // This is a weird hack. It works. Leave it!!!
  const { changeParams } = props;
  useEffect(() => {
    changeParams({
      params: {
        ...FIXED_EARNINGS_PARAMS,
      },
      shouldFetch: true,
    });
  }, []);

  return (
    <EarningByBase
      {...props}
      form={FORMS.EARNINGS_SUMMARY}
      url={EARNINGS_ENDPOINT.SUMMARY}
      topBar={<HeaderOfPage />}
      tableViewType={EARNINGS_SUMMARY_LIST_TABLE_VIEW}
      footerViewType={EARNINGS_SUMMARY_FOOTER_TABLE_VIEW}
    />
  );
};

EarningsSummaryPage.propTypes = {
  changeParams: PropTypes.func.isRequired,
};

export default EarningsSummaryPage;
