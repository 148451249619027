import types from './types';
import actions from './actions';
import selectors from './selectors';
import { startSubmit, stopSubmit } from 'redux-form';
import scopedEpics from '../factory/scopedEpics';
import { folderAPI, collectionAPI } from 'api';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { clearSelectedAssets } from 'ducks/asset/common/actions';
import { pushInfoSnackbar } from 'ducks/ui/snackbar';
import { removeOverlay } from 'ducks/ui/overlay';
import { handleFormErrors } from 'utils/forms';

const scopedEpicsList = scopedEpics(types, actions, selectors, folderAPI);

const convertFolderToAlbumEpic = action$ =>
  action$.pipe(
    ofType(types.CONVERT_ALBUM_REQUEST),
    switchMap(({ payload: { id, values, onSuccess, formName } }) =>
      concat(
        of(startSubmit(formName)),
        from(collectionAPI.single.create(values)).pipe(
          mergeMap(asset =>
            from(folderAPI.single.delete([id])).pipe(
              mergeMap(() => {
                onSuccess(asset);
                return of(
                  removeOverlay(formName),
                  stopSubmit(formName),
                  actions.deleteAssetsActionSuccess([id]),
                  clearSelectedAssets(),
                  pushInfoSnackbar('Successfully converted your folder to album')
                );
              })
            )
          ),
          catchError(errorData => {
            return of(stopSubmit(formName, handleFormErrors(errorData)));
          })
        )
      )
    )
  );

const generateFolderToAlbumEpic = action$ =>
  action$.pipe(
    ofType(types.GENERATE_ALBUM_REQUEST),
    switchMap(({ payload: { values, onSuccess, formName } }) =>
      concat(
        of(startSubmit(formName)),
        from(collectionAPI.single.create(values)).pipe(
          mergeMap(asset => {
            onSuccess(asset);
            return of(
              removeOverlay(formName),
              stopSubmit(formName),
              actions.addAssetsAction(asset),
              clearSelectedAssets(),
              pushInfoSnackbar('Successfully generated an album from your folder')
            );
          }),
          catchError(errorData => {
            return of(stopSubmit(formName, handleFormErrors(errorData)));
          })
        )
      )
    )
  );

export default {
  ...scopedEpicsList,
  convertFolderToAlbumEpic,
  generateFolderToAlbumEpic,
};
