import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import dayjs from 'dayjs';
import { TableHeader } from 'components/utils/Table';
import { CSSclassForStatus, amountWithCommas } from './utils';

const PaymentsTableListViewColumnHeaders = memo(props => (
  <React.Fragment>
    <TableHeader label="Payment ID" sortKey={null} {...props} />
    <TableHeader label="Date" sortKey={null} className="AssetTable__cell--centered" {...props} />
    <TableHeader
      label="Amount (USD)"
      sortKey="amount"
      className="AssetTable__cell--centered"
      {...props}
    />
    <TableHeader
      label="Status"
      sortKey="status"
      className="AssetTable__cell--centered"
      {...props}
    />
    <TableHeader
      label="Account"
      sortKey="accounts"
      className="EarningsTables__cell--right"
      {...props}
    />
  </React.Fragment>
));

PaymentsTableListViewColumnHeaders.displayName = 'PaymentsTableListViewColumnHeaders';

const PaymentsTableListViewColumns = ({ asset: payment }) => (
  <React.Fragment>
    <td>{payment.payment_id}</td>
    <td className="EarningsTables__cell--centered">
      {dayjs(payment.created).format('DD MMM YYYY')}
    </td>
    <td className="EarningsTables__cell--centered">{amountWithCommas(payment.amount)}</td>
    <td className={`EarningsTables__cell--centered ${CSSclassForStatus(payment.status)}`}>
      {payment.status}
    </td>
    <td className="EarningsTables__cell--right">{payment.selected_account}</td>
  </React.Fragment>
);

PaymentsTableListViewColumns.displayName = 'PaymentsTableListViewColumns';

PaymentsTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.payments).isRequired,
};

export { PaymentsTableListViewColumnHeaders, PaymentsTableListViewColumns };
