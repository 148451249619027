import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSidebarOpenState } from 'ducks/ui/sidebar';
import Sidebar from './Sidebar';

const mapStateToProps = state => ({
  sidebarIsOpen: getSidebarOpenState(state),
});

export default withRouter(connect(mapStateToProps)(Sidebar));
