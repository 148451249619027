import types from './types';
import scopedActions from '../factory/scopedActions';
import { folderAPI } from 'api';
import { simpleAction } from '../../utils';

const allActions = scopedActions(types, folderAPI);

const generateAlbumFromFolderRequest = ({ values, onSuccess = () => {}, formName = null }) =>
  simpleAction(types.GENERATE_ALBUM_REQUEST, {
    values,
    onSuccess,
    formName,
  });

const convertAlbumFromFolderRequest = ({ id, values, onSuccess = () => {}, formName = null }) =>
  simpleAction(types.CONVERT_ALBUM_REQUEST, {
    id,
    values,
    onSuccess,
    formName,
  });

export default {
  ...allActions,
  generateAlbumFromFolderRequest,
  convertAlbumFromFolderRequest,
};
