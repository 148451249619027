import { omit, without } from 'lodash';
import * as types from './types';

const initialState = {
  byKeyId: {},
  keys: [],
};

function overlays(state = initialState, action) {
  switch (action.type) {
    case types.ADD_OVERLAY: {
      const { type, props } = action.payload;
      const uniqueId = type;

      return {
        byKeyId: {
          ...state.byKeyId,
          [uniqueId]: {
            type,
            props,
          },
        },
        keys: [...state.keys, uniqueId],
      };
    }
    case types.DELETE_OVERLAY:
      return {
        byKeyId: omit(state.byKeyId, action.payload.overlayId),
        keys: without(state.keys, action.payload.overlayId),
      };
    default:
      return state;
  }
}

export default overlays;
