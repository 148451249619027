import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { mediaLibAxios as axios, orfiumAxios } from 'api/axiosInstances';
import { merge, sortedUniqBy } from 'lodash';
import {
  FETCH_GENRE_OPTIONS_REQUEST,
  FETCH_SUBGENRE_OPTIONS_REQUEST,
  FETCH_AUTOCOMPLETE_OPTIONS_REQUEST,
} from './types';
import { setGenreOptions, setSubgenreOptions, setArtistOptions } from './actions';

// make sure to only pick the date string (since we don't care about the other fields), while
// making sure that the values are unique (duplicates might exist sometimes)
const handleAddition = data => sortedUniqBy(data.map(datum => datum.title));

const fetchGenreEpic = action$ =>
  action$.pipe(
    ofType(FETCH_GENRE_OPTIONS_REQUEST),
    switchMap(() =>
      from(orfiumAxios.get('/upload/options/categories/')).pipe(
        mergeMap(({ data }) => of(setGenreOptions(handleAddition(data)))),
        catchError(() => of(setGenreOptions([])))
      )
    )
  );

const fetchSubGenreEpic = action$ =>
  action$.pipe(
    ofType(FETCH_SUBGENRE_OPTIONS_REQUEST),
    switchMap(() =>
      from(orfiumAxios.get('/upload/options/subcategories/')).pipe(
        mergeMap(({ data }) => of(setSubgenreOptions(handleAddition(data)))),
        catchError(() => of(setSubgenreOptions([])))
      )
    )
  );

const fetchAutocompleteOptionsEpic = action$ =>
  action$.pipe(
    ofType(FETCH_AUTOCOMPLETE_OPTIONS_REQUEST),
    switchMap(() =>
      from(axios.get('/auto-complete/')).pipe(
        mergeMap(({ data }) =>
          of(setArtistOptions(merge(data.albums.artists, data.recordings.artists)))
        ),
        catchError(() => of(setArtistOptions([])))
      )
    )
  );

export default {
  fetchGenreEpic,
  fetchSubGenreEpic,
  fetchAutocompleteOptionsEpic,
};
