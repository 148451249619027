import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { MODALS as types } from 'utils/constants';
import {
  PreventUnmountModal,
  AdvancedFilterModal,
  CustomRangeFilterModal,
  PaymentFormModal,
  WelcomeModal,
  ImpersonationModal,
} from 'components';
import './ModalController.scss';

// gets the proper component based on type
const mapModalTypeToComponent = type => {
  switch (type) {
    case types.ADVANCED_FILTERS:
      return AdvancedFilterModal;
    case types.CUSTOM_RANGE:
      return CustomRangeFilterModal;
    case types.PREVENT_UNMOUNT:
      return PreventUnmountModal;
    case types.PAYMENT_FORM:
      return PaymentFormModal;
    case types.WELCOME_EARNINGS:
      return WelcomeModal;
    case types.IMPERSONATION:
      return ImpersonationModal;
    default:
      return <h1>There was an error and the content could not be loaded</h1>;
  }
};

function ModalController({ modals }) {
  const transitions = useTransition(modals, modal => modal.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      velocity: 0.5,
    },
  });

  return transitions.map(({ item: modal, props: styles, key }) => {
    const Component = mapModalTypeToComponent(modal.type);
    return (
      <animated.div key={key} style={styles} className="ModalController-transition">
        <Component {...modal.props} />
      </animated.div>
    );
  });
}

ModalController.reduxProps = {
  modals: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string,
      props: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
  removeModal: PropTypes.func.isRequired,
};

ModalController.propTypes = {
  ...ModalController.reduxProps,
};

export default memo(ModalController);
