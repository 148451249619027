import { simpleAction } from '../utils';
import {
  SIDEBAR_ASSET_FETCH_FAILURE,
  SIDEBAR_ASSET_FETCH_REQUEST,
  SIDEBAR_ASSET_FETCH_SUCCESS,
} from './types';

export const fetchSidebarAssetsFailure = assetType =>
  simpleAction(SIDEBAR_ASSET_FETCH_FAILURE, { assetType });

export const fetchSidebarAssetsRequest = ({ params, assetType }) =>
  simpleAction(SIDEBAR_ASSET_FETCH_REQUEST, { params, assetType });

export const fetchSidebarAssetsSuccess = ({ items, hasMore, params, assetType }) =>
  simpleAction(SIDEBAR_ASSET_FETCH_SUCCESS, { items, hasMore, params, assetType });
