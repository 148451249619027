import {
  SHOW_APPLIEDFILTERDISPLAY,
  HIDE_APPLIEDFILTERDISPLAY,
  UPDATE_APPLIEDFILTERDISPLAY,
} from './types';
import { simpleAction } from '../../utils';

export const showAppliedFiltersDisplay = content =>
  simpleAction(SHOW_APPLIEDFILTERDISPLAY, { content });

export const updateAppliedFiltersDisplay = content =>
  simpleAction(UPDATE_APPLIEDFILTERDISPLAY, { content });

export const hideAppliedFiltersDisplay = () => simpleAction(HIDE_APPLIEDFILTERDISPLAY);
