import { createSelector } from 'reselect';
export const __REDUX_STATE_KEY__ = 'snackbar';
export const getReduxStateSlice = state => state.ui[__REDUX_STATE_KEY__];

// retrieves a single snackbar based on its Id
export const getSnackbar = (state, id) => getReduxStateSlice(state).byKeyId[id];

// retrieves an ordered list of all the snackbars
export const getSnackbars = createSelector(
  state => getReduxStateSlice(state),
  snackbarState =>
    snackbarState.keys.map(id => ({
      id,

      // didn't destructure so that the keys are clear to the reader
      content: snackbarState.byKeyId[id].content,
      type: snackbarState.byKeyId[id].type,
    }))
);
