import { mediaLibAxios as axios } from '../axiosInstances';
import { selectApiSubmitableFields } from '../utils';

export default {
  get: (itemId, params = {}, cancelToken) =>
    axios
      .get(`/recordings/${itemId}/`, { params, cancelToken })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
  create: asset =>
    axios
      .post('/recordings/', selectApiSubmitableFields(asset))
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
  update: (itemId, payload) =>
    axios
      .put(`/recordings/${itemId}/`, payload)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
  delete: itemId =>
    axios
      .delete(`/recordings/${itemId}/`)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
};
