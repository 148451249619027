import scopedTypes from '../factory/scopedTypes';

export const SCOPE = 'ALBUM_';

const scopedTypesList = scopedTypes(SCOPE);

const UPLOAD_ALBUM_REQUEST = 'UPLOAD_ALBUM_REQUEST';
const UPLOAD_ALBUM_SUCCESS = 'UPLOAD_ALBUM_SUCCESS';
const UPLOAD_ALBUM_FAIL = 'UPLOAD_ALBUM_FAIL';

export default {
  ...scopedTypesList,
  UPLOAD_ALBUM_REQUEST,
  UPLOAD_ALBUM_SUCCESS,
  UPLOAD_ALBUM_FAIL,
};
