import React from 'react';
import PropTypes from 'prop-types';

class EscHandler extends React.PureComponent {
  componentDidMount() {
    // listen to escape keypresses
    document.addEventListener('keyup', this.handleESCKeypress);
  }

  componentWillUnmount() {
    // stop listening to escape keypresses
    document.removeEventListener('keyup', this.handleESCKeypress);
  }

  handleESCKeypress = e => {
    if (e.key === 'Escape') {
      this.props.callback(e);
    }
  };

  render() {
    return this.props.children;
  }
}

EscHandler.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func.isRequired,
};

export default EscHandler;
