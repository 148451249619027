import React from 'react';
import ContentLoader from 'react-content-loader';
import { usePlaceholderSvgRects } from 'hooks/usePlaceholderSvgRects';

const TablePlaceholder = props => {
  const { rects, placeholderWidth } = usePlaceholderSvgRects();

  return (
    <ContentLoader
      style={{
        transform: 'translate(-2em,-4em)',
      }}
      speed={2}
      width={'100%'}
      height={'550'}
      viewBox={`0 0 ${placeholderWidth} 550`}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      {...props}
    >
      {rects}
    </ContentLoader>
  );
};

export default TablePlaceholder;
