import React from 'react';
import BaseToggleField from '../BaseToggleField';
import { Checkbox } from 'components';
import './CheckboxInput.scss';

function CheckboxInput(props) {
  return (
    <BaseToggleField {...props}>
      {({ fieldProps }) => (
        <React.Fragment>
          <Checkbox
            className="CheckboxInput__control--checkbox"
            checked={Boolean(fieldProps.value)}
            onChange={() => fieldProps.onChange(!fieldProps.value)}
          />
          <input {...fieldProps} hidden />
        </React.Fragment>
      )}
    </BaseToggleField>
  );
}

export default CheckboxInput;
