import { combineReducers } from 'redux';
import reducers, { selectors } from './results';
import { combineEpics } from 'redux-observable';
import { values } from 'lodash';
import { epics as earningsEpics } from './results';

export default combineReducers({
  totalEarnings: reducers.results,
  [selectors.__REDUX_STATE_KEY__]: reducers.list,
});
export const epics = combineEpics(...values(earningsEpics));
