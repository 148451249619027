import dayjs from 'dayjs';

// name of the cookies where the token is stored in
// name of the cookies where the token is stored in
export const __TOKEN_COOKIE__ = `token${process.env.REACT_APP_COOKIE_SUFFIX || ''}`;
export const __IMPERSONATOR_TOKEN_COOKIE__ = `impersonator-token${process.env
  .REACT_APP_COOKIE_SUFFIX || ''}`;

// name of the cookie that stores the account header
export const __ACCOUNT_COOKIE__ = `account${process.env.REACT_APP_COOKIE_SUFFIX || ''}`;

// default image whenever an artwork doesn't exist
export const ORFIUM_DEFAULT_ARTWORK_URL =
  'https://res.cloudinary.com/apilama/image/fetch/w_350,h_350,c_thumb,f_auto,fl_lossy,q_70/https://s3-us-west-2.amazonaws.com/orfium-public/img/orfium_logo-2+(1).jpg';

// default avatar whenever a user doesn't have one
export const ORFIUM_DEFAULT_AVATAR_URL =
  'https://res.cloudinary.com/apilama/image/fetch/w_350,h_350,c_thumb,f_auto,fl_lossy,q_70/https://cdn.orfium.com/img/profile.jpg?v=20151123.1';

// date constants (for display purposes only)
export const DATE_FORMAT = 'DD MMM YYYY';
export const MIN_DATEPICKER_YEAR = '1950';
export const MAX_DATEPICKER_YEAR = new Date().getFullYear() + 1;

// date format of the API (for submission purposes)
export const API_DATE_FORMAT = 'YYYY-MM-DD';

// how many tracks can be uploading simultaneously to S3
export const MAX_PARALLEL_UPLOADS = 3;

// how many tracks can we extract metadata from at the same time
export const MAX_PARALLEL_EXTRACTIONS = 10;

// whenever we have an exchange with the API, we limit what we send it to only the following keys.
// That means that any extra keys (like filenames, upload data, etc.) will be pruned
export const API_SUBMITABLE_FIELDS = [
  'id',
  'type',
  'title',
  'artists',
  'album',
  'genre',
  'subgenre',
  'released_on',
  'label',
  'publisher',
  'mix_version',
  'image_uri',
  'file_uri',
  'dj',
  'performed_on',
  'venue',
  'duration',
  'quality',
  'format',
  'moods',
  'instruments',
  'vocals',
  'tags',
  'upc',
  'ean',
  'isrc',
  'iswc',
  'grid',
  'hfa_code',
  'custom_id',
  'orfium_id',
  'tracklist',
  'recordings',
  'shared',
];

// Default values for the fields of the CSV
export const CSV_FIELDS = {
  id: {
    label: 'ID',
    parse: value => value,
    unparse: value => value,
  },
  title: {
    label: 'Title[required]',
    parse: value => value,
    unparse: value => value,
  },
  artists: {
    label: 'Artists',
    parse: value => (value ? value.join(',') : []),
    unparse: value => (value ? value.split(',').map(v => v.trim()) : ['Unknown Artist']), // set default to unknown artist field
  },
  album: {
    label: 'Album',
    parse: value => value,
    unparse: value => value || 'Unknown Album', // set default to empty albums
  },
  genre: {
    label: 'Genre',
    parse: value => value,
    unparse: value => value,
  },
  subgenre: {
    label: 'Subgenre',
    parse: value => value,
    unparse: value => value,
  },
  released_on: {
    label: 'Release Date',
    parse: value => dayjs(value || undefined).format(API_DATE_FORMAT),
    unparse: value => (value ? dayjs(value).format(API_DATE_FORMAT) : null),
  },
  label: {
    label: 'Label',
    parse: value => value,
    unparse: value => value,
  },
  publisher: {
    label: 'Publisher',
    parse: value => value,
    unparse: value => value,
  },
  image_uri: {
    label: 'Image URL',
    parse: value => value,
    unparse: value => value,
  },
  upc: {
    label: 'UPC',
    parse: value => value,
    unparse: value => value,
  },
  ean: {
    label: 'EAN',
    parse: value => value,
    unparse: value => value,
  },
  isrc: {
    label: 'ISRC',
    parse: value => value,
    unparse: value => value,
  },
  iswc: {
    label: 'ISWC',
    parse: value => value,
    unparse: value => value,
  },
  hfa_code: {
    label: 'HFA Code',
    parse: value => value,
    unparse: value => value,
  },
};

export const ALBUM = 'album';
export const FOLDER = 'folder';
export const RECORDING = 'recording';

export const ALBUM_DISPLAY_NAME = 'Album';
export const FOLDER_DISPLAY_NAME = 'Folder';
export const RECORDING_DISPLAY_NAME = 'Recording';

export const ALBUM_LIST_TABLE_VIEW = 'ALBUM_LIST_TABLE_VIEW';
export const ALBUM_DETAILS_TABLE_VIEW = 'ALBUM_DETAILS_TABLE_VIEW';
export const FOLDER_LIST_TABLE_VIEW = 'FOLDER_LIST_TABLE_VIEW';
export const FOLDER_DETAILS_TABLE_VIEW = 'FOLDER_DETAILS_TABLE_VIEW';
export const RECORDING_LIST_TABLE_VIEW = 'RECORDING_LIST_TABLE_VIEW';
export const EARNINGS_BYASSET_LIST_TABLE_VIEW = 'EARNINGS_BYASSET_LIST_TABLE_VIEW';
export const EARNINGS_BYARTIST_LIST_TABLE_VIEW = 'EARNINGS_BYARTIST_LIST_TABLE_VIEW';
export const EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW =
  'EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW';
export const EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW = 'EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW';
export const EARNINGS_PAYMENTS_LIST_TABLE_VIEW = 'EARNINGS_PAYMENTS_LIST_TABLE_VIEW';
export const EARNINGS_SUMMARY_LIST_TABLE_VIEW = 'EARNINGS_SUMMARY_LIST_TABLE_VIEW';
export const EARNINGS_SUMMARY_FOOTER_TABLE_VIEW = 'ARNINGS_SUMMARY_FOOTER_TABLE_VIEW';
export const EARNINGS_REVENUE_LIST_TABLE_VIEW = 'EARNINGS_REVENUE_LIST_TABLE_VIEW';
export const EARNINGS_REVENUE_FOOTER_TABLE_VIEW = 'EARNINGS_REVENUE_FOOTER_TABLE_VIEW';
// available item types
export const ITEM_TYPES = ['Track', 'Podcast', 'Set'];

// different types of collections that the backend accepts
export const COLLECTION_TYPES = ['LP', 'EP', 'Single'];

// different mix types for a track particularly
export const MIX_TYPES = [
  'Original',
  'Edit',
  'Remix',
  'Mashup',
  'Cover',
  'Public Domain Re-Performance',
];

// default name of the CSV file that will be downloaded
export const DEFAULT_CSV_FILENAME = 'Library Template File.csv';

export const FIXED_PAGINATION_LIMIT = 25;

export const EARNINGS_PERIODS_CONFIG = {
  Overall: { period: 'Overall', subtractValue: undefined, unit: undefined },
  'Last 3 Months': { period: 'Last 3 Months', subtractValue: 3, unit: 'Month' },
  'Last 6 Months': { period: 'Last 6 Months', subtractValue: 6, unit: 'Month' },
  'Last 12 Months': { period: 'Last 12 Months', subtractValue: 1, unit: 'Year' },
  'Custom Range': { period: 'Custom Range', subtractValue: null, unit: null },
};

export const EARNINGS_PERIODS = Object.keys(EARNINGS_PERIODS_CONFIG);

export const ALLOWED_EARNINGS_PERIODS = [...EARNINGS_PERIODS, 'Custom'];

export const REVENUE_STREAMS = [
  'Any',
  'Retail Streaming',
  'Retail Digital Download',
  'Micro-License',
  'Sync-License',
  'Performance',
  'Digital Performance',
  'Direct License Performance',
  'Mechanical Royalties',
  'Neighboring Rights Royalties',
  'Post-Synchronization License',
];

export const YT_LICENSE = ['All', 'Sync License', 'Post-Sync License'];

export const EARNINGS_SERVICES = ['All Services', 'Orfium', 'Youtube'];

export const ADVANCED_FILTERS_EARNINGS = [
  'Any Stream',
  'Retail Streaming',
  'Retail Digital Download',
  'Micro-License',
  'Sync-License',
  'Sync-License (Administrator)',
  'Performance',
  'Digital Performance',
  'Direct License Performance',
  'Mechanical Royalties',
  'Neighboring Rights Royalties',
  'Post-Synchronization License',
];

export const EARNINGS_INITIAL_VALUES = {
  earningsAdvancedFilters: 'Any Stream',
  earningsPeriods: 'Last 12 Months',
  earningsService: 'All Services',
  earningsStream: 'Any',
  earningsLicense: 'All',
  earningsSearch: '',
};

export const EARNINGS_REDUX_FORM_CONFIG = {
  initialValues: EARNINGS_INITIAL_VALUES,
  enableReinitialize: true,
};

export const FORMS = {
  UPLOAD_TRACKS: 'ut',
  UPLOAD_ALBUM: 'ua',
  EDIT_FOLDER: 'ef',
  EDIT_TRACK: 'er',
  CREATE_FOLDER: 'cf',
  CREATE_ALBUM: 'ca',
  EDIT_ALBUM: 'ea',
  GENERATE_ALBUM_FROM_FOLDER: 'gaf',
  CONVERT_FOLDER_TO_ALBUM: 'cfa',
  EARNINGS_BY_ARTIST: 'earningsByArtistPage',
  EARNINGS_BY_ASSET: 'earningsByAssetPage',
  EARNINGS_YOUTUBE_CHANNEL: 'earningsByYoutubeChannel',
  EARNINGS_YOUTUBE_VIDEO: 'earningsByYoutubeVideo',
  EARNINGS_SUMMARY: 'earningsSummary',
  EARNINGS_REVENUE_STREAM: 'earningsRevenueStream',
  EARNINGS_PAYMENT_FORM: 'earningsPaymentForm',
};

export const MODALS = {
  DELETE: 'del',
  ADVANCED_FILTERS: 'adfil',
  ADD_TO_ALBUM: 'aa',
  ADD_TO_FOLDER: 'af',
  PREVENT_UNMOUNT: 'pu',
  SHARE: 'sh',
  CUSTOM_RANGE: 'cr',
  PAYMENT_FORM: 'pf',
  WELCOME_EARNINGS: 'we',
  IMPERSONATION: 'ip',
};

export const CSV_NAMING_CONFIG = {
  '/summary/': 'Summary report',
  '/by-revenue-stream/': 'By Revenue Stream report',
  '/by-youtube-video/': 'By Youtube Video report',
  '/by-youtube-channel/': 'By Youtube Channel report',
  '/by-asset/': 'By Asset report',
  '/by-artist/': 'By Artist report',
};

export const EARNINGS_ENDPOINT = {
  SUMMARY: '/summary/',
  PAYMENT: '/payments/',
  BY_REVENUE: '/by-revenue-stream/',
  BY_YOUTUBE_VIDEO: '/by-youtube-video/',
  BY_YOUTUBE_CHANNEL: '/by-youtube-channel/',
  BY_ASSET: '/by-asset/',
  BY_ARTIST: '/by-artist/',
};

export const WIRE_TRANSFER_TYPE = 'WIRE';
export const PAYPAL_TRANSFER_TYPE = 'PAYPAL';

// MacOS reports audio wma files as video
export const UPLOAD_ACCEPTED_MIME_TYPES = ['audio/*', 'video/x-ms-wma'];

export const LICENCE_PARAMS = {
  'Sync License': 'SYNC_LICENSE',
  'Post-Sync License': 'POST_SYNCHRONIZATION_LICENSE',
  'Retail Streaming': 'RETAIL_STREAMING',
  'Retail Digital Download': 'RETAIL_DIGITAL_DOWNLOAD',
  'Micro-License': 'MICRO_LICENSE',
  'Sync-License': 'SYNC_LICENSE',
  'Sync-License (Administrator)': 'SYNC_LICENSE_ADMIN',
  Performance: 'PERFORMANCE',
  'Digital Performance': 'PERFORMANCE_DIGITAL',
  'Direct License Performance': 'PERFORMANCE_DIRECT_LICENSE',
  'Mechanical Royalties': 'MECHANICAL_ROYALTIES',
  'Neighboring Rights Royalties': 'NEIGHBORING_RIGHTS_ROYALTIES',
  'Post-Synchronization License': 'POST_SYNCHRONIZATION_LICENSE',
  All: '',
  'Any Stream': '',
};

export const SERVICE_PARAMS = {
  Orfium: 'ORFIUM',
  Youtube: 'YOUTUBE',
};

// https://stackoverflow.com/a/38191078
// This is exported as a string, which is to be fed to a RegExp constructor.
// Exporting it as string offers greater flexibility that exporting it as a regex, since it allows
// the consumer to use the pattern in a tailor made regex
export const UUID4_PATTERN =
  '\\b[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89aAbB][0-9a-fA-F]{3}-\\b[0-9a-fA-F]{12}\\b';

/*
  Params for Earnings Pages
 */

const initialPeriodTo = dayjs()
  .endOf('month')
  .format('MM/DD/YYYY');
const InitialPeriodFrom = dayjs()
  .subtract(1, 'year')
  .add(1, 'month')
  .startOf('month')
  .format('MM/DD/YYYY');

export const FIXED_EARNINGS_PARAMS = {
  period_from: InitialPeriodFrom,
  period_to: initialPeriodTo,
  period: 'Last 12 Months',
};
