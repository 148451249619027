import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Select } from 'components';
import { handleMonthsParams } from '../utils';
import { transformStringToDropdownOption } from '../../utils';
import { EARNINGS_PERIODS } from 'utils/constants';
import './SummaryRevenueFilterForm.scss';

function SummaryRevenueFilterForm({ changeParams, pushCustomRangeModal }) {
  const handleMonthChange = value => {
    if (value === 'Custom Range') {
      pushCustomRangeModal();
    } else {
      changeParams({
        params: handleMonthsParams(value),
        shouldFetch: true,
      });
    }
  };

  // added to silence warnings from Redux-Form
  const EmptyFunction = () => {};

  return (
    <Form onSubmit={EmptyFunction}>
      <div className="SummaryRevenueFilterForm__center">
        <span className="SummaryRevenueFilterForm__earnings_periods">
          <Field
            component={Select}
            onChange={handleMonthChange}
            label="Months"
            name="earningsPeriods"
            options={EARNINGS_PERIODS}
            transformOption={transformStringToDropdownOption}
            aligment="left"
            padded
          />
        </span>
      </div>
    </Form>
  );
}

SummaryRevenueFilterForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SummaryRevenueFilterForm.propTypes = {
  ...SummaryRevenueFilterForm.reduxProps,
};

export default SummaryRevenueFilterForm;
