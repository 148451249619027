import { getReduxStateSlice as getModalsState } from 'ducks/ui/modal/selectors';
import { ADD_MODAL } from 'ducks/ui/modal/types';
import { getReduxStateSlice as getOverlaysState } from 'ducks/ui/overlay/selectors';
import { ADD_OVERLAY } from 'ducks/ui/overlay/types';

const filterModalAndOverlayAdditions = store => next => action => {
  // Filter out any actions that try to add a modal or overlay with an ID that already exists in the
  // state, and don't let them reach the reducer

  if (action.type === ADD_MODAL || action.type === ADD_OVERLAY) {
    const state = store.getState();

    if (action.type === ADD_MODAL) {
      const modals = getModalsState(state);
      if (modals.byKeyId[action.payload.type]) {
        return;
      }
    }

    if (action.type === ADD_OVERLAY) {
      const overlays = getOverlaysState(state);
      if (overlays.byKeyId[action.payload.type]) {
        return;
      }
    }
  }

  // In any other case let the action reach the reducer
  next(action);
};

export default filterModalAndOverlayAdditions;
