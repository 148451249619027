import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { MonthPickerInput, OrfiumButton, validators } from 'components';
import './CustomRangeFilterForm.scss';
import { guid } from 'utils/common';

class CustomRangeFilterForm extends React.PureComponent {
  handleSubmit = values => {
    this.props.onSubmit({ values });
  };

  render() {
    const { handleSubmit, submitting, error, valid } = this.props;

    return (
      <Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="AdvancedFilterForm__padding">
            <span className="AdvancedFilterForm__custom_month_range">
              <span className="AdvancedFilterForm__custom_month_range__label">Month Range</span>
              <span className="AdvancedFilterForm__custom_month_range__min">
                <Field
                  component={MonthPickerInput}
                  name="earningsFrom"
                  placeholder="From"
                  dateFormat="MM/DD/YYYY"
                  label=""
                  validate={validators.isRequired}
                />
              </span>
              <span className="AdvancedFilterForm__custom_month_range__max">
                <Field
                  component={MonthPickerInput}
                  name="earningsTo"
                  placeholder="To"
                  dateFormat="MM/DD/YYYY"
                  label=""
                  lastDay
                  validate={validators.isRequired}
                />
              </span>
            </span>
          </div>
          {error && (
            <div className="AdvancedFilterForm__error">
              {error.map(item => (
                <p key={guid}>{item}</p>
              ))}
            </div>
          )}
          <div className="AdvancedFilterForm__footer">
            <OrfiumButton type="submit" disabled={!valid || submitting}>
              {'Apply Filters'}
            </OrfiumButton>
          </div>
        </Form>
      </Fragment>
    );
  }
}

CustomRangeFilterForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CustomRangeFilterForm.propTypes = {
  ...CustomRangeFilterForm.reduxProps,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomRangeFilterForm;
