import { change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { isNil, omitBy } from 'lodash';
import { actions } from 'ducks/earnings/results';
import { showAppliedFiltersDisplay } from 'ducks/ui/appliedFiltersDisplay';
import { EARNINGS_INITIAL_VALUES } from 'utils/constants';
import AdvancedFilterForm from './AdvancedFilterForm';
import { handleAdvancedFiltersParams } from '../utils';
import { isCorrectDateFormatFromTo } from '../../validators';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: formValues => {
      ownProps.closeModal(true);
      dispatch(change(ownProps.form, 'earningsPeriods', 'Custom Range'));
      const params = handleAdvancedFiltersParams(formValues.values, ownProps.url);
      dispatch(showAppliedFiltersDisplay(omitBy(formValues.values, isNil)));
      dispatch(actions.resetAssetsPagination());
      dispatch(
        actions.changeRequestedAssetsParams({
          params,
          shouldFetch: true,
        })
      );
    },
  };
};

const AdvFilterForm = connect(null, mapDispatchToProps)(AdvancedFilterForm);

const AdvancedFilter = reduxForm({
  initialValues: EARNINGS_INITIAL_VALUES,
  destroyOnUnmount: false,
  validate: isCorrectDateFormatFromTo,
})(AdvFilterForm);

export default AdvancedFilter;
