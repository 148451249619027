import { FETCH_USER_INFO_REQUEST, FETCH_PERMISSIONS } from './types';
import { setUserInfo, setPermissions } from './actions';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { orfiumAxios } from 'api/axiosInstances';

const fetchUserEpic = action$ => {
  return action$.pipe(
    ofType(FETCH_USER_INFO_REQUEST),
    switchMap(({ payload: { onSuccess, onError } }) => {
      return from(orfiumAxios.get('/my/info/')).pipe(
        mergeMap(({ data }) => {
          const { impersonations, user_info, social_accounts: socialAccounts } = data;
          onSuccess(data);
          return of(
            setUserInfo({
              ...user_info,
              impersonations,
              socialAccounts,
            })
          );
        }),
        catchError(error => {
          onError(error);
          return of();
        })
      );
    })
  );
};

const fetchPermissionsEpic = action$ => {
  return action$.pipe(
    ofType(FETCH_PERMISSIONS),
    // eslint-disable-next-line no-unused-vars
    switchMap(({ payload: { onSuccess, onError } }) => {
      return from(orfiumAxios.get('/my/permissions/')).pipe(
        mergeMap(({ data }) => {
          let organizations = [];

          const permissions = data.permissions.filter(permission =>
            permission.scope.startsWith('orfium.earnings')
          );

          for (let i = 0; i < permissions.length; i++) {
            organizations = organizations.concat(permissions[i].organizations || []);
          }

          onSuccess(data);
          return of(
            setPermissions({
              organizations,
            })
          );
        }),
        // eslint-disable-next-line no-unused-vars
        catchError(error => {
          //onError(error);
          return of();
        })
      );
    })
  );
};

export default {
  fetchUserEpic,
  fetchPermissionsEpic,
};
