import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Search, Select } from 'components';
import { handleMonthsParams, handleLicensesParams } from '../utils';
import { transformStringToDropdownOption } from '../../utils';
import { EARNINGS_PERIODS, YT_LICENSE } from 'utils/constants';
import './YoutubeChannelFilterForm.scss';
import { debounce } from 'lodash';

function YoutubeChannelFilterForm({ changeParams, pushCustomRangeModal, handleSubmit }) {
  const handleMonthChange = value => {
    if (value === 'Custom Range') {
      pushCustomRangeModal();
    } else {
      changeParams({
        params: handleMonthsParams(value),
        shouldFetch: true,
      });
    }
  };

  const handleLicenseChange = value => {
    changeParams({
      params: {
        ...handleLicensesParams(value),
      },
      shouldFetch: true,
    });
  };
  const handleSearchChange = useCallback(
    debounce(e => {
      changeParams({
        params: { q: e.currentTarget.value ? e.currentTarget.value : '' },
        shouldFetch: true,
      });
    }, 200),
    []
  );

  // added to silence warnings from Redux-Form
  const EmptyFunction = () => {};

  return (
    <Form onSubmit={handleSubmit(EmptyFunction)}>
      <div className="YoutubeChannelFilterForm__center">
        <span className="YoutubeChannelFilterForm__earnings_search">
          <Field
            component={Search}
            onChange={handleSearchChange}
            name="earningsSearch"
            placeholder="Search by Channel ID"
            aligment="left"
            padded
          />
        </span>
        <span className="YoutubeChannelFilterForm__earnings_periods">
          <Field
            component={Select}
            onChange={handleMonthChange}
            label="Months"
            name="earningsPeriods"
            options={EARNINGS_PERIODS}
            transformOption={transformStringToDropdownOption}
            aligment="left"
            padded
          />
        </span>
        <span className="YoutubeChannelFilterForm__revenue">
          <Field
            component={Select}
            onChange={handleLicenseChange}
            label="License"
            name="earningsLicense"
            options={YT_LICENSE}
            transformOption={transformStringToDropdownOption}
            padded
            aligment="left"
          />
        </span>
      </div>
    </Form>
  );
}

YoutubeChannelFilterForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

YoutubeChannelFilterForm.propTypes = {
  ...YoutubeChannelFilterForm.reduxProps,
};

export default YoutubeChannelFilterForm;
