import * as types from './types';
import { types as recordingTypes } from '../recordings';
import { types as albumTypes } from '../albums';
import { types as folderTypes } from '../folders';
import * as searchTypes from 'ducks/global/search';
import { xor } from 'lodash';

export const initialState = {
  selectedIds: [],
};

function asset(state = initialState, action) {
  switch (action.type) {
    case types.ASSET_TOGGLE:
      return {
        ...state,
        selectedIds: xor(state.selectedIds, action.payload),
      };
    case types.ASSET_SELECTION_CLEAR:
    case searchTypes.SEARCH:
      return {
        ...state,
        selectedIds: [],
      };
    case recordingTypes.LIST_RESET:
    case albumTypes.LIST_RESET:
    case folderTypes.LIST_RESET:
    case albumTypes.DETAILS_RESET:
    case folderTypes.DETAILS_RESET:
      return initialState;
    default:
      return state;
  }
}

export default asset;
