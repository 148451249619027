import { reset } from 'redux-form';
import { actions } from 'ducks/earnings/results';
import {
  getAppliedFiltersDisplayText,
  hideAppliedFiltersDisplay,
} from 'ducks/ui/appliedFiltersDisplay';
import { connect } from 'react-redux';
import AppliedFiltersDisplay from './AppliedFiltersDisplay';

const mapStateToProps = state => ({
  values: getAppliedFiltersDisplayText(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetAppliedFilters: () => {
      dispatch(hideAppliedFiltersDisplay());
      dispatch(actions.resetAssetsListState());
      dispatch(reset(ownProps.form));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliedFiltersDisplay);
