import { earningsAxios as axios } from '../axiosInstances';
import changeReportName from 'utils/changeReportName';
export default {
  getPaymentAccountsAPI: () =>
    axios
      .get(`/payment-accounts/`)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
  getCurrentBalance: () =>
    axios
      .get(`/payment-user-balance/`)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  createPayment: (account_id, payment_amount) => {
    return axios
      .post('/payments/', { account_id, payment_amount })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      });
  },
  deletePaypalAccount: accountID => {
    return axios
      .post('/paypal-account-disable/', { account_id: accountID })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      });
  },
  getExportCSV: ({ url, ...params }) => {
    const removeTheseParamsKeys = ['url', 'page'];
    const cleanParams = Object.keys(params)
      .filter(key => !removeTheseParamsKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {});

    return axios
      .get(url, {
        responseType: 'blob',
        params: {
          ...cleanParams,
        },
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        const reportName = changeReportName(url, params);
        link.setAttribute('download', reportName + '.csv');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        throw error.response ? error.response.data : error;
      });
  },
};
