export const negativeCSSclass = value => (value === 0 ? '' : 'EarningsTables__negative');
export const CSSclassForStatus = value =>
  value === 'REJECTED' || value === 'FAILED'
    ? 'EarningsTables__negative'
    : 'EarningsTables__positive';

// toLocaleString was not used because is inconsistent in different browsers and verisons of them.
export const amountWithCommas = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const checkEmptyField = (value = '', fallBackText = '') => {
  let fieldValue = value;
  const fieldValueIsNotCorrect = !value || value === '-';

  if (fieldValueIsNotCorrect) {
    fieldValue = `Unknown ${fallBackText}`;
  }

  return fieldValue;
};
