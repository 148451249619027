import { simpleAction } from 'ducks/utils';
import {
  FETCH_GENRE_OPTIONS_REQUEST,
  FETCH_SUBGENRE_OPTIONS_REQUEST,
  FETCH_AUTOCOMPLETE_OPTIONS_REQUEST,
  SET_GENRE_OPTIONS,
  SET_SUBGENRE_OPTIONS,
  SET_ARTIST_OPTIONS,
} from './types';

// we export it for testing
export const setGenreOptions = payload => simpleAction(SET_GENRE_OPTIONS, payload);

// we export it for testing
export const setSubgenreOptions = payload => simpleAction(SET_SUBGENRE_OPTIONS, payload);

export const setArtistOptions = payload => simpleAction(SET_ARTIST_OPTIONS, payload);

export const fetchGenreOptionsRequest = () => simpleAction(FETCH_GENRE_OPTIONS_REQUEST);

export const fetchSubgenreOptionsRequest = () => simpleAction(FETCH_SUBGENRE_OPTIONS_REQUEST);

export const fetchAutocompleteOptionsRequest = () =>
  simpleAction(FETCH_AUTOCOMPLETE_OPTIONS_REQUEST);
