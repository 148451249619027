import { FIXED_PAGINATION_LIMIT } from 'utils/constants';
import { has, uniqBy } from 'lodash';
import { ROUTE_CHANGE } from '../../../router';

export const initialState = {
  items: [],
  isInitialFetch: true,
  hasMore: false,
  isLoading: true, // this is to define if we are showing loading or not // TODO: investigate
  isFetching: false, // this is to define if data is fetching - is different to loading because there are two different states
  params: {},
};

function scopedListReducer(
  types,
  initialParams = {
    limit: FIXED_PAGINATION_LIMIT,
    offset: 0,
  }
) {
  const scopedReducerInitialState = {
    ...initialState,
    isLoading: true,
    params: initialParams,
  };

  return (state = scopedReducerInitialState, action) => {
    switch (action.type) {
      case types.LIST_FETCH_REQUEST:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          isFetching: true,
        };
      case types.LIST_FETCH_SUCCESS: {
        const { hasMore, assets: fetchedAssets } = action.payload;
        let newList = state.isLoading ? [...state.items, ...fetchedAssets] : fetchedAssets;
        if (has(newList[0], 'id')) {
          newList = uniqBy(newList, item => item.id);
        }
        return {
          ...state,
          isInitialFetch: false,
          isLoading: false,
          isFetching: false,
          hasMore,
          items: newList,
        };
      }
      case types.LIST_FETCH_FAILURE:
        return {
          ...state,
          isInitialFetch: false,
          hasMore: false,
          isFetching: false,
          isLoading: false,
        };
      case types.LIST_CHANGE_PARAMS:
        return {
          ...state,
          params: {
            ...state.params,
            ...action.payload.params,
          },
        };
      case types.LIST_RESET_PAGINATION_PARAMS:
        return {
          ...state,
          params: {
            ...state.params,
            ...scopedReducerInitialState.params,
          },
        };
      case types.DELETE_SUCCESS:
        return {
          ...state,
          items: state.items.filter(asset => !action.payload.includes(asset.id)),
          params: {
            ...state.params,
            offset: Number(state.params.offset) - action.payload.length,
          },
        };
      case types.UPDATE_SUCCESS:
        return {
          ...state,
          items: state.items.map(asset => {
            const updatedAsset = action.payload.find(updatedAsset => updatedAsset.id === asset.id);
            return updatedAsset ? { ...asset, ...updatedAsset } : asset;
          }),
        };
      case types.LIST_ADD: {
        // we don't want to add items to a paginated list if we haven't fetched something from the
        // server already. If the state is pristine then we don't add items here, since when fetched
        // from the server they will come on their own
        if (state.isInitialFetch) {
          return state;
        }

        return {
          ...state,
          items: [...action.payload, ...state.items],
          params: {
            ...state.params,
            offset: Number(state.params.offset) + action.payload.length,
          },
        };
      }
      case ROUTE_CHANGE:
      case types.LIST_RESET:
        return scopedReducerInitialState;
      case types.FETCH_EXPORT_CSV: {
        return {
          ...state,
          isExporting: true,
        };
      }
      case types.FETCH_EXPORT_SUCCESS: {
        return {
          ...state,
          isExporting: false,
        };
      }
      default:
        return state;
    }
  };
}

export default scopedListReducer;
