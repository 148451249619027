import { createSelector } from 'reselect';

export const __REDUX_STATE_KEY__ = 'modals';
export const getReduxStateSlice = state => state.ui[__REDUX_STATE_KEY__];

// retrieves an ordered list of all the modals
export const getModals = createSelector(
  state => getReduxStateSlice(state),
  modalState =>
    modalState.keys.map(id => {
      const modal = modalState.byKeyId[id];
      return {
        id,
        type: modal.type,
        props: modal.props,
      };
    })
);
