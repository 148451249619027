import { AdvancedFilters, ReportIcons } from 'components';
import { AssetFilterForm } from 'components/Forms/FiltersForm';
import React, { Fragment } from 'react';
import { EARNINGS_BYARTIST_LIST_TABLE_VIEW, EARNINGS_ENDPOINT, FORMS } from 'utils/constants';
import { EarningByBase } from '../utils';
import './EarningsByArtistVideo.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Earnings By Artist</div>
    </div>
    <div className="EarningsByArtistVideo__center">
      <span className="EarningsByArtistVideo__filters">
        <AssetFilterForm
          url={EARNINGS_ENDPOINT.BY_ARTIST}
          form={FORMS.EARNINGS_BY_ARTIST}
          placeholderSearchText="Search by artist name"
        />
      </span>
      <span className="EarningsByArtistVideo__advanced_button">
        <AdvancedFilters url={EARNINGS_ENDPOINT.BY_ARTIST} form={FORMS.EARNINGS_BY_ARTIST} />
      </span>
      <ReportIcons url={EARNINGS_ENDPOINT.BY_ARTIST} />
    </div>
  </Fragment>
);

const EarningsByArtistVideo = props => (
  <EarningByBase
    {...props}
    form={FORMS.EARNINGS_BY_ARTIST}
    topBar={<HeaderOfPage />}
    url={EARNINGS_ENDPOINT.BY_ARTIST}
    tableViewType={EARNINGS_BYARTIST_LIST_TABLE_VIEW}
  />
);

export default EarningsByArtistVideo;
