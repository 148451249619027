import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { AdvancedFilterForm } from 'components/Forms';
import './AdvancedFilterModal.scss';

const AdvancedFilterModal = ({ hide, form, url }) => (
  <Modal title="Advanced Filters" onClose={hide} data-testid="advancedfilter-modal">
    <div className="AdvancedFilterModal">
      <AdvancedFilterForm closeModal={hide} form={form} url={url} />
    </div>
  </Modal>
);

AdvancedFilterModal.propTypes = {
  hide: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AdvancedFilterModal;
