import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const withMemoizedRouteInstance = Component => {
  function MemoizationEnhancedComponent({
    location: __location,
    match: __match,
    history: __history,
    ...rest
  }) {
    return <Component {...rest} />;
  }

  MemoizationEnhancedComponent.propTypes = {
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
  };

  const shouldUseMemoizedInstance = (oldProps, newProps) =>
    oldProps.location.pathname === newProps.location.pathname;

  return withRouter(memo(MemoizationEnhancedComponent, shouldUseMemoizedInstance));
};

export default withMemoizedRouteInstance;
