import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CSVSVG } from 'assets/xls.svg';
import './ReportIcons.scss';

export const ReportIcons = ({ changeParams, params, isExporting }) => {
  const exportingCssClass = isExporting ? 'ReportIcons--exporting' : '';
  return (
    <div
      role="button"
      tabIndex={0}
      className={`ReportIcons ${exportingCssClass}`}
      onClick={() => !isExporting && changeParams(params)}
      data-testid="reportcsv"
    >
      <span className={`ReportIcons__text`}>Download</span>
      <span rel="noopener noreferrer" target="_blank" className={`ReportIcons__xls`}>
        <CSVSVG />
      </span>
    </div>
  );
};

ReportIcons.defaultProps = {
  isExporting: false,
};

ReportIcons.propTypes = {
  changeParams: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
  isExporting: PropTypes.bool,
};

export default ReportIcons;
