import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AdvancedFilterButton } from './subcomponents';
import { MODALS } from 'utils/constants';

function AdvancedFilters({ form, pushModal, removeModal, url }) {
  const hideAdvancedFiltersModal = useCallback(() => removeModal(MODALS.ADVANCED_FILTERS), [form]);
  const showAdvancedFiltersModal = useCallback(
    () =>
      pushModal({
        type: MODALS.ADVANCED_FILTERS,
        props: { form, hide: hideAdvancedFiltersModal, url },
      }),
    [form]
  );

  return <AdvancedFilterButton onClick={showAdvancedFiltersModal} />;
}

AdvancedFilters.reduxProps = {
  pushModal: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
};

AdvancedFilters.propTypes = {
  ...AdvancedFilters.reduxProps,
  form: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default memo(AdvancedFilters);
