// We don't add "FETCH_", "DELETE_", and "EDIT_" types since we won't ever manipulate these options
// and we don't want to "hook" to the fetching of these resources (so we are saving additional
// renders)
export const FETCH_GENRE_OPTIONS_REQUEST = 'SET_GENRE_OPTIONS_REQUEST';
export const FETCH_SUBGENRE_OPTIONS_REQUEST = 'SET_SUBGENRE_OPTIONS_REQUEST';
export const FETCH_AUTOCOMPLETE_OPTIONS_REQUEST = 'SET_ARTIST_OPTIONS_REQUEST';

export const SET_GENRE_OPTIONS = 'SET_GENRE_OPTIONS';
export const SET_SUBGENRE_OPTIONS = 'SET_SUBGENRE_OPTIONS';
export const SET_ARTIST_OPTIONS = 'SET_ARTIST_OPTIONS';
