import reactGA from 'react-ga';

const analytics = {
  // initialize the analytics module (to be used only once!)
  init: () => {
    reactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      testMode: !process.env.REACT_APP_ENABLE_TRACKING,
    });
  },

  // log the current page
  trackCurrentPage: ({ pathname, hash, search }) => {
    reactGA.pageview(pathname + hash + search);
  },
};

export default analytics;
