import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIconSVG } from 'assets/check-icon.svg';
import './Checkbox.scss';

class Checkbox extends React.PureComponent {
  // we cache it se we can utilize the same instance instead of rendering a new one on every mount
  // on a page with a lot of checkboxes flickered, it increases performance
  static CheckedSVG = (<CheckIconSVG />);

  handleClick = () => {
    this.props.onChange(!this.props.checked);
  };

  render() {
    const { checked, className, disabled, style } = this.props;
    const btnClassName = `Checkbox ${className} ${checked ? 'Checkbox--checked' : ''}`;
    return (
      <button
        type="button"
        disabled={disabled}
        onClick={this.handleClick}
        className={btnClassName}
        style={style}
        role="checkbox"
        aria-checked={checked}
      >
        <div className="Checkbox__handle">{Checkbox.CheckedSVG}</div>
      </button>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  style: {},
  onChange: () => {},
  checked: false,
  disabled: false,
};

export default Checkbox;
