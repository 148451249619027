import React from 'react';
import PropTypes from 'prop-types';
import BaseTextField from '../BaseTextField';
import { MonthPicker } from 'components';
import { addYears, getYear } from 'date-fns';

const currentDate = new Date();
const nextYear = getYear(addYears(currentDate, 1));

function MonthPickerInput(props) {
  return (
    <BaseTextField {...props}>
      {({ fieldProps }) => (
        <React.Fragment>
          <MonthPicker
            allowedYears={{ after: 2014, before: nextYear }}
            changeValue={fieldProps.onChange}
            onBlur={fieldProps.onBlur}
            onFocus={fieldProps.onFocus}
            dateFormat={props.dateFormat}
            lastDay={props.lastDay}
          >
            <input
              readOnly
              label={fieldProps.label}
              value={fieldProps.value}
              placeholder={fieldProps.placeholder}
            />
          </MonthPicker>
        </React.Fragment>
      )}
    </BaseTextField>
  );
}

MonthPickerInput.defaultProps = {
  lastDay: false,
};
MonthPickerInput.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  lastDay: PropTypes.bool,
};

export default MonthPickerInput;
