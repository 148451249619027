import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchUserInfoRequest, fetchPermissions } from 'ducks/user';
import { pushModal, removeModal } from 'ducks/ui/modal';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import { appInit } from 'ducks/router';
import WithReduxRouter from 'utils/reduxRouter';
import {
  Header,
  Sidebar,
  Snackbar,
  Container,
  ErrorBoundary,
  OverlayController,
  ModalController,
} from './components';
import './App.scss';
import { MODALS } from './utils/constants';

const App = ({ fetchUserInfoRequest, fetchPermissions, history, pushModal, removeModal }) => {
  useEffect(() => {
    fetchUserInfoRequest();
    fetchPermissions();

    // assume we've already shown welcome modal to the user,
    // since Earnings Dashboard v2 is now more than 2 years in production
    const showedModal = true; // window.localStorage.getItem('welcomeModal');
    if (!showedModal) {
      pushModal({
        type: MODALS.WELCOME_EARNINGS,
        props: { hide: hideAdvancedFiltersModal },
      });
    }
  }, []);

  const hideAdvancedFiltersModal = useCallback(() => {
    removeModal(MODALS.WELCOME_EARNINGS);
    window.localStorage.setItem('welcomeModal', 'false');
  }, []);

  return (
    <WithReduxRouter history={history}>
      <Router history={history}>
        <div className="App">
          <Header />
          <Container>
            <div className="App__main">
              <Sidebar />
              <div className="App__content">
                <ErrorBoundary fallbackToErrorScreen>
                  <Routes />
                </ErrorBoundary>
              </div>
            </div>
          </Container>
          <Snackbar />
          <OverlayController />
          <ModalController />
        </div>
      </Router>
    </WithReduxRouter>
  );
};

App.reduxProps = {
  fetchUserInfoRequest: PropTypes.func.isRequired,
  fetchPermissions: PropTypes.func.isRequired,
};

App.propTypes = {
  ...App.reduxProps,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapDispatchToProps = {
  fetchUserInfoRequest,
  fetchPermissions,
  appInit,
  pushModal,
  removeModal,
};

export default connect(null, mapDispatchToProps)(App);
