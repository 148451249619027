import { combineReducers } from 'redux';
import { isObject } from 'lodash';
import scopedListReducer from './scopedListReducer';
import scopedDetailsReducer from './scopedDetailsReducer';

function scopedReducers(types, { details = true, list = true } = {}, initialParams) {
  if (!isObject(types)) {
    console.error('No redux action types have been submitted'); // eslint-disable-line
  }

  const combinedReducers = {};
  if (details) {
    combinedReducers.details = scopedDetailsReducer(types);
  }
  if (list) {
    combinedReducers.list = scopedListReducer(types, initialParams);
  }

  return combineReducers(combinedReducers);
}

export default scopedReducers;
