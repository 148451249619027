import types from './types';
import scopedActions from 'ducks/asset/factory/scopedActions';
import { earningsAPI } from 'api';
import { simpleAction } from '../../utils';

/*eslint-disable no-irregular-whitespace */

/*

So scopedActions became a const and was exported along all other new actions that payment needed.

getPaymentsAccounts = it’s the action that causes the GET on /payments​-accounts/ endpoint. The recieved data it recieves are presented inside the AvailableAccounts

fetchPaymentsAccountsSuccess = is the success of the request and pass the data to the reducer

getUserBalance = it’s the action that causes the GET on /payments-user-balance​/ endpoint. The recieved data are presented inside the BalanceManager

fetchUserBalanceSuccess= is the success of the request and pass the data to the reducer

addPayment = it's the action that causes the POST on /payments/ and is fired by the submit of the PaymentForm

fetchPaymentFailure= is the failure of a request about payments. I found no reason to make more failure actions for each request.

 */

/*eslint-enable no-irregular-whitespace */

const allActions = scopedActions(types, earningsAPI);
const getPaymentsAccounts = () => simpleAction(types.GET_PAYMENT_ACCOUNTS);
const fetchPaymentsAccountsSuccess = asset => simpleAction(types.PAYMENT_ACCOUNTS_SUCCESS, asset);
const getUserBalanceRequest = () => simpleAction(types.GET_USER_BALANCE);
const fetchUserBalanceSuccess = asset => simpleAction(types.GET_USER_BALANCE_SUCCESS, asset);
const addPaymentRequest = ({ values, formName }) =>
  simpleAction(types.ADD_PAYMENTS, { values, formName });

const fetchPaymentFailure = () => simpleAction(types.FETCH_PAYMENTS_FAILURE);
const deletePaymentAccountRequest = accountId =>
  simpleAction(types.DELETE_PAYMENTS_ACCOUNT, { accountId });
const fetchExportCSVRequest = params => simpleAction(types.FETCH_EXPORT_CSV, params);
const fetchExportCSVSuccss = () => simpleAction(types.FETCH_EXPORT_SUCCESS);
const fetchExportCSVFailure = () => simpleAction(types.FETCH_EXPORT_FAILURE);
export default {
  ...allActions,
  getPaymentsAccounts,
  fetchPaymentsAccountsSuccess,
  getUserBalanceRequest,
  fetchUserBalanceSuccess,
  addPaymentRequest,
  fetchPaymentFailure,
  deletePaymentAccountRequest,
  fetchExportCSVRequest,
  fetchExportCSVSuccss,
  fetchExportCSVFailure,
};
