import React from 'react';
import PropTypes from 'prop-types';
import { animated, config, Transition } from 'react-spring/renderprops.cjs';
import { SidebarContent } from './subcomponents';
import styles from './Sidebar.scss';

// the fallback to "0" is for testing purposes
const width = parseInt(styles.sidebarWidth) || 0;

function Sidebar({ sidebarIsOpen, location }) {
  return (
    <Transition
      items={sidebarIsOpen}
      native
      from={{ transform: 'translate3d(-100%, 0, 0)', width: 0, opacity: 0 }}
      enter={{ transform: 'translate3d(0, 0, 0)', width: width, opacity: 1 }}
      leave={{ transform: 'translate3d(-100%, 0, 0)', width: 0, opacity: 0 }}
      config={{
        ...config.default,
        restDisplacementThreshold: 0.1,
        restSpeedThreshold: sidebarIsOpen ? config.default.restSpeedThreshold : 1,
      }}
    >
      {sidebarIsOpen =>
        sidebarIsOpen &&
        ((styles /* eslint-disable-line react/display-name */) => (
          <animated.aside className="Sidebar" style={styles} data-testid="sidebar">
            <SidebarContent currentPath={location.pathname} />
          </animated.aside>
        ))
      }
    </Transition>
  );
}

Sidebar.routerProps = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

Sidebar.reduxProps = {
  sidebarIsOpen: PropTypes.bool.isRequired,
};

Sidebar.propTypes = {
  ...Sidebar.routerProps,
  ...Sidebar.reduxProps,
};

export default Sidebar;
