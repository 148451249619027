import { createSelector } from 'reselect';

export const __REDUX_STATE_KEY__ = 'overlays';
export const getReduxStateSlice = state => state.ui[__REDUX_STATE_KEY__];

// retrieves an ordered list of all the overlays
export const getOverlays = createSelector(
  state => getReduxStateSlice(state),
  overlayState =>
    overlayState.keys.map(id => ({
      id,

      // didn't destructure so that the keys are clear to the reader
      type: overlayState.byKeyId[id].type,
      props: overlayState.byKeyId[id].props,
    }))
);
