import { mediaLibAxios as axios } from '../axiosInstances';
import { selectApiSubmitableFields } from '../utils';

export default {
  get: (collectionId, params = {}, cancelToken) =>
    axios
      .get(`/albums/${collectionId}/`, { params, cancelToken })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  create: asset =>
    axios
      .post('/albums/', [selectApiSubmitableFields(asset)])
      .then(({ data }) => data[0])
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  update: (collectionId, payload) =>
    axios
      .put(`/albums/${collectionId}/`, payload)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  delete: collectionId =>
    axios
      .delete(`/albums/${collectionId}/`)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
};
