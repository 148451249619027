import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { animated, useTransition } from 'react-spring';
import './OverlayController.scss';

// gets the proper component based on type
const mapOverlayTypeToComponent = type => {
  switch (type) {
    default:
      return <h1>There was an error and the content could not be loaded</h1>;
  }
};

function OverlayController({ overlays }) {
  const transitions = useTransition(overlays, overlay => overlay.id, {
    from: { transform: 'translate3d(0, 100vh, 0)' },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: { transform: 'translate3d(0, 100vh, 0)' },
  });

  return transitions.map(({ item: overlay, props: styles, key }) => {
    const Component = mapOverlayTypeToComponent(overlay.type);
    return (
      <animated.div key={key} style={styles} className="OverlayController-transition">
        <Component {...overlay.props} />
      </animated.div>
    );
  });
}

OverlayController.defaultProps = {
  overlays: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      props: PropTypes.objectOf(PropTypes.any).isRequired,
    })
  ).isRequired,
  removeOverlay: PropTypes.func.isRequired,
};

OverlayController.propTypes = {
  ...OverlayController.reduxProps,
};

export default memo(OverlayController);
