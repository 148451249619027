import Cookie from 'js-cookie';

const forcedOptions = {
  path: '/',
  domain: process.env.REACT_APP_COOKIE_DOMAINS,
};

function set(name, value, options = {}) {
  Cookie.set(name, value, { ...options, ...forcedOptions });
}

function remove(name, options = {}) {
  Cookie.remove(name, { ...options, ...forcedOptions });
}

function get(name) {
  return Cookie.get(name);
}

export default {
  set,
  remove,
  get,
};
