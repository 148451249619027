import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { amountWithCommas, checkEmptyField } from './utils';

const ByartistTableListViewColumnHeaders = props => (
  <React.Fragment>
    <TableHeader label="Artist" sortKey="artists" {...props} />
    <TableHeader
      label="Months"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Service"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Reported Earnings"
      sortKey="reported_earnings"
      className="EarningsTables__cell--centered"
      {...props}
    />

    <TableHeader
      label="Your Earnings"
      sortKey="your_earnings"
      className="EarningsTables__cell--right EarningsTables__mediumGrey"
      {...props}
    />
  </React.Fragment>
);

const ByArtistTableListViewColumns = ({ asset: byArtist }) => (
  <React.Fragment>
    <td>{checkEmptyField(byArtist.artists, 'Name')}</td>
    <td className="EarningsTables__cell--centered">{byArtist.period}</td>
    <td className="EarningsTables__cell--centered">{byArtist.service}</td>
    <td className="EarningsTables__cell--centered">
      {amountWithCommas(byArtist.reported_earnings.toFixed(2))}
    </td>
    <td className="EarningsTables__cell--right EarningsTables__lightGrey">
      {amountWithCommas(byArtist.your_earnings.toFixed(2))}
    </td>
  </React.Fragment>
);

ByArtistTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.byArtist).isRequired,
};

export { ByartistTableListViewColumnHeaders, ByArtistTableListViewColumns };
