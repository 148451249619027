import React from 'react';
import PropTypes from 'prop-types';
import { escapeRegExp, initial, last } from 'lodash';
import { toList } from 'utils/common';
import { ReactComponent as CaretSVG } from 'assets/caret--alt.svg';
import { mediaLibAxios as axios } from 'api/axiosInstances';
import BaseTextField from '../BaseTextField';
import SUIDropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import './Select.scss';

// disabling lazy loading due to react console warnings
// const SUIDropdown = lazy(() =>
//   import(/* webpackChunkName: "sui-dropdown" */ 'semantic-ui-react/dist/commonjs/modules/Dropdown')
// );

const caretIcon = <CaretSVG />;

const isDiff = (a, b) => a.toLowerCase() !== b.toLowerCase();

const includesSameItem = (existingItems, newValue) => {
  return existingItems.some(value => !isDiff(value, newValue));
};

class Dropdown extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { options } = state;
    const {
      input: { value },
      transformOption,
    } = props;

    // initial options are the ones supplied in options. If we have an initial
    // preselected value though, we must have this value in the options as well, so if it's not
    // already included, we include it. This is to combat cases where the options come from an API
    // but haven't arrived yet, while simultaneously the Dropdown has a value. This value won't be
    // displayed (since it can't be found in the options), thus ONLY when there is a valid value
    // AND there are 0 options available, then we will force 1 option: the current value
    if (value && !options.length) {
      return { options: toList(value).map(transformOption) };
    }

    return null;
  }
  constructor(props) {
    super(props);
    const { options, transformOption } = props;

    this.state = {
      // transform the options (possibly response from server) into semantic-ui compatible values.
      // That means that we have to make sure that the `text` and `value` keys exist for each
      // option
      options: options.map(transformOption),
      open: false,
      searchQuery: '',
    };
  }

  componentDidMount() {
    const { remoteUrl } = this.props;
    if (remoteUrl) {
      this.fetchOptionsFromServer(remoteUrl);
    }
  }

  componentDidUpdate(prevProps) {
    const optionsAreDynamic = !!this.props.remoteUrl;
    const { remoteUrl, options } = this.props;

    // if the options are dynamic and not hardcoded
    if (optionsAreDynamic) {
      // if the endpoint under which we get the options has changed
      if (remoteUrl !== prevProps.remoteUrl) {
        this.fetchOptionsFromServer(remoteUrl);
      }

      // else if the options are hardcoded
    } else {
      // if the hardcoded options have changed
      if (options !== prevProps.options) {
        this.setState({
          options: options.map(this.props.transformOption),
        });
      }
    }
  }

  fetchOptionsFromServer(endpoint) {
    // request data from server
    axios.get(endpoint).then(response => {
      // transform the options (possibly response from server) into semantic-ui compatible
      // values. That means that you have to make sure that the `text` and `value` keys
      // exist for each option

      const results = this.props.transformResponse(response.data);
      this.setState({
        options: results.map(this.props.transformOption),
      });
    });
  }

  handleOpen = () => {
    // if we should open-on-focus or the user has typed something in the searchbox, we should show
    // the dropdown. We wrap it in a RAF for two reasons:

    // 1. Performance, since a new node will have to be mounted to the DOM
    // 2. Make sure that `searchQuery` has updated, since in Semantic the `handleOpen` is fired
    // before the `handleSearchChange` is triggered so we read the "previous" value of the search
    // in this event handler (Thus when you type a single character in the searchbbar, this handler
    // will think that you still have no characters and the `if` below might be wrongly evaluated).
    // To avoid that we use an async callback (i.e. RAF). This makes sure
    // that the `handleOpen` is executed after `handleSearchChange` is performed
    requestAnimationFrame(() => {
      if (this.props.openOnFocus || this.state.searchQuery) {
        this.setState({ open: true });
      }
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  // gets called every time clicks on the "Add {value}" dropdown option. It always fires after the
  // `handleChange` method
  handleItemAddition = (__event, { value: newValue }) => {
    const { options } = this.state;
    const { transformOption } = this.props;

    // Check if the tag has been added by the user and was not already existent in our options.
    const newOption = transformOption(newValue);
    if (newOption.value.length) {
      // get the value of all the options
      const optionValues = options.map(({ value }) => value);

      // if it's not existant, then simply add it
      if (!includesSameItem(optionValues, newOption.value)) {
        this.setState(state => ({
          options: [...state.options, newOption],
        }));

        // if it was, replace the existing one with the new version of it (might be the same with
        // different cases,spacing, etc. Just make sure you replace it.
      } else {
        this.setState(state => ({
          options: state.options.map(option =>
            isDiff(option.value, newOption.value) ? option : newOption
          ),
        }));
      }
    }
  };

  // gets called every time a new value is submitted to the Select
  handleChange = (event, { value }) => {
    const { multiple, input } = this.props;

    // if we resetted the value or removed any tags from a multiple Select, then simply update the
    // form and do nothing more
    if (!value || !value.length) {
      return input.onChange(value);
    }

    // `value` contains all the values of the Select if it's a multiple. We attempt to clean it
    let itemThatWasJustAdded = multiple ? last(value) : value;
    if (typeof itemThatWasJustAdded === 'string') {
      itemThatWasJustAdded = itemThatWasJustAdded.trim();
    }

    // trigger the `onChange` event on the input which updates the value of the form-field, only
    // if the value that was added was not already present. In cases of a single-select, there can
    // only be one value present, so this check is not needed
    if (multiple) {
      // get all the existing tags
      const existingTags = initial(value);

      // if our newly added value (string or integer) was not already present in the list of existing
      // tags, then simply add it
      if (!includesSameItem(existingTags, itemThatWasJustAdded)) {
        input.onChange([...existingTags, itemThatWasJustAdded]);

        // if it was already present, we need to replace its previous value with the one we got. This
        // is because the previous value might have different casing, spaces, etc. and we want to
        // keep the last value only. Thus any existing "Orfium" value would be replaced with "ORFIUM"
        // if the user tried to add "ORFIUM".
      } else {
        input.onChange(
          existingTags.map(tag => (isDiff(tag, itemThatWasJustAdded) ? tag : itemThatWasJustAdded))
        );
      }
    } else {
      input.onChange(itemThatWasJustAdded);
    }

    // clear the search query and close the plugin on a successful select. Since we are within
    // an event listener. These two "setState" calls will be batched, but we still keep our
    // separation of concerns :D
    this.setState({ searchQuery: '' }, this.handleClose);

    // make sure to blur the select (SUI Dropdown doesn't do that by default, but I think it should
    // have). We make sure to only do that if the user was focusing the "input" and didn't simply
    // use his mouse to click on an option.
    if (!multiple) {
      event.target.tagName === 'INPUT' && requestAnimationFrame(() => event.target.blur());
    }
  };

  handleSearchChange = (__, { searchQuery }) => {
    this.setState({ searchQuery });
    if (!searchQuery && !this.props.openOnFocus) {
      this.setState({ open: false });
    }
  };

  render() {
    const {
      input,
      placeholder,
      disabled,
      multiple,
      searchable,
      selected,
      renderOption,
      allowAdditions,
      renderLabel,
      maxSearchResults,
      openOnFocus,
    } = this.props;

    const { open, options, searchQuery } = this.state;
    const re = new RegExp(escapeRegExp(searchQuery), 'i');

    // add a key based on the value of each option. Also if a custom renderer has been provided,
    // utilize that in order to properly render the contents of a dropdown
    const enhancedOptions = options
      .filter(option => (maxSearchResults === undefined ? option : re.test(option.text)))
      .slice(0, maxSearchResults === undefined ? options.length : maxSearchResults)
      .map(option => ({
        ...option,
        key: option.value,
        content: renderOption ? renderOption(option) : undefined,
      }));

    const value = input.value || selected || (multiple ? [] : '');
    return (
      <BaseTextField {...this.props}>
        <React.Suspense fallback={<input id={input.name} />}>
          <SUIDropdown
            id={input.name}
            data-testid={`${input.name}-select`}
            selectOnBlur={false}
            selectOnNavigation={false}
            onFocus={input.onFocus}
            name={input.name}
            search={searchable}
            placeholder={placeholder}
            disabled={disabled}
            multiple={multiple}
            value={value}
            onChange={this.handleChange}
            onAddItem={this.handleItemAddition}
            onSearchChange={this.handleSearchChange}
            options={enhancedOptions}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            open={open}
            searchQuery={searchQuery}
            renderLabel={renderLabel}
            allowAdditions={allowAdditions}
            selection
            lazyLoad
            icon={openOnFocus ? caretIcon : undefined}
          />
        </React.Suspense>
      </BaseTextField>
    );
  }
}

Dropdown.propTypes = {
  // the URL that will return the options
  remoteUrl: PropTypes.string,

  // initial Dropdown
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  // the node that will be rendered as a label
  renderLabel: PropTypes.func,

  // whether the Dropdown can be searched. If autocomplete is "true" then this must be "true" as well
  searchable: PropTypes.bool,

  // whether or not we accept multiple values
  multiple: PropTypes.bool,

  // typical input placeholder
  placeholder: PropTypes.string,

  // a function that will return a component that will be rendered for each <option>
  renderOption: PropTypes.func,

  // a function that will transform the response prior to it getting stored in the local state
  transformResponse: PropTypes.func,

  // allow the user to add custom tags
  allowAdditions: PropTypes.bool,

  // whether the Dropdown should open when you click on its "input" (applicable to autocomplete
  // selects only)
  openOnFocus: PropTypes.bool,

  // maximum number of search results. Leave undefined for no maximum
  maxSearchResults: PropTypes.number,

  // a function that MUST return at least a `text` and a `value` prop for each option (if these
  // keys are not already present). Also adding a value in the `image` field will also showcase a
  // nice image. With the
  transformOption: PropTypes.func,
  disabled: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
};

Dropdown.defaultProps = {
  renderLabel: undefined,
  remoteUrl: '',
  transformOption: option => option,
  transformResponse: response => response,
  renderOption: undefined,
  searchable: false,
  disabled: false,
  allowAdditions: false,
  multiple: false,
  placeholder: '',
  selected: undefined,
  maxSearchResults: undefined,
  options: [],
  openOnFocus: true,
};

export default Dropdown;
