/* eslint-disable */
import { orfiumAxios } from 'api/axiosInstances';
import { Input, Modal } from 'components';
import { fetchUserInfoRequest } from 'ducks/user';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { withOrfiumAvatarFallback } from 'utils/common';
import { impersonate } from 'utils/impersonation';
import './ImpersonationModal.scss';

function ImpersonationModal(props) {
  const { hide, form, url, fetchUserInfoRequest, impersonate } = props;

  const [state, setState] = useState({
    searchTerm: '',
    users: [],
    pendingRequests: 0,
  });

  function handleKeyPress(e, value) {
    const searchTerm = value;
    setState(state => {
      return {
        ...state,
        searchTerm: value,
      };
    });

    if (searchTerm.length < 3) {
      setState(state => {
        return {
          ...state,
          users: [],
        };
      });
    } else {
      requestUsers(searchTerm);
    }
  }

  const requestUsers = useMemo(() => {
    return _.debounce(queryTerm => {
      setState(state => {
        return {
          ...state,
          pendingRequests: state.pendingRequests + 1,
        };
      });

      return orfiumAxios
        .get(`/profile/search/?q=${queryTerm}`)
        .then(({ data }) => {
          setState(state => {
            return {
              ...state,
              users: data.results,
              pendingRequests: state.pendingRequests - 1,
            };
          });
        })
        .catch(() => {
          setState(state => {
            return {
              ...state,
              pendingRequests: state.pendingRequests - 1,
            };
          });
        });
    }, 100);
  }, []);

  return (
    <Modal title="Impersonate a user" onClose={hide} data-testid="impersonation-modal">
      <div className="ImpersonationModal">
        <div className="form-wrapper">
          <Form onSubmit={() => {}}>
            <Field
              component={Input}
              placeholder="Search for users..."
              name="search"
              icon="search"
              onChange={handleKeyPress}
            />
          </Form>
        </div>
        <div className="user-container">
          {state.users.map(user => {
            return (
              <button
                key={user.id}
                // style={style}
                onClick={() => {
                  impersonate(user.username).then(hide);
                }}
                className="user-wrapper"
              >
                <img
                  width={50}
                  height={50}
                  alt="Avatar"
                  className="avatar-image"
                  src={withOrfiumAvatarFallback(user.avatar)}
                />
                <div className="user-info">
                  <div className="name">{name}</div>
                  <div className="username">@{user.username}</div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}

ImpersonationModal.propTypes = {
  hide: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default connect(undefined, { impersonate, fetchUserInfoRequest })(
  reduxForm({
    form: 'impersonation-form',
  })(ImpersonationModal)
);
