import { combineReducers } from 'redux';
import { values } from 'lodash';
import albums, {
  __REDUX_STATE_KEY__ as __ALBUMS_REDUX_STATE_KEY__,
  epics as albumEpics,
} from './albums';
import folders, {
  __REDUX_STATE_KEY__ as __FOLDERS_REDUX_STATE_KEY__,
  epics as folderEpics,
} from './folders';
import recordings, {
  __REDUX_STATE_KEY__ as __RECORDINGS_REDUX_STATE_KEY__,
  epics as recordingEpics,
} from './recordings';
import common, { __REDUX_STATE_KEY__ as __COMMON_REDUX_STATE_KEY__ } from './common';
import { combineEpics } from 'redux-observable';

export default combineReducers({
  [__RECORDINGS_REDUX_STATE_KEY__]: recordings,
  [__ALBUMS_REDUX_STATE_KEY__]: albums,
  [__COMMON_REDUX_STATE_KEY__]: common,
  [__FOLDERS_REDUX_STATE_KEY__]: folders,
});

export const epics = combineEpics(
  ...values(recordingEpics),
  ...values(albumEpics),
  ...values(folderEpics)
);
