import React, { useEffect } from 'react';
import { appInit } from 'ducks/router';
import { ReactReduxContext } from 'react-redux';
import { routeChange } from 'ducks/router';

const WithReduxRouter = props => {
  const Context = ReactReduxContext;

  const ReduxRouter = ({ children, store, history }) => {
    useEffect(() => {
      // fire an action during the initial load (this will help us run middlewares that have to do
      // with initial mounts (i.e. analytics, user events, etc.)
      store.dispatch(appInit({ location: history.location }));

      history.listen((location, action) => {
        store.dispatch(routeChange({ location, action }));
      });
    }, []);
    return children;
  };

  return (
    <Context.Consumer>{({ store }) => <ReduxRouter store={store} {...props} />}</Context.Consumer>
  );
};

export default WithReduxRouter;
