import * as types from './types';
import { FIXED_PAGINATION_LIMIT } from 'utils/constants';

const initialAssetState = {
  items: [],
  isInitialFetch: true,
  isFetching: false,
  hasMore: false,
  params: {
    offset: 0,
    limit: FIXED_PAGINATION_LIMIT,
  },
};

function asset(state, action) {
  switch (action.type) {
    case types.SIDEBAR_ASSET_FETCH_SUCCESS:
      return {
        ...state,
        isInitialFetch: false,
        isFetching: false,
        items: [...state.items, ...action.payload.items],
        hasMore: action.payload.hasMore,
        params: {
          ...state.params,
          ...action.payload.params,
        },
      };
    case types.SIDEBAR_ASSET_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SIDEBAR_ASSET_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isInitialFetch: false,
      };
    // TODO: Fix me, needs logic for addition; to add each item at the proper place
    default:
      return state;
  }
}

export const initialState = {
  folder: initialAssetState,
  album: initialAssetState,
};

function sidebar(state = initialState, action) {
  switch (action.type) {
    case types.SIDEBAR_ASSET_FETCH_REQUEST:
    case types.SIDEBAR_ASSET_FETCH_FAILURE:
    case types.SIDEBAR_ASSET_FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.assetType]: asset(state[action.payload.assetType], action),
      };
    default:
      return state;
  }
}

export default sidebar;
