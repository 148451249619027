import React from 'react';
import { Searchbar } from 'components';
import BaseTextField from '../BaseTextField';

function Search(props) {
  return (
    <BaseTextField {...props}>
      {({ fieldProps }) => <Searchbar handleChange={fieldProps.onChange} {...fieldProps} />}
    </BaseTextField>
  );
}

export default Search;
