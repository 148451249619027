import dayjs from 'dayjs';
import { CSV_NAMING_CONFIG } from 'utils/constants';

const getReportName = url => CSV_NAMING_CONFIG[url] || '';

export default function changeReportName(url, params) {
  let reportName = getReportName(url);

  if (params.period_from && params.period_to) {
    reportName = `${reportName} (${dayjs(params.period_from).format('MMM-YYYY')} ${dayjs(
      params.period_to
    ).format('MMM-YYYY')})`;
  }

  return reportName;
}
