/* eslint-disable react/display-name */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withMemoizedRouteInstance } from './hoc';
import {
  EarningsByArtistVideo,
  EarningsByAssetPage,
  EarningsByYoutubeChannel,
  EarningsByYoutubeVideo,
  EarningsPaymentPage,
  EarningsPaypalAuth,
  EarningsRevenueSteam,
  EarningsSummaryPage,
  NotFoundPage,
} from './pages';
import urls from './urls';

export const routes = [
  {
    path: '/paypal-auth/',
    component: EarningsPaypalAuth,
  },
  {
    path: `(/|${urls.earningsDashboard()})`,
    render: () => <Redirect to={urls.earnings.summary()} />,
    exact: true,
  },
  {
    path: urls.earnings.summary(),
    component: EarningsSummaryPage,
  },
  {
    path: urls.earnings.asset(),
    component: EarningsByAssetPage,
  },
  {
    path: urls.earnings.artist(),
    component: EarningsByArtistVideo,
  },
  {
    path: urls.earnings.summary(),
    component: EarningsSummaryPage,
  },
  {
    path: urls.earnings.revenueStream(),
    component: EarningsRevenueSteam,
  },
  {
    path: urls.earnings.asset(),
    component: EarningsByAssetPage,
  },
  {
    path: urls.earnings.artist(),
    component: EarningsByArtistVideo,
  },
  {
    path: urls.earnings.youtubeVideo(),
    component: EarningsByYoutubeVideo,
  },
  {
    path: urls.earnings.youtubeChannel(),
    component: EarningsByYoutubeChannel,
  },
  {
    path: urls.earnings.payments(),
    component: EarningsPaymentPage,
  },

  {
    path: '*',
    component: NotFoundPage,
  },
];

const Routes = () => (
  <Switch>
    {routes.map(routeProps => (
      <Route key={routeProps.path} {...routeProps} />
    ))}
  </Switch>
);

export default withMemoizedRouteInstance(Routes);
