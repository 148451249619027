import scopedReducer from 'ducks/asset/factory/scopedReducer';
import types from './types';

const listReducerInstance = scopedReducer(
  types,
  { details: false },
  {
    direction: 'desc',
    page: 1,
  }
);
const InitialState = {
  availableAccounts: [],
  total: {},
  currentEndBalance: undefined,
  accountId: null,
};
const list = (state = {}, action) => ({ ...state, ...listReducerInstance(state, action) });

const results = (state = InitialState, action) => {
  if (action.type === types.LIST_FETCH_SUCCESS) {
    return { ...state, total: action.payload.total };
  }
  if (action.type === types.PAYMENT_ACCOUNTS_SUCCESS) {
    const payload = action.payload.available_accounts;
    const filterData = payload.map(account => {
      return {
        email: account.info.email,
        id: account.id,
        type: account.type,
      };
    });
    return { ...state, availableAccounts: filterData };
  }
  if (action.type === types.GET_USER_BALANCE_SUCCESS) {
    return { ...state, currentEndBalance: action.payload.current_end_balance };
  }
  if (action.type === types.DELETE_PAYMENTS_ACCOUNT) {
    return { ...state, accountId: action.payload.accountId };
  }

  return state;
};

export default { results, list };
