export const __REDUX_STATE_KEY__ = 'user';
export const getReduxStateSlice = state => state[__REDUX_STATE_KEY__];

export const getUserInfo = state => getReduxStateSlice(state);
export const userIsAuthenticated = state => !!getReduxStateSlice(state).username;
export const getUserAvatar = state => getReduxStateSlice(state).avatar;
export const getUserUsername = state => getReduxStateSlice(state).username;
export const getUserDisplayName = state => getReduxStateSlice(state).name;
export const getUserStaffStatus = state => getReduxStateSlice(state).is_staff;
export const getOrganizations = state => getReduxStateSlice(state).organizations;
export const getUserID = state => getReduxStateSlice(state).id;
