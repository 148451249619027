import { has, mapValues } from 'lodash';
import {
  ALBUM_DISPLAY_NAME,
  COLLECTION_TYPES,
  FOLDER_DISPLAY_NAME,
  ITEM_TYPES,
  ORFIUM_DEFAULT_ARTWORK_URL,
  ORFIUM_DEFAULT_AVATAR_URL,
  RECORDING_DISPLAY_NAME,
} from 'utils/constants';
import uuidv4 from 'uuid/v4';

export const getAssetKey = asset => asset.id;
export const getEarningsAssetKey = asset => asset.asset_id;
export const getUploadKey = getAssetKey; // used to be filename, we switched to front-end GUID

/**
 * checks whether the input is a valid UUID
 *
 * @returns {Boolean}  Returns true if the input is a valid UUID and false otherwise
 */
export const isGuid = str =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(str);

/**
 * returns a full URL with an Orfium app prefix for the relative path given
 *
 * @param {String} url - A relative path
 * @returns {String} A full URL for the relative path given
 */
export function withOrfiumDomain(url) {
  return `${process.env.REACT_APP_ORFIUM_DOMAIN}${url}`;
}

/**
 * returns a full URL with a MediaLibrary app prefix for the relative path given
 *
 * @param {String} url - A relative path
 * @returns {String}  A full URL for the relative path given
 */
export function withMediaLibraryDomain(url) {
  return `${process.env.REACT_APP_MEDIA_LIBRARY_DOMAIN}${url}`;
}

/**
 * returns the image or falls back to a default image on empty images
 *
 * @param {Object} image - A URL for an image of media library
 * @returns {String} A URL for an image
 */
export function withOrfiumLogoFallback(image) {
  return image || ORFIUM_DEFAULT_ARTWORK_URL;
}

/**
 * returns the image or falls back to a default avatar on users without an avatar
 *
 * @param {Object} image - A URL for the avatar of a user
 * @returns {String} A URL for an image
 */
export function withOrfiumAvatarFallback(image) {
  return image || ORFIUM_DEFAULT_AVATAR_URL;
}

/**
 * returns a new guid which is guaranteed to be a unique ID
 *
 * @returns {String} The GUID string
 */
export const guid = uuidv4;

/**
 * makes sure that the `param` passed is converted to an array
 *
 * @param {Object|String|Number|Array} param - A list of items or an item
 * @returns {Array} - The same array or an array containing 1 item
 */
export function toList(param) {
  if (!(param instanceof Array)) {
    return [param];
  }

  return param;
}

/**
 * Adds "0" in the beginning of a number, if needed
 *
 * @param {Number} value - A number
 * @returns {String} - The possibly 0-prefixed number in the form of a string
 */
export function prefixWithZero(value) {
  const normalisedValue = value.toString();
  if (normalisedValue.length === 1) {
    return `0${normalisedValue}`;
  }
  return normalisedValue;
}

/**
 * A function that smartly merges props. Imagine that we had a component that receives multiple
 * event handlers. If we simply used destructuring then only 1 event handler would be executed.
 * This function makes sure that if two different sources of props define common event handlers,
 * then both of these handlers get executed.
 *
 * @param {Object} propsA - A collection of props to apply to a component
 * @param {Object} propsB - An additional collection of props to apply to a component
 * @returns {Object} - Smartly merged props
 */
export function mergeProps(propsA, propsB) {
  const mergedProps = { ...propsA, ...propsB };
  return mapValues(mergedProps, (propValue, propName) => {
    if (typeof propValue === 'function') {
      const correspondingPropValueInA = propsA[propName];
      if (!correspondingPropValueInA) {
        return propValue;
      }

      return (...args) => {
        propValue(...args);
        correspondingPropValueInA(...args);
      };
    }

    return propValue;
  });
}

/**
 * A function that helps creating a dummy textarea in order to pass a string ready to copy it to the
 * clipboard. Once that is done the element created is being removed from the dom.
 *
 * @param {String} str - A simple string to be copied
 */
export function copyStringToClipboard(str) {
  // Create new element
  var el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

/**
 * A function that takes a text and returns a valid slug for it. Useful for filename and url
 * creation
 *
 * @param {String} text - A string to slugify
 * @returns {String} - A slugified string
 */
export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

/**
 * A function that takes a URL as an input and returns an object with its GET params
 *
 * @param {String} url - A url to extract params from
 * @returns {Object} - An object with all the params in a {paramName: paramValue} structure
 */
export function parseQueryParamsFromURL(url) {
  // account for `null` or `''` cases
  if (!url) {
    return {};
  }
  // creates a URL instance
  const urlObj = new URL(url);

  // creates a URLSearchParams instance. `.entries()` yields an array of tuples [[key, value], ...]
  const searchParamsObj = new URLSearchParams(urlObj.search);

  return Array.from(searchParamsObj.entries()).reduce(
    (acc, pair) => ({
      ...acc,
      [pair[0]]: Boolean(pair[1]) && !isNaN(pair[1]) ? Number(pair[1]) : pair[1],
    }),
    {}
  );
}

/**
 * Checks whether the asset is a Collection
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is an album
 */
export function isAlbum(asset) {
  return COLLECTION_TYPES.includes(asset.type);
}

/**
 * Checks whether the asset is a Folder
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is a folder
 */
export function isFolder(asset) {
  return has(asset, 'parent');
}

/**
 * Checks whether the asset is an Item
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is a recording
 */
export function isRecording(asset) {
  return ITEM_TYPES.includes(asset.type);
}

/**
 * Checks whether the asset is of earnings type in the shape of a By Asset
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the ByAsset page of earnings.
 */
export function isByAsset(asset) {
  return has(asset, 'asset');
}

/**
 * Checks whether the asset is of earnings type in the shape of a By Artist
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the ByArtist page of earnings.
 */
export function isByArtist(asset) {
  return has(asset, 'artists');
}

/**
 * Checks whether the asset is of earnings type in the shape of a Youtube Video
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the YoutubeVideo page of earnings.
 */
export function isYoutubeVideo(asset) {
  return has(asset, 'video_earnings') && has(asset, 'video_id');
}

/**
 * Checks whether the asset is of earnings type in the shape of a Youtube Channel
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the YoutubeChannel page of earnings.
 */
export function isYoutubeChannel(asset) {
  return has(asset, 'channel_id');
}

/**
 * Checks whether the asset is of earnings type in the shape of a Revenue Stream
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the RevenueStream page of earnings.
 */
export function isRevenueStream(asset) {
  return has(asset, 'revenue_stream');
}

/**
 * Checks whether the asset is of earnings type in the shape of a Revenue Stream Footer
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the RevenueStream page of earnings.
 */
export function isRevenueStreamFooter(asset) {
  return has(asset, 'net_earnings');
}

/**
 * Checks whether the asset is of earnings type in the shape of a Summary
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the Summary page of earnings.
 */
export function isSummary(asset) {
  return has(asset, 'end_balance');
}

/**
 * Checks whether the asset is of Payment type
 *
 * @param {Object} asset - The asset to check
 * @returns {Boolean} - Whether the asset is for the Payment page of earnings.
 */
export function isPayment(asset) {
  return has(asset, 'payment_id');
}

/**
 * Get the type in string of the asset based on the asset passed
 *
 * @param {Object} asset - The asset to check
 * @returns {String} - The asset string whether is folder or album etc
 */
export function getTypeDisplayNameBasedOnAsset(asset) {
  if (isRecording(asset)) {
    return RECORDING_DISPLAY_NAME;
  }
  if (isAlbum(asset)) {
    return ALBUM_DISPLAY_NAME;
  }
  if (isFolder(asset)) {
    return FOLDER_DISPLAY_NAME;
  }
  return 'Item';
}
