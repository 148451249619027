import React, { memo } from 'react';
import { Tooltip } from 'components';
import { ReactComponent as AdvancedFilterIcon } from 'assets/advanced-filters.svg';
import './AdvancedFilterButton.scss';

export const AdvancedFilterButton = props => (
  <Tooltip title="Advanced Filters">
    <button type="button" className="AdvancedFilterButton" {...props}>
      <AdvancedFilterIcon />
      Filters
    </button>
  </Tooltip>
);

export default memo(AdvancedFilterButton);
