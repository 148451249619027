import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIconSVG } from 'assets/search-icon.svg';
import './Searchbar.scss';

function Searchbar({ value, handleChange, role, ...rest }) {
  return (
    <div className="Searchbar" role={role}>
      <SearchIconSVG className={`Searchbar__icon${value ? ' active' : ''}`} />
      <input
        {...rest}
        value={value}
        onChange={handleChange}
        type="search"
        data-testid="searchbar-search-input"
      />
    </div>
  );
}

Searchbar.defaultProps = {
  role: 'search',
};

Searchbar.propTypes = {
  value: PropTypes.string.isRequired,
  role: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default memo(Searchbar);
