import { connect } from 'react-redux';
import { getSnackbars, removeSnackbar } from 'ducks/ui/snackbar';
import Snackbar from './Snackbar';

const mapStateToProps = state => ({
  snackbars: getSnackbars(state),
});

const mapDispatchToProps = { removeSnackbar };

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
