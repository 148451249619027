import { connect } from 'react-redux';
import { actions } from 'ducks/earnings/results';
import YoutubeChannelFilterForm from './YoutubeChannelFilterForm';
import { EARNINGS_REDUX_FORM_CONFIG, MODALS } from 'utils/constants';
import { pushModal } from 'ducks/ui/modal';
import { hideModal } from 'utils/modals';
import {
  earningsFilterMapStateToProps as mapStateToProps,
  initReinitializedReduxForm,
} from '../utils';

const url = '/by-youtube-channel/';
// Until the API fixes we will send the period from and to on the initial Fetch
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    pushCustomRangeModal: () => {
      dispatch(
        pushModal({
          type: MODALS.CUSTOM_RANGE,
          props: {
            hide: hideModal(dispatch),
            form: ownProps.form,
            url: url,
          },
        })
      );
    },
    changeParams: ({ params, shouldFetch }) => {
      dispatch(actions.resetAssetsPagination());
      dispatch(
        actions.changeRequestedAssetsParams({
          params: {
            url,
            ...params,
          },
          shouldFetch,
        })
      );
    },
  };
};

const reduxFormConfig = {
  ...EARNINGS_REDUX_FORM_CONFIG,
  form: 'ytFormChannel',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(initReinitializedReduxForm(YoutubeChannelFilterForm, reduxFormConfig));
