import { createSelector } from 'reselect';
import { getSelectedAssetIds } from '../common/selectors';

function scopedSelectors(statePath, { details = true, list = true } = {}) {
  const getReduxListStateSlice = state => statePath(state).list;
  const getReduxDetailsStateSlice = state => statePath(state).details;

  const listSelectors = {
    getIsLoading: state => getReduxListStateSlice(state).isLoading,
    getIsFetching: state => getReduxListStateSlice(state).isFetching,
    getIsExporting: state => getReduxListStateSlice(state).isExporting,
    getParams: state => getReduxListStateSlice(state).params,
    getHasMore: state => getReduxListStateSlice(state).hasMore,
    getIsInitialFetch: state => getReduxListStateSlice(state).isInitialFetch,
    getItems: state => getReduxListStateSlice(state).items,
  };

  listSelectors.getSelectedListAssets = createSelector(
    getSelectedAssetIds,
    listSelectors.getItems,
    (selectedAssetIds, list) => list.filter(asset => selectedAssetIds.includes(asset.id))
  );

  const detailsSelectors = {
    getSingle: state => getReduxDetailsStateSlice(state).item,
    getSingleIsFetching: state => getReduxDetailsStateSlice(state).isFetching,
    getSingleIsLoading: state => getReduxDetailsStateSlice(state).isLoading,
  };

  detailsSelectors.getSelectedRecordings = createSelector(
    getSelectedAssetIds,
    detailsSelectors.getSingle,
    (selectedAssetIds, item) =>
      item ? item.recordings.filter(asset => selectedAssetIds.includes(asset.id)) : []
  );

  return {
    ...(details ? detailsSelectors : {}),
    ...(list ? listSelectors : {}),
    getReduxListStateSlice,
    getReduxDetailsStateSlice,
  };
}

export default scopedSelectors;
