import React from 'react';
import PropTypes from 'prop-types';
import { GenericErrorPage } from 'pages';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    // if no error occurs -> render as normal
    if (!this.state.hasError) {
      return this.props.children;
    }

    // if we have chosen to show a full-screen error page when an error occurs
    if (this.props.fallbackToErrorScreen) {
      return <GenericErrorPage />;
    }

    // if we have chosen to hide the component inside the error boundary when an error occurs
    if (this.props.fallbackToHidden) {
      return null;
    }

    // default behaviour for errors is to show a box with the message
    return (
      <div className="ErrorBoundary">
        <p>{this.props.errorMessage}</p>
      </div>
    );
  }
}

ErrorBoundary.propTypes = {
  ...ErrorBoundary.reduxProps,
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  fallbackToErrorScreen: PropTypes.bool,
  fallbackToHidden: PropTypes.bool,
};

ErrorBoundary.defaultProps = {
  errorMessage: 'Something went wrong and we could not display correctly this content',
  fallbackToErrorScreen: false,
  fallbackToHidden: false,
};

export default ErrorBoundary;
