import { connect } from 'react-redux';
import { actions, selectors } from 'ducks/earnings/results';
import { ReportIcons } from './ReportIcons';

const mapStateToProps = state => ({
  params: selectors.getParams(state),
  isExporting: selectors.getIsExporting(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeParams: params => {
      dispatch(
        actions.fetchExportCSVRequest({
          params: { url: ownProps.url, export_format: 'csv', ...params },
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportIcons);
