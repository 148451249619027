/**
 * Transforms a string to a valid Dropdown/Select option
 *
 * @param {String} str - A value that we want to pass as a dropdown option
 * @returns {Object} - An object that our Dropdown (Select) accepts as a valid option
 */
import { PAYPAL_TRANSFER_TYPE, WIRE_TRANSFER_TYPE } from '../../utils/constants';

export const transformStringToDropdownOption = str => ({
  text: str,
  value: str,
});

export const getPaymentAccountById = (accounts, accountId) => {
  return Array.isArray(accounts) && accounts.find(acc => acc.id === accountId);
};

export const isWireTransfer = type => type === WIRE_TRANSFER_TYPE;

export const getAvailableText = ({ email: paymentEmail, type: paymentType }) => {
  const PAYMENT_ACCOUNT_TEXT_CONFIG = {
    [WIRE_TRANSFER_TYPE]: 'Wire transfer account',
    [PAYPAL_TRANSFER_TYPE]: paymentEmail,
  };

  return PAYMENT_ACCOUNT_TEXT_CONFIG[paymentType];
};
