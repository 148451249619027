import React from 'react';
import PropTypes from 'prop-types';
import { selectOrganization } from 'utils/impersonation';
import { connect } from 'react-redux';

import './OrganizationSelect.scss';

const OrganizationSelect = ({ organizations, selectedOrganizationId, selectOrganization }) => {
  if (!organizations) {
    return null;
  }

  // sort organizations by name
  organizations.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div className="OrganizationSelect">
      <span className="OrganizationSelect__description">Select an organization:</span>
      <button type="button" onClick={() => selectOrganization(null)}>
        {!selectedOrganizationId && '✓ '}None (my earnings)
      </button>
      {organizations.map(organization => (
        <button
          key={organization.uuid}
          type="button"
          onClick={() => selectOrganization(organization)}
        >
          {selectedOrganizationId === organization.orfium_uuid && '✓ '}
          {organization.name}
        </button>
      ))}
    </div>
  );
};

OrganizationSelect.reduxProps = {
  organizations: PropTypes.array,
  selectedOrganizationId: PropTypes.string,
};

OrganizationSelect.propTypes = {
  ...OrganizationSelect.reduxProps,
};

export default connect(null, { selectOrganization })(OrganizationSelect);
