import scopedTypes from '../factory/scopedTypes';

export const SCOPE = 'FOLDER_';

const scopedTypesList = scopedTypes(SCOPE);

const GENERATE_ALBUM_REQUEST = 'GENERATE_ALBUM_REQUEST';
const CONVERT_ALBUM_REQUEST = 'CONVERT_ALBUM_REQUEST';

export default {
  ...scopedTypesList,
  GENERATE_ALBUM_REQUEST,
  CONVERT_ALBUM_REQUEST,
};
