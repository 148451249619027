import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import FocusLock from 'react-focus-lock';
import SemanticPopup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import './Popup.scss';

class Popup extends React.Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false }, this.props.onClose);
  };

  handleOpen = () => {
    this.setState({ open: true }, this.props.onOpen);
  };

  render() {
    const {
      trigger,
      horizontalPosition,
      verticalPosition,
      children,
      className,
      ...rest
    } = this.props;

    const semanticPopupProps = omit(rest, ['onOpen', 'onClose']);
    return (
      <SemanticPopup
        {...semanticPopupProps}
        className={`Popup ${className}`}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        trigger={trigger}
        on="click"
        open={this.state.open}
        position={`${verticalPosition} ${horizontalPosition}`}
      >
        <FocusLock returnFocus>
          <div className="Popup__content" onClick={this.handleClose}>
            {children}
          </div>
        </FocusLock>
      </SemanticPopup>
    );
  }
}

Popup.propTypes = {
  trigger: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  verticalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Popup.defaultProps = {
  className: '',
  horizontalPosition: 'right',
  verticalPosition: 'bottom',
  onOpen: () => {},
  onClose: () => {},
};

export default Popup;
