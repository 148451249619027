import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Container.scss';

const Container = ({ centered, children, className, ...rest }) => {
  const classes = classNames({
    Container: true,
    'Container--centered': centered,
    [className]: !!className,
  });

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

Container.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  centered: false,
  className: '',
};

export default Container;
