import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HamburgerSVG } from 'assets/hamburger.svg';
import HeaderToolkit from './subcomponents/HeaderToolkit';
import './Header.scss';
import HeaderLogo from './subcomponents/HeaderLogo/HeaderLogo';

const Header = ({ sidebarIsOpen, openSidebar, closeSidebar }) => {
  return (
    <header className="Header">
      <div className="Header__left">
        <button
          title="Toggle sidebar"
          type="button"
          className={`Header__left-menu-trigger${sidebarIsOpen ? ' active' : ''}`}
          onClick={sidebarIsOpen ? closeSidebar : openSidebar}
        >
          <HamburgerSVG />
        </button>
        <HeaderLogo />
      </div>
      <HeaderToolkit />
    </header>
  );
};

Header.reduxProps = {
  sidebarIsOpen: PropTypes.bool.isRequired,
  openSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  pushOverlay: PropTypes.func.isRequired,
};

Header.propTypes = {
  ...Header.reduxProps,
};

export default Header;
