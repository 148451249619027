import React from 'react';
import PropTypes from 'prop-types';
import './Table.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const Table = ({ children, className = '', ...rest }) => (
  <table role="table" aria-label="asset-table" className={`Table ${className}`} {...rest}>
    {children}
  </table>
);

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

Table.Head = ({ children, className = '', ...rest }) => (
  <thead className={`Table__head ${className}`} {...rest}>
    <tr>{children}</tr>
  </thead>
);

Table.Head.propTypes = propTypes;
Table.Head.defaultProps = defaultProps;

Table.Body = ({ children, className = '', ...rest }) => (
  <tbody className={`Table__body ${className}`} {...rest}>
    {children}
  </tbody>
);

Table.Body.propTypes = propTypes;
Table.Body.defaultProps = defaultProps;

Table.Footer = ({ children, className = '', ...rest }) => (
  <tfoot className={`Table__footer ${className}`} {...rest}>
    {children}
  </tfoot>
);

Table.Footer.propTypes = propTypes;
Table.Footer.defaultProps = defaultProps;

Table.Head.displayName = 'Table.Head';
Table.Body.displayName = 'Table.Body';
Table.Footer.displayName = 'Table.Footer';

export default Table;
