import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { negativeCSSclass, amountWithCommas } from './utils';

const SummaryFooterTableListViewCollumns = ({ asset: summary }) => (
  <React.Fragment>
    <td>Overall (All Streams)</td>
    <td className="EarningsTables__cell--centered">{amountWithCommas(summary.gross_earnings)}</td>
    <td className={`EarningsTables__cell--centered ${negativeCSSclass(summary.transaction_fees)}`}>
      {amountWithCommas(-summary.transaction_fees) || 0}
    </td>
    <td className={`EarningsTables__cell--centered ${negativeCSSclass(summary.orfium_share)}`}>
      {amountWithCommas(-summary.orfium_share) || 0}
    </td>
    <td className="EarningsTables__cell--centered">{amountWithCommas(summary.net_earnings)}</td>
    <td
      className={`EarningsTables__cell--centered EarningsTables__lightMediumGrey ${negativeCSSclass(
        amountWithCommas(summary.withdrawals)
      )}`}
    >
      {amountWithCommas(-summary.withdrawals) || 0}
    </td>
    <td className="EarningsTables__cell--right EarningsTables__mediumGrey" />
  </React.Fragment>
);

SummaryFooterTableListViewCollumns.propTypes = {
  asset: PropTypes.shape(shapes.summaryFooter).isRequired,
};
export default SummaryFooterTableListViewCollumns;
