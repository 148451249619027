import flow from 'lodash/fp/flow';
import { EARNINGS_INITIAL_VALUES, LICENCE_PARAMS, SERVICE_PARAMS } from './constants';

const CUSTOM_PERIOD_INDICATOR = 'Custom';
const CUSTOM_PERIOD_SUFFIX = ' Range';

const checkPeriod = period => period || EARNINGS_INITIAL_VALUES.earningsPeriods;
const addProperSuffixToCustomPeriod = period => {
  const isCustomPeriod = period === CUSTOM_PERIOD_INDICATOR;
  if (isCustomPeriod) {
    period = period.concat(CUSTOM_PERIOD_SUFFIX);
  }

  return period;
};

const getNormalizedPeriod = selectedPeriod =>
  flow(checkPeriod, addProperSuffixToCustomPeriod)(selectedPeriod);

const getSelectedValue = (paramsConfig, selectedVal, fallbackText) => {
  const paramKeysList = Object.keys(paramsConfig);
  const matchKeyWithSelectedValue = key => paramsConfig[key] === selectedVal;

  return selectedVal ? paramKeysList.find(matchKeyWithSelectedValue) : fallbackText;
};

export const getUpdatedInitialFormValues = ({
  selectedPeriod,
  selectedService,
  selectedEarningsType,
  searchValue,
}) => {
  return {
    ...EARNINGS_INITIAL_VALUES,
    earningsPeriods: getNormalizedPeriod(selectedPeriod),
    earningsService: getSelectedValue(
      SERVICE_PARAMS,
      selectedService,
      EARNINGS_INITIAL_VALUES.earningsService
    ),
    earningsLicense: getSelectedValue(
      LICENCE_PARAMS,
      selectedEarningsType,
      EARNINGS_INITIAL_VALUES.earningsLicense
    ),
    earningsSearch: searchValue || EARNINGS_INITIAL_VALUES.earningsSearch,
  };
};
