import { connect } from 'react-redux';
import { cancelImpersonation } from 'utils/impersonation';
import UserCluster from './UserCluster';
import { MODALS } from 'utils/constants';
import {
  getUserAvatar,
  getUserDisplayName,
  getUserUsername,
  getUserStaffStatus,
  getOrganizations,
} from 'ducks/user';
import { pushModal, removeModal } from 'ducks/ui/modal';

const mapDispatchToProps = dispatch => {
  return {
    pushImpersonationModal: () => {
      dispatch(
        pushModal({
          type: MODALS.IMPERSONATION,
          props: {
            hide: () => dispatch(removeModal(MODALS.IMPERSONATION)),
            url: '',
          },
        })
      );
    },
    cancelImpersonation: () => dispatch(cancelImpersonation()),
  };
};

const mapStateToProps = state => ({
  isStaff: getUserStaffStatus(state),
  avatar: getUserAvatar(state),
  username: getUserUsername(state),
  name: getUserDisplayName(state),
  organizations: getOrganizations(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCluster);
