import { ADD_SNACKBAR, DELETE_SNACKBAR } from './types';
import { simpleAction } from '../../utils';

export const __INFO__ = 'info';
export const __WARNING__ = 'warning';

export function pushInfoSnackbar(content) {
  return simpleAction(ADD_SNACKBAR, { content, type: __INFO__ });
}

export function pushWarningSnackbar(content) {
  return simpleAction(ADD_SNACKBAR, { content, type: __WARNING__ });
}

export function removeSnackbar(snackbarId) {
  return simpleAction(DELETE_SNACKBAR, snackbarId);
}
