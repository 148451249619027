import React, { useEffect, useState } from 'react';
import { earningsAxios as axios } from 'api/axiosInstances';
import { Base } from 'pages/utils';
import { parseQueryParamsFromURL } from 'utils/common';

const VERIFY_MSG = 'Connecting your account...';

function EarningsPaypalAuth() {
  const parsed = parseQueryParamsFromURL(window.location.href);
  const [status, setStatus] = useState('PROCESSING');

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get('paypal-auth/', { params: { ...parsed } })
        .then(() => {
          if (window) {
            window.close();
            window.opener.location.reload(false);
          }
        })
        .catch(() => {
          setStatus('FAILED');
          window.close();
        });
    };

    fetchData();
  }, []);

  const showHtmlMessage = status => {
    if (status === 'PROCESSING') {
      return <div>{VERIFY_MSG}</div>;
    }
    if (status === 'FAILED') {
      return (
        <div>
          <p>
            Something went wrong while you were adding a PayPal account.
            <br />
            Keep in mind you might only add verified accounts.
          </p>
        </div>
      );
    }
  };
  return (
    <Base>
      <div className="EarningsSummaryPage__title">{showHtmlMessage(status)}</div>
    </Base>
  );
}

export default EarningsPaypalAuth;
