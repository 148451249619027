import { connect } from 'react-redux';
import { actions } from 'ducks/earnings/results';
import { selectors } from 'ducks/earnings/results';
import BalanceManager from './BalanceManager';
import { pushModal, removeModal } from 'ducks/ui/modal';
import { MODALS, FORMS } from 'utils/constants';
import { getUserID } from 'ducks/user';

const mapStateToProps = state => ({
  userID: getUserID(state),
  currentEndBalance: selectors.getCurrentBalance(state),
  accounts: selectors.getAvailableAccounts(state),
});

const mapDispatchToProps = dispatch => {
  return {
    pushPaymentFormModal: () => {
      dispatch(
        pushModal({
          type: MODALS.PAYMENT_FORM,
          props: {
            hide: () => dispatch(removeModal(MODALS.PAYMENT_FORM)),
            form: FORMS.EARNINGS_PAYMENT_FORM,
          },
        })
      );
    },
    fetchInitialBalance: () => dispatch(actions.getUserBalanceRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceManager);
