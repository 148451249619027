import React from 'react';
import './GenericErrorPage.scss';

function GenericErrorPage() {
  return (
    <div className="GenericErrorPage">
      <h1>The app reached an unknown state</h1>
      <h2>{" This is a crash & it's a programming issue "}</h2>
    </div>
  );
}

export default GenericErrorPage;
