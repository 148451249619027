import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseSVG } from 'assets/remove-icon.svg';
import { EscHandler } from '../utils';
import './Modal.scss';
import FocusLock from 'react-focus-lock';

function Modal({ title, children, onClose, ...rest }) {
  useEffect(() => {
    // check whether a `no-scroll` already exists. If it already exists we should omit handling it
    // because something else (another component) is handling it already
    const shouldOmitHandlingNoScroll = document.body.classList.contains('no-scroll');
    if (!shouldOmitHandlingNoScroll) {
      window.requestIdleCallback(() => document.body.classList.add('no-scroll'));
      return () => {
        window.requestIdleCallback(() => document.body.classList.remove('no-scroll'));
      };
    }
  }, []);

  return (
    <FocusLock>
      <div className="Modal">
        <div className="Modal__obfuscator" onClick={onClose} />

        <div className="Modal__container" role="dialog">
          {title && (
            <button type="button" className="Modal__close" onClick={onClose}>
              <CloseSVG />
            </button>
          )}
          {title && (
            <div className="Modal__header">
              <h1>{title}</h1>
            </div>
          )}
          <div className="Modal__content" {...rest}>
            <EscHandler callback={onClose}>{children}</EscHandler>
          </div>
        </div>
      </div>
    </FocusLock>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  title: null,
};

export default memo(Modal);
