import { mediaLibAxios as axios } from '../axiosInstances';
import { selectApiSubmitableFields } from '../utils';

export default {
  get: (params, cancelToken) =>
    // only submit the fields that need to submitted
    axios
      .get('/folders/', {
        params,
        cancelToken,
      })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
  create: payload => {
    // only submit the fields that need to submitted
    const pluckedPayload = payload.map(o => selectApiSubmitableFields(o));
    return axios
      .post('/folders/', pluckedPayload)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      });
  },
  update: payload => {
    // only submit the fields that need to submitted
    const pluckedPayload = payload.map(o => selectApiSubmitableFields(o));
    return axios
      .put('/folders/', pluckedPayload)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      });
  },
  delete: payload =>
    // perform the request
    axios
      .delete('/folders/', { data: payload })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
};
