import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { amountWithCommas, checkEmptyField } from './utils';

const VIDEO_COL_LABEL = 'Video';

const ByYoutubeVideoTableListViewColumnHeaders = memo(props => (
  <React.Fragment>
    <TableHeader label="Video" sortKey={null} {...props} />
    <TableHeader
      label="YouTube ID"
      className="EarningsTables__cell--centered"
      sortKey={null}
      {...props}
    />
    <TableHeader
      label="Months"
      className="EarningsTables__cell--centered"
      sortKey={null}
      {...props}
    />
    <TableHeader
      label="Total Reported Earnings"
      sortKey="reported_earnings"
      className="EarningsTables__cell--right"
      {...props}
    />
  </React.Fragment>
));

const ByYoutubeVideoTableListViewColumns = ({ asset: ytVideo }) => (
  <React.Fragment>
    <td>{checkEmptyField(ytVideo.video_title, VIDEO_COL_LABEL)}</td>
    <td className="EarningsTables__cell--centered">
      <a rel="noopener noreferrer" target="_blank" href={`${ytVideo.youtube_url}`}>
        {ytVideo.video_id}
      </a>
    </td>
    <td className="EarningsTables__cell--centered">{ytVideo.period}</td>
    <td className="EarningsTables__cell--right">
      <div dir="rtl">{amountWithCommas(ytVideo.video_earnings.toFixed(2))}</div>
    </td>
  </React.Fragment>
);

ByYoutubeVideoTableListViewColumns.displayName = 'ByYoutubeVideoTableListViewColumns';

ByYoutubeVideoTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.byYoutubeVideo).isRequired,
};

export { ByYoutubeVideoTableListViewColumnHeaders, ByYoutubeVideoTableListViewColumns };
