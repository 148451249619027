import { ROUTE_CHANGE, APP_INIT } from 'ducks/router';
import analyticsModule from 'utils/analytics';

const analytics = __store => next => action => {
  if ([APP_INIT, ROUTE_CHANGE].includes(action.type)) {
    analyticsModule.trackCurrentPage(action.payload.location);
  }

  next(action);
};

export default analytics;
