import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';
// we create the dynamic import statement outside lazy so we can "preload" the tooltip (instead of
// actually fetching it when we are about to render a <Tooltip> component). This is done because the
// tooltip is present in each row of the AssetTable when viewing the CollectionDetailsPage, this
// would cause double the amount of normal renders since it would render once with the suspense
// fallback and another when the promise got resolved. This would cause an update on each row and
// if we have 100 rows we save 100 renders by eagerly preloading it. The user will log in from
// stable internet so consuming his MB is not an issue in this case

// disabling lazy loading due to react console warnings
// const dynamicImport = import(/* webpackChunkName: "mui-tooltip" */ '@material-ui/core/Tooltip');
// const MUITooltip = lazy(() => dynamicImport);

function Tooltip({ children, title, ...rest }) {
  return (
    <React.Suspense fallback={children}>
      <MUITooltip enterDelay={750} title={title} {...rest}>
        {children}
      </MUITooltip>
    </React.Suspense>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Tooltip;
