import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { omitBy, isNil } from 'lodash';
import { actions } from 'ducks/earnings/results';
import { showAppliedFiltersDisplay } from 'ducks/ui/appliedFiltersDisplay';
import { EARNINGS_INITIAL_VALUES, MODALS } from 'utils/constants';
import CustomRangeFilterForm from './CustomRangeFilterForm';
import { handleAdvancedFiltersParams } from '../utils';
import { removeModal } from 'ducks/ui/modal';
import { isCorrectDateFormatFromTo } from '../../validators';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: formValues => {
      const params = handleAdvancedFiltersParams(formValues.values, ownProps.url);
      dispatch(showAppliedFiltersDisplay(omitBy(formValues.values, isNil)));
      dispatch(actions.resetAssetsPagination());
      dispatch(
        actions.changeRequestedAssetsParams({
          params,
          shouldFetch: true,
        })
      );
      dispatch(removeModal(MODALS.CUSTOM_RANGE));
    },
  };
};

const CtFilterForm = connect(null, mapDispatchToProps)(CustomRangeFilterForm);

const CustomFilterDates = reduxForm({
  initialValues: EARNINGS_INITIAL_VALUES,
  destroyOnUnmount: false,
  validate: isCorrectDateFormatFromTo,
  // there is an open bug issue for this: https://github.com/erikras/redux-form/issues/4149  This is the solution
  shouldError: () => true,
})(CtFilterForm);

export default CustomFilterDates;
