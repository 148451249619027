import { earningsAxios as axios } from '../axiosInstances';

export default {
  get: ({ url, ...params }, cancelToken) =>
    axios
      .get(url, {
        params,
        cancelToken,
      })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
};
