/* eslint-disable react/button-has-type */
// because this might be used both as a button and as a submit-button

import React from 'react';
import PropTypes from 'prop-types';
import './OrfiumButton.scss';

const OrfiumButton = ({ className, children, ...rest }) => (
  <button className={`OrfiumButton ${className}`} {...rest}>
    {children}
  </button>
);

OrfiumButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

OrfiumButton.defaultProps = {
  className: '',
};

export default OrfiumButton;
