import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { amountWithCommas } from './utils';

const ByYoutubeChannelTableListViewColumnHeaders = memo(props => (
  <React.Fragment>
    <TableHeader label="Channel URL" sortKey={null} {...props} />
    <TableHeader
      label="Months"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Your Earnings"
      sortKey="your_earnings"
      className="EarningsTables__cell--right"
      {...props}
    />
  </React.Fragment>
));

ByYoutubeChannelTableListViewColumnHeaders.displayName =
  'ByYoutubeChannelTableListViewColumnHeaders';

const ByYoutubeChannelTableListViewColumns = ({ asset: ytchannel }) => (
  <React.Fragment>
    <td>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={`https://youtube.com/channel/${ytchannel.channel_id}`}
      >{`${ytchannel.channel_id}`}</a>
    </td>
    <td className="EarningsTables__cell--centered">{ytchannel.period}</td>
    <td className="EarningsTables__cell--right">
      <div dir="rtl">{amountWithCommas(ytchannel.your_earnings.toFixed(2))}</div>
    </td>
  </React.Fragment>
);

ByYoutubeChannelTableListViewColumns.displayName = 'ByYoutubeChannelTableListViewColumns';

ByYoutubeChannelTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.byYoutubeChannel).isRequired,
};

export { ByYoutubeChannelTableListViewColumnHeaders, ByYoutubeChannelTableListViewColumns };
