import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';

const HeaderLogo = () => (
  <Link to="/" className="Header__left-logo-trigger" aria-label="Orfium Media Library">
    <Logo />
  </Link>
);

export default HeaderLogo;
