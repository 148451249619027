import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary, Table } from 'components';
import { Row } from './subcomponents';
import * as shapes from 'shapes';
import { get } from 'lodash';
import classNames from 'classnames';
import { guid } from 'utils/common';
import { EARNINGS_ENDPOINT } from 'utils/constants';
import { tableItems } from './utils.jsx';
import './EarningsTables.scss';

const EarningsTables = ({
  assets,
  viewType,
  onSort,
  sortKey,
  sortDir,
  urlLink,
  total,
  footerType,
}) => {
  const ViewTypeColumnHeaders = get(tableItems, [viewType, 'ColumnHeaders'], 'th');
  const tableIsSortable = Boolean(onSort) && false;

  const getEmptyColSpan = urlLink => {
    switch (urlLink) {
      case EARNINGS_ENDPOINT.BY_ASSET:
        return 9;
      case EARNINGS_ENDPOINT.BY_REVENUE:
        return 6;
      case EARNINGS_ENDPOINT.BY_ARTIST:
        return 5;
      case EARNINGS_ENDPOINT.BY_YOUTUBE_CHANNEL:
        return 3;
      case EARNINGS_ENDPOINT.PAYMENT:
        return 5;
      case EARNINGS_ENDPOINT.BY_YOUTUBE_VIDEO:
        return 4;
      default:
        return 7;
    }
  };

  const tableClassName = classNames({
    [`EarningsTables EarningsTables--${viewType}`]: true,
    sortable: tableIsSortable,
  });

  return (
    <ErrorBoundary>
      <div className="table-scroll-overflow">
        <Table className={tableClassName}>
          <Table.Head>
            <ViewTypeColumnHeaders
              onSort={onSort}
              activeSortKey={sortKey}
              activeSortDir={sortDir}
            />
          </Table.Head>
          {total && (
            <Table.Footer>
              <Row viewType={footerType} asset={total} />
            </Table.Footer>
          )}
          <Table.Body>
            {!assets.length && (
              <tr className="placeholder" data-testid="table-placeholder">
                <td colSpan={getEmptyColSpan(urlLink)}>No data were found</td>
              </tr>
            )}
            {assets.map(asset => {
              return <Row key={guid()} viewType={viewType} asset={asset} />;
            })}
          </Table.Body>
        </Table>
      </div>
    </ErrorBoundary>
  );
};

EarningsTables.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(shapes.byArtist),
      PropTypes.shape(shapes.byAsset),
      PropTypes.shape(shapes.byYoutubeChannel),
      PropTypes.shape(shapes.byYoutubeVideo),
      PropTypes.shape(shapes.payments),
      PropTypes.shape(), // allow empty array ([]) as a value on this prop
    ])
  ).isRequired,
  viewType: PropTypes.string.isRequired,
  footerType: PropTypes.string,
  onSort: PropTypes.func,
  sortKey: PropTypes.string,
  sortDir: PropTypes.oneOf(['asc', 'desc']),
  urlLink: PropTypes.string.isRequired,
  total: PropTypes.shape({}),
};

EarningsTables.defaultProps = {
  onSort: null,
  sortKey: null,
  sortDir: null,
  footerType: undefined,
  total: undefined,
};

export default EarningsTables;
