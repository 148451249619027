import recordingTypes from 'ducks/asset/recordings/types';

export const initialState = {
  item: null,
  isLoading: true, // this is to define if we are showing loading or not // TODO: investigate
  isFetching: false, // this is to define if data is fetching - is different to loading because there are two different states
};

function scopedDetailsReducer(types) {
  return (state = initialState, action) => {
    switch (action.type) {
      case types.DETAILS_FETCH_REQUEST:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          isFetching: true,
        };
      case types.DETAILS_FETCH_SUCCESS:
        return {
          ...state,
          item: action.payload,
          isLoading: false,
          isFetching: false,
        };
      case types.DETAILS_FETCH_FAILURE:
        return {
          ...state,
          isFetching: false,
          isLoading: false,
        };
      default: {
        if (!state.item) {
          return state;
        }

        switch (action.type) {
          case recordingTypes.UPDATE_SUCCESS:
            return {
              ...state,
              item: {
                ...state.item,
                recordings: state.item.recordings.map(recording => {
                  const updatedRecording = action.payload.find(
                    updatedAsset => updatedAsset.id === recording.id
                  );
                  return updatedRecording ? { ...recording, ...updatedRecording } : recording;
                }),
              },
            };

          case recordingTypes.DELETE_SUCCESS:
            return {
              ...state,
              item: {
                ...state.item,
                recordings: state.item.recordings.filter(
                  asset => !action.payload.includes(asset.id)
                ),
              },
            };

          case types.DELETE_SUCCESS: {
            if (action.payload.includes(state.item.id)) {
              return {
                ...state,
                item: null,
                recordings: [],
              };
            }
            return state;
          }

          // TODO: Fix me, needs logic when an update causes the item to be moved out of the list
          case types.UPDATE_SUCCESS: {
            const updatedItem = action.payload.find(
              updatedAsset => updatedAsset.id === state.item.id
            );
            if (updatedItem) {
              return {
                ...state,
                item: {
                  ...state.item,
                  ...updatedItem,
                },
              };
            }
            return state;
          }
          case types.DETAILS_RESET:
            return initialState;
          default:
            return state;
        }
      }
    }
  };
}

export default scopedDetailsReducer;
