import { toList } from 'utils/common';
import { simpleAction } from '../../utils';
import { ALBUM_DISPLAY_NAME, RECORDING_DISPLAY_NAME, FOLDER_DISPLAY_NAME } from 'utils/constants';
import { folderAPI, itemAPI, collectionAPI } from 'api';

function scopedActions(types, api = itemAPI) {
  let assetType = 'Item';
  if (api === collectionAPI) {
    assetType = ALBUM_DISPLAY_NAME;
  }
  if (api === itemAPI) {
    assetType = RECORDING_DISPLAY_NAME;
  }
  if (api === folderAPI) {
    assetType = FOLDER_DISPLAY_NAME;
  }

  const actionsCreators = {
    updateSingleRequest: ({
      id,
      values,
      onSuccess = () => {},
      onError = () => {},
      formName = null,
      skipNotifications = false,
    }) =>
      simpleAction(types.SINGLE_UPDATE_REQUEST, {
        assetId: id,
        values,
        onSuccess,
        onError,
        formName,
        skipNotifications,
        assetType,
      }),
    addTracksToAssetRequest: ({ id, values }) =>
      simpleAction(types.ADDING_TRACKS_REQUEST, {
        assetId: id,
        values,
        assetType,
      }),
    removeTracksFromAssetRequest: ({ id, values }) =>
      simpleAction(types.REMOVING_TRACKS_REQUEST, {
        assetId: id,
        values,
        assetType,
      }),
    updateAssetsRequest: ({ assets, onSuccess = () => {}, onError = () => {} }) =>
      simpleAction(types.BULK_UPDATE_REQUEST, {
        updatedAssets: assets,
        onSuccess,
        onError,
      }),
    addAssetsRequest: ({ assets, onSuccess = () => {}, onError = () => {} }) =>
      simpleAction(types.ADD_REQUEST, { assets, onSuccess, onError, assetType }),
    addAssetsAction: item => simpleAction(types.LIST_ADD, toList(item)),
    updateAssetsAction: item => simpleAction(types.UPDATE_SUCCESS, toList(item)),
    deleteAssetsRequest: ({ ids, onSuccess = () => {}, onError = () => {} }) =>
      simpleAction(types.DELETE_REQUEST, {
        ids,
        onSuccess,
        onError,
        assetType,
      }),
    deleteAssetsActionSuccess: ids => simpleAction(types.DELETE_SUCCESS, toList(ids)),
    resetAssetsPagination: () => simpleAction(types.LIST_RESET_PAGINATION_PARAMS),
    createAssetRequest: ({ values, onSuccess = () => {}, onError = () => {}, formName = null }) =>
      simpleAction(types.CREATE_REQUEST, {
        values,
        onSuccess,
        onError,
        formName,
        assetType,
      }),
    shareAssetRequest: ({ values, formName, onSuccess = () => {} }) =>
      simpleAction(types.SHARE_REQUEST, {
        values,
        onSuccess,
        assetType,
        formName,
      }),
    resetAssetsListState: () => simpleAction(types.LIST_RESET),
    resetAssetsDetailsState: () => simpleAction(types.DETAILS_RESET),
    fetchAssetsListFailure: () => simpleAction(types.LIST_FETCH_FAILURE),
    fetchSingleAssetFailure: () => simpleAction(types.DETAILS_FETCH_FAILURE),
    changeRequestedAssetsParams: ({ params, shouldFetch }) =>
      simpleAction(types.LIST_CHANGE_PARAMS, { params, shouldFetch }),
    fetchAssetsListRequest: ({ params, isLoading }) =>
      simpleAction(types.LIST_FETCH_REQUEST, {
        isLoading,
        params,
      }),
    fetchSingleAssetRequest: ({ id, isLoading = true }) =>
      simpleAction(types.DETAILS_FETCH_REQUEST, {
        isLoading,
        assetId: id,
      }),
    fetchAssetsListSuccess: payload => simpleAction(types.LIST_FETCH_SUCCESS, payload),
    fetchSingleAssetSuccess: asset => simpleAction(types.DETAILS_FETCH_SUCCESS, asset),
  };

  return actionsCreators;
}

export default scopedActions;
