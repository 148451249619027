import { removeModal } from 'ducks/ui/modal';
import { reset } from 'redux-form';
import { MODALS } from 'utils/constants';

export const hideModal = dispatch => (shouldResetEarningsPeriodFilter, formId) => {
  if (shouldResetEarningsPeriodFilter) {
    dispatch(reset(formId));
  }
  dispatch(removeModal(MODALS.CUSTOM_RANGE));
};
