import React from 'react';
import PropTypes from 'prop-types';

const AppliedFiltersDisplay = ({ values, resetAppliedFilters }) => (
  <div className="EarningsByBase__label">
    <b>Applied Advanced Filters </b>
    {values.earningsAdvancedFilters && (
      <span>
        Revenue stream: <b>{values.earningsAdvancedFilters}</b>
      </span>
    )}
    {values.earningsAssetTypeAlbum && !values.earningsAssetTypeTrack && (
      <span>
        Asset Types: <b>Album</b>
      </span>
    )}
    {values.earningsAssetTypeTrack && !values.earningsAssetTypeAlbum && (
      <span>
        Asset Types: <b>Track</b>
      </span>
    )}
    {values.earningsAssetTypeTrack && values.earningsAssetTypeAlbum && (
      <span>
        Asset Types: <b>Track, Album</b>
      </span>
    )}
    {values.earningsReportedEarningsMax && values.earningsReportedEarningsMin && (
      <span>
        Your earnings:
        <b>
          <i>
            ${values.earningsReportedEarningsMin} - ${values.earningsReportedEarningsMax}
          </i>
        </b>
      </span>
    )}
    {values.earningsFrom && values.earningsTo && (
      <span>
        Custom period:
        <b>
          <i>
            {values.earningsFrom} - {values.earningsTo}
          </i>
        </b>
      </span>
    )}
    <button type="submit" onClick={resetAppliedFilters}>
      Clear All Filters
    </button>
  </div>
);

AppliedFiltersDisplay.reduxProps = {
  values: PropTypes.object.isRequired,
  resetAppliedFilters: PropTypes.func.isRequired,
};

AppliedFiltersDisplay.propTypes = {
  ...AppliedFiltersDisplay.reduxProps,
};

export default AppliedFiltersDisplay;
