import dayjs from 'dayjs';
import { selectors } from '../../../ducks/earnings/results';
import {
  EARNINGS_PERIODS_CONFIG,
  EARNINGS_REDUX_FORM_CONFIG,
  LICENCE_PARAMS,
  SERVICE_PARAMS,
} from '../../../utils/constants';
import { has } from 'lodash';
import { reduxForm } from 'redux-form';

const NowDate = dayjs()
  .endOf('month')
  .format('MM/DD/YYYY');

const getPeriodConfig = value => EARNINGS_PERIODS_CONFIG[value] || {};

const getLicenseParam = licenceName => LICENCE_PARAMS[licenceName] || '';

const getServiceParam = serviceName => SERVICE_PARAMS[serviceName] || null;

const getPeriodRangeConfig = value => {
  const { period, unit, subtractValue } = getPeriodConfig(value);
  const shouldAddCustomRange = unit && subtractValue;
  const period_from =
    shouldAddCustomRange &&
    dayjs()
      .subtract(subtractValue, unit)
      .add(1, 'month')
      .startOf('month')
      .format('MM/DD/YYYY');

  return { period, period_from, period_to: shouldAddCustomRange && NowDate };
};

export const earningsFilterMapStateToProps = state => {
  return {
    initialValues: selectors.getUpdatedFormValues(state),
  };
};

export const handleLicensesParams = value => ({
  earnings_type: getLicenseParam(value),
});

export const handleServiceParams = value => ({
  service: getServiceParam(value),
});

export const handleMonthsParams = value => getPeriodRangeConfig(value);

export function initReinitializedReduxForm(
  FormComponent,
  reduxFormConfig = EARNINGS_REDUX_FORM_CONFIG
) {
  let FormToConnect = FormComponent;

  FormToConnect = reduxForm(reduxFormConfig)(FormToConnect);

  return FormToConnect;
}

export function handleReduxFormValues(value) {
  const { period, period_from, period_to } = getPeriodRangeConfig(value);

  return period ? [period_from, period_to] : ['', ''];
}

export function handleAdvancedFiltersParams(formKeys, url) {
  let response = {};
  const hasCustomDateKeys = has(formKeys, 'earningsTo') && has(formKeys, 'earningsFrom');

  if (hasCustomDateKeys) {
    response = {
      period: 'Custom',
      period_to: formKeys.earningsTo,
      period_from: formKeys.earningsFrom,
    };
  }

  response = {
    ...response,
    ...handleLicensesParams(formKeys.earningsAdvancedFilters),
    url: url,
    total_earnings_to: formKeys.earningsReportedEarningsMax,
    total_earnings_from: formKeys.earningsReportedEarningsMin,
    'asset-type-album': formKeys.earningsAssetTypeAlbum || undefined,
    'asset-type-track': formKeys.earningsAssetTypeTrack || undefined,
  };
  return response;
}
