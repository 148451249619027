// This will just run the :focus-visible polyfill
// https://drafts.csswg.org/selectors-4/#the-focus-visible-pseudo
// https://github.com/WICG/focus-visible
import 'focus-visible';
import 'utils/focus-visible.scss';
import React from 'react';
import { Provider } from 'react-redux';
import { __TOKEN_COOKIE__, __ACCOUNT_COOKIE__ } from 'utils/constants';
import cookies from 'utils/cookies';
import store from 'store';
import ReactDOM from 'react-dom';
import 'utils/config';
import { redirectToLoginPage } from 'utils/auth';
import history from 'utils/history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { setEarningsHeader } from './utils/impersonation';

// get selected account
const accountUUID = cookies.get(__ACCOUNT_COOKIE__);

if (accountUUID) {
  setEarningsHeader('Account', accountUUID);
}

const token = cookies.get(__TOKEN_COOKIE__);
if (!token) {
  redirectToLoginPage();
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root')
  );
}

if (process.env.REACT_APP_TEST_ARIA_COMP) {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// TODO: utilize service worker with CDN
serviceWorker.unregister();

// Force push
