import { mapValues } from 'lodash';

/**
 * handles the API errors on a failed API POST/UPDATE and makes sure that the redux-form can have
 * an error thrown the way it expects it (in order to properly have the UI updated)
 *
 * @param {Object|Array<Object>} errorData - The error that was catched in the promise `catch` during submission
 * @returns {Object}
 */
export function handleFormErrors(errorData) {
  // if the response of the server is an array we need to flatten it to a single object by merging
  // all the keys found in each object of the array into a single bit object. FYI it's an array
  // when the error occurs through an endpoint that accepts multiple items as payloads (i.e. multiple
  // track update) and an object when the endpoint only accepts a single item (i.e. single collection
  // update)

  // Make sure errorObject is an object:

  // Catch all falsy cases
  let errorObject = !errorData ? {} : errorData;
  // Catch other primitives
  if (
    typeof errorObject === 'string' ||
    typeof errorObject === 'number' ||
    typeof errorObject === 'symbol'
  ) {
    errorObject = {};
  }
  // Catch arrays
  if (Array.isArray(errorObject)) {
    errorObject = errorData.reduce((a, b) => ({ ...a, ...b }), {});
  }

  // now we have a big object with each key being a field of the form and each value being an array
  // of strings (errors). We will combine these values in the array with a `,` between them
  return mapValues(errorObject, errorsArray =>
    errorsArray.reduce((errA, errB) => `${errA} ${errB}`)
  );
}
