import React from 'react';
import PropTypes from 'prop-types';
import useAdminUrl from 'hooks/useAdminUrl';

const TEXT = {
  ADMIN_BTM: 'Admin',
};

const StaffUserTools = ({ isStaff }) => {
  const { ADMIN_BTM } = TEXT;
  const { adminBaseUrl } = useAdminUrl();
  const adminFullUrl = `${adminBaseUrl}/admin`;

  return isStaff ? (
    <>
      <a className="UserCluster__admin-link" href={adminFullUrl}>
        {ADMIN_BTM}
      </a>
    </>
  ) : null;
};

StaffUserTools.reduxProps = {
  isStaff: PropTypes.bool,
};

StaffUserTools.propTypes = {
  ...StaffUserTools.reduxProps,
};

export default StaffUserTools;
