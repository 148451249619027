import { mediaLibAxios, orfiumAxios, earningsAxios } from 'api/axiosInstances';
import { __TOKEN_COOKIE__ } from 'utils/constants';
import cookies from 'utils/cookies';
import analytics from 'utils/analytics';
import 'lazysizes';

/* This file contains code that only runs once during initialization in order to configure stuff */

/* Initialize google analytics script in order to track events */
analytics.init();

/* Browsers like Safari & Edge don't have `requestIdleCallback` so we have to mock it */
if (!window.requestIdleCallback) {
  window.requestIdleCallback = cb => cb();
}

/* We needed a way to add the headers the moment the app boots
if a token exists. The reducer file where `authenticated` state is initialized was an option but i
ended up putting it here because it belongs to an initial async configuration.
 */
const token = cookies.get(__TOKEN_COOKIE__);
if (token) {
  mediaLibAxios.defaults.headers.common.Authorization = `Token ${token}`;
  orfiumAxios.defaults.headers.common.Authorization = `Token ${token}`;
  earningsAxios.defaults.headers.common.Authorization = `Token ${token}`;
} else {
  delete mediaLibAxios.defaults.headers.common.Authorization;
  delete orfiumAxios.defaults.headers.common.Authorization;
  delete earningsAxios.defaults.headers.common.Authorization;
}
