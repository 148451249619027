import { useState, useEffect } from 'react';

const useAdminUrl = () => {
  const [adminBaseUrl, setAdminBaseUrl] = useState('');

  useEffect(() => {
    if (window) {
      // adds window.location origin as a fallback url in case env variable is missing
      setAdminBaseUrl(process.env.REACT_APP_EARNINGS_URL || window.location.origin);
    }
  }, []);

  return { adminBaseUrl };
};

export default useAdminUrl;
