import { ROUTE_CHANGE } from 'ducks/router';
import {
  hideAppliedFiltersDisplay,
  getAppliedFiltersDisplayShow,
} from 'ducks/ui/appliedFiltersDisplay';

const hideAppliedFilters = store => next => action => {
  const state = store.getState();
  if (action.type === ROUTE_CHANGE) {
    if (getAppliedFiltersDisplayShow(state)) {
      store.dispatch(hideAppliedFiltersDisplay());
    }
  }

  next(action);
};

export default hideAppliedFilters;
