import { connect } from 'react-redux';
import { actions } from 'ducks/earnings/results';
import { selectors } from 'ducks/earnings/results';
import { EARNINGS_ENDPOINT, FIXED_EARNINGS_PARAMS } from 'utils/constants';

import EarningsByYoutubeVideo from './EarningsByYoutubeVideo';

const mapStateToProps = state => ({
  isFetching: selectors.getIsFetching(state),
  results: selectors.getItems(state),
  hasMore: selectors.getHasMore(state),
  isInitialFetch: selectors.getIsInitialFetch(state),
  params: selectors.getParams(state),
});

const url = EARNINGS_ENDPOINT.BY_YOUTUBE_VIDEO;

const mapDispatchToProps = dispatch => {
  return {
    reset: () => dispatch(actions.resetAssetsListState()),
    changeParams: ({ params, shouldFetch }) => {
      dispatch(actions.resetAssetsPagination());
      dispatch(
        actions.changeRequestedAssetsParams({
          params: {
            url,
            ...params,
          },
          shouldFetch,
        })
      );
    },
    fetchMore: ({ params, isLoading }) => {
      dispatch(
        actions.fetchAssetsListRequest({
          params: {
            url,
            ...FIXED_EARNINGS_PARAMS,
            ...params,
          },
          isLoading,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarningsByYoutubeVideo);
