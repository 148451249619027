import { ReactComponent as ArtistSVG } from 'assets/artist-icon.svg';
import { ReactComponent as AssetSVG } from 'assets/asset-icon.svg';
import { ReactComponent as YoutubeChannelSVG } from 'assets/bychannel.svg';
import { ReactComponent as PaymentSVG } from 'assets/payments-icon.svg';
import { ReactComponent as RevenueStreamSVG } from 'assets/revenue-stream-icon.svg';
import { ReactComponent as SummarySVG } from 'assets/summary-icon.svg';
import { ReactComponent as YoutubeVideoSVG } from 'assets/youtubevideo-icon.svg';
import { ErrorBoundary } from 'components';
import React, { memo } from 'react';
import urls from 'urls';
import { SidebarApp, SidebarFolder } from '../index';

const SidebarContent = () => (
  <div className="Sidebar__container">
    <ErrorBoundary>
      <SidebarApp.Content>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.summary()}>
            <SidebarFolder.Icon>
              <SummarySVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>Summary</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.revenueStream()}>
            <SidebarFolder.Icon>
              <RevenueStreamSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>By Revenue Stream</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.asset()}>
            <SidebarFolder.Icon>
              <AssetSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>By Asset</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.artist()}>
            <SidebarFolder.Icon>
              <ArtistSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>By Artist</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.youtubeVideo()}>
            <SidebarFolder.Icon>
              <YoutubeVideoSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>By YouTube Video</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.youtubeChannel()}>
            <SidebarFolder.Icon>
              <YoutubeChannelSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>By YouTube Channel</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
        <SidebarFolder>
          <SidebarFolder.Link to={urls.earnings.payments()}>
            <SidebarFolder.Icon>
              <PaymentSVG />
            </SidebarFolder.Icon>
            <SidebarFolder.Label>Payments</SidebarFolder.Label>
          </SidebarFolder.Link>
        </SidebarFolder>
      </SidebarApp.Content>
    </ErrorBoundary>
  </div>
);

export default memo(SidebarContent);
