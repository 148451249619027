import React from 'react';
import { Modal, OrfiumButton } from 'components';
import PropTypes from 'prop-types';
import { ReactComponent as WelcomeSVG } from 'assets/illustration.svg';
import './WelcomeModal.scss';

const WelcomeModal = ({ hide }) => (
  <Modal onClose={hide}>
    <div className="WelcomeModal__container">
      <WelcomeSVG className="WelcomeModal__img" />
      <div className="WelcomeModal__modalTitle">Welcome</div>
      <div className="WelcomeModal__modalSubtitle">to the new Earnings Dashboard</div>
      <p className="WelcomeModal__modalText">
        It’s currently in beta version and our team is always open to suggestions and feedback. You
        can always switch back to the old version
      </p>
      <div className="AssetUploadBase__footer">
        <div className="AssetUploadBase__footer-proceed">
          <OrfiumButton onClick={hide}>Let&apos;s Go!</OrfiumButton>
        </div>
      </div>
    </div>
  </Modal>
);

WelcomeModal.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default WelcomeModal;
