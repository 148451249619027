import * as types from './types';

export const initialState = {
  genres: null,
  subgenres: null,
  artists: null,
};

function options(state = initialState, action) {
  switch (action.type) {
    case types.SET_GENRE_OPTIONS:
      return {
        ...state,
        genres: action.payload,
      };
    case types.SET_SUBGENRE_OPTIONS:
      return {
        ...state,
        subgenres: action.payload,
      };
    case types.SET_ARTIST_OPTIONS:
      return {
        ...state,
        artists: action.payload,
      };
    default:
      return state;
  }
}

export default options;
