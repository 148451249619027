import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeftSVG } from 'assets/arrow-copy.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/arrow-copy-2.svg';
import dayjs from 'dayjs';
import './MonthPicker.scss';

const currentYear = dayjs().year();

class MonthPicker extends React.Component {
  state = {
    date: new Date(),
    year: this.props.year,
    open: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const element = event.target;
    if (this.wrapperRef && !this.wrapperRef.contains(element)) {
      this.close(event);
    }
  };

  handleMonthChange = month => event => {
    event.persist();
    const date = new Date(this.state.year, month);

    this.changeValue(date, event);
    this.close(event);
  };

  handleTriggerClick = event => {
    event.persist();

    const { onFocus, onBlur } = this.props;
    const { open } = this.state;

    this.toggleOpen();

    if (!open && onFocus) {
      onFocus();
    }
    if (open && onBlur) {
      onBlur();
    }
  };

  // Other functions
  nextYear = () => {
    const { allowedYears } = this.props;
    const { year } = this.state;

    if (Array.isArray(allowedYears)) {
      const sortedYears = allowedYears.sort((a, b) => a - b);
      const currentIndex = sortedYears.indexOf(year);

      if (currentIndex < sortedYears.length - 1) {
        const nextYear = sortedYears[currentIndex + 1];
        this.setState({ year: nextYear });
      }
    }

    const nextYear = year + 1;

    if (this.isAllowedYear(nextYear)) {
      this.setState({ year: nextYear });
    }
  };

  previousYear = () => {
    const { allowedYears } = this.props;
    const { year } = this.state;

    if (Array.isArray(allowedYears)) {
      const sortedYears = allowedYears.sort((a, b) => a - b);
      const currentIndex = sortedYears.indexOf(year);

      if (currentIndex > 0) {
        const previousYear = sortedYears[currentIndex - 1];
        this.setState({ year: previousYear });
      }
    }

    const previousYear = year - 1;

    if (this.isAllowedYear(previousYear)) {
      this.setState({ year: previousYear });
    }
  };

  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  close = () => {
    const { onBlur } = this.props;

    if (!this.state.open) return;

    this.setState({ open: false });

    onBlur && onBlur();
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  changeValue = (date, event) => {
    const { changeValue, dateFormat, lastDay } = this.props;
    const newDate = lastDay ? dayjs(date).endOf('month') : date;

    const formattedDate = dayjs(newDate).format(dateFormat);

    this.setState({ date: newDate });
    if (changeValue) {
      changeValue(formattedDate, event);
    }
  };

  isAllowedYear = year => {
    const { allowedYears } = this.props;

    if (!allowedYears) {
      return true;
    }

    if (Array.isArray(allowedYears)) {
      return allowedYears.indexOf(year) >= 0;
    }

    const { before, after } = allowedYears;

    if (before && after) {
      return year < before && year > after;
    }

    if (before) return year < before;
    if (after) return year > after;

    return false;
  };

  renderMonths = () => {
    const { year, date } = this.state;
    const selectedMonth = dayjs(date).month();
    const selectedYear = dayjs(date).year();

    const monthNameFormatter = Intl.DateTimeFormat('en', { month: 'short' });

    return new Array(12).fill(null).map((__item, index) => {
      const monthName = monthNameFormatter.format(new Date(selectedYear, index, 1));

      const isSelectedMonth =
        selectedMonth !== undefined && index === selectedMonth && year === selectedYear;

      return (
        <div
          className={`Month${isSelectedMonth ? '__selected' : ''}`}
          selected={isSelectedMonth}
          key={monthName}
          onClick={this.handleMonthChange(index)}
          index={index}
        >
          {monthName}
        </div>
      );
    });
  };

  render() {
    const { open, year } = this.state;
    const { className } = this.props;

    return (
      <div className={className} tabIndex={-1} ref={this.setWrapperRef}>
        <div onClick={this.handleTriggerClick}>{this.props.children}</div>
        {open && (
          <div className="MonthModal">
            <div className="MonthHeader">
              <span className="Arrow" data-testid="month-arrow-left" onClick={this.previousYear}>
                <ArrowLeftSVG />
              </span>
              <span data-testid="yearId" className="Year">
                {year}
              </span>
              <span className="Arrow" data-testid="month-arrow-right" onClick={this.nextYear}>
                <ArrowRightSVG />
              </span>
            </div>
            <div className="MonthContainer">{this.renderMonths()}</div>
          </div>
        )}
      </div>
    );
  }
}

MonthPicker.propTypes = {
  allowedYears: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      before: PropTypes.number,
      after: PropTypes.number,
    }),
  ]).isRequired,
  year: PropTypes.number,
  lastDay: PropTypes.bool,
  className: PropTypes.string,
  dateFormat: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  changeValue: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

MonthPicker.defaultProps = {
  year: currentYear,
  className: '',
  lastDay: false,
};
export default MonthPicker;
