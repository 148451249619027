import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Select, Search } from 'components';
import { transformStringToDropdownOption } from '../../utils';
import { handleServiceParams, handleReduxFormValues, handleMonthsParams } from '../utils';
import { EARNINGS_PERIODS, EARNINGS_SERVICES } from 'utils/constants';
import './AssetFilterForm.scss';
import { debounce } from 'lodash';

function AssetFilterForm({
  changeParams,
  changeRange,
  pushCustomRangeModal,
  handleSubmit,
  placeholderSearchText,
}) {
  const handleServiceChange = value => {
    changeParams({
      params: {
        ...handleServiceParams(value),
      },
      shouldFetch: true,
    });
  };
  const handleMonthChange = value => {
    if (value === 'Custom Range') {
      pushCustomRangeModal();
    } else {
      changeRange({
        reduxChanges: { ...handleReduxFormValues(value) },
      });
      changeParams({
        params: handleMonthsParams(value),
        shouldFetch: true,
      });
    }
  };
  const handleSearchChange = useCallback(
    debounce(e => {
      changeParams({
        params: {
          q: e.currentTarget.value ? e.currentTarget.value : '',
        },
        shouldFetch: true,
      });
    }, 200),
    []
  );
  // added to silence warnings from Redux-Form
  const EmptyFunction = () => {};

  return (
    <Form onSubmit={handleSubmit(EmptyFunction)}>
      <div className="AssetFilterForm__center">
        <span className="AssetFilterForm__summary_periods">
          <Field
            component={Search}
            onChange={handleSearchChange}
            name="earningsSearch"
            placeholder={placeholderSearchText}
            aligment="left"
            padded
          />
        </span>
        <span className="AssetFilterForm__earnings_periods">
          <Field
            component={Select}
            onChange={handleMonthChange}
            label="Months"
            name="earningsPeriods"
            options={EARNINGS_PERIODS}
            transformOption={transformStringToDropdownOption}
            aligment="left"
            padded
          />
        </span>
        <span className="AssetFilterForm__revenue">
          <Field
            component={Select}
            onChange={handleServiceChange}
            label="Service"
            name="earningsService"
            options={EARNINGS_SERVICES}
            transformOption={transformStringToDropdownOption}
            padded
            aligment="left"
          />
        </span>
      </div>
    </Form>
  );
}

AssetFilterForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

AssetFilterForm.propTypes = {
  ...AssetFilterForm.reduxProps,
};

export default AssetFilterForm;
