import scopedTypes from 'ducks/asset/factory/scopedTypes';

export const SCOPE = 'EARNINGS_BYASSET_';

const scopedTypesList = scopedTypes(SCOPE);

const GET_PAYMENT_ACCOUNTS = 'GET_PAYMENT_ACCOUNTS';
const PAYMENT_ACCOUNTS_SUCCESS = 'PAYMENT_ACCOUNTS_SUCCESS';
const GET_USER_BALANCE = 'GET_USER_BALANCE';
const GET_USER_BALANCE_SUCCESS = 'GET_USER_BALANCE_SUCCESS';
const ADD_PAYMENTS = 'ADD_PAYMENTS';
const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE';
const DELETE_PAYMENTS_ACCOUNT = 'DELETE_PAYMENTS_ACCOUNT';
const FETCH_EXPORT_CSV = 'FETCH_EXPORT_CSV';
const FETCH_EXPORT_FAILURE = 'FETCH_EXPORT_FAILURE';
const FETCH_EXPORT_SUCCESS = 'FETCH_EXPORT_SUCCESS';

export default {
  ...scopedTypesList,
  GET_PAYMENT_ACCOUNTS,
  PAYMENT_ACCOUNTS_SUCCESS,
  GET_USER_BALANCE,
  GET_USER_BALANCE_SUCCESS,
  ADD_PAYMENTS,
  FETCH_PAYMENTS_FAILURE,
  DELETE_PAYMENTS_ACCOUNT,
  FETCH_EXPORT_CSV,
  FETCH_EXPORT_FAILURE,
  FETCH_EXPORT_SUCCESS,
};
