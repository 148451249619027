import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { AppliedFiltersDisplay, EarningsTables, Loader } from 'components';
import Base from '../Base';
import './EarningsByBase.scss';
import { EARNINGS_ENDPOINT } from 'utils/constants';
import TablePlaceholder from 'components/EarningsTables/subcomponents/TablePlaceholder';

const Spinner = ({ hasMore, isFetching }) =>
  hasMore ? (
    <Loader className="AssetListBase__loader" position="relative" />
  ) : (
    !isFetching && <TablePlaceholder key="loader" />
  );

Spinner.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function EarningsByBase({
  results,
  hasMore,
  fetchMore,
  isFetching,
  isInitialFetch,
  changeParams,
  params,
  reset,
  tableViewType,
  topBar,
  url,
  form,
  shouldDisplayAppliedFilters,
  total,
  footerViewType,
  userID,
}) {
  const { order_by, direction, page } = params;

  const [mount, changeMount] = useState(false);

  // whenever the URL or the user ID (meaning impersonation is active) changes, we reset the initial state
  useEffect(() => {
    // This should reset the initial state only when a previous fetching has finished, otherwise it will cancel the on going fetch
    if (!isFetching) {
      reset();
    }

    return () => {
      !isInitialFetch && reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  // This Flag is used to prevent the wrong params from firing.
  // @author Panagiotis V
  useEffect(() => {
    if (page === 1) {
      changeMount(true);
    }
  }, [page]);
  // whenever a Table header is clicked within this page, this func gets executed in order to update the sort states.
  const handleSort = chosenSortKey => {
    // if we are sorting through a different key from our sort key on redux, force this key to be the active one
    if (chosenSortKey !== order_by) {
      changeParams({ params: { order_by: chosenSortKey, page: 1 }, shouldFetch: true });
    } else {
      changeParams({
        params: {
          order_by: chosenSortKey,
          direction: direction === 'asc' ? 'desc' : 'asc',
        },
        shouldFetch: true,
      });
    }
  };
  const handleLoadMore = () => {
    const isSummaryPage = EARNINGS_ENDPOINT.SUMMARY === url;
    const shouldFetchForMore = !isFetching && mount && !isSummaryPage;
    if (shouldFetchForMore) {
      fetchMore({ params, isLoading: true });
    }
  };

  const table = !isInitialFetch && (
    <EarningsTables
      urlLink={url}
      assets={results}
      total={total}
      footerType={footerViewType}
      viewType={tableViewType}
      onSort={handleSort}
      sortKey={order_by}
      sortDir={direction}
    />
  );
  return (
    <Base>
      {topBar}
      {shouldDisplayAppliedFilters && <AppliedFiltersDisplay form={form} />}
      <div className="infinite-scroll-wrapper">
        <InfiniteScroll
          loadMore={handleLoadMore}
          loader={<Spinner key="loader" hasMore={hasMore} isFetching={isFetching} />}
          hasMore={isInitialFetch || hasMore}
          threshold={750}
          initialLoad
        >
          {!hasMore && isFetching ? <TablePlaceholder /> : table}
        </InfiniteScroll>
      </div>
    </Base>
  );
}

EarningsByBase.reduxProps = {
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  isInitialFetch: PropTypes.bool.isRequired,
  changeParams: PropTypes.func.isRequired,
  params: PropTypes.shape({
    order_by: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  shouldDisplayAppliedFilters: PropTypes.bool,
  total: PropTypes.shape({}),
  userID: PropTypes.number,
};

EarningsByBase.propTypes = {
  ...EarningsByBase.reduxProps,
  tableViewType: PropTypes.string.isRequired,
  footerViewType: PropTypes.string,
  topBar: PropTypes.element.isRequired,
  shouldDisplayAppliedFilters: PropTypes.bool,
};

EarningsByBase.defaultProps = {
  shouldDisplayAppliedFilters: false,
  footerViewType: undefined,
};

export default EarningsByBase;
