import { combineReducers } from 'redux';
import options, {
  __REDUX_STATE_KEY__ as __OPTIONS_REDUX_STATE_KEY__,
  epics as globalEpics,
} from './options';
import search, { __REDUX_STATE_KEY__ as __SEARCH_REDUX_STATE_KEY__ } from './search';

export const reducers = combineReducers({
  [__OPTIONS_REDUX_STATE_KEY__]: options,
  [__SEARCH_REDUX_STATE_KEY__]: search,
});

export const epics = globalEpics;
