import { createSelector } from 'reselect';

export const __REDUX_STATE_KEY__ = 'common';
const getReduxStateSlice = state => state.asset[__REDUX_STATE_KEY__];

export const getSelectedAssetIds = state => getReduxStateSlice(state).selectedIds;
export const makeCheckIfSelected = createSelector(
  state => getSelectedAssetIds(state),
  selectedAssetIds => assetId => selectedAssetIds.includes(assetId)
);

export { getReduxStateSlice as getReduxCommonStateSlice };
