import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { actions, selectors } from 'ducks/earnings/results';
import { FORMS, MODALS } from 'utils/constants';
import { maxAllowedPayment } from '../validators';
import PaymentForm from './PaymentForm';
import { removeModal } from 'ducks/ui/modal';
import { get, head } from 'lodash';
import { getPaymentAccountById, isWireTransfer } from '../utils';

const selector = formValueSelector(FORMS.EARNINGS_PAYMENT_FORM);

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: ({ values }) => {
      const formName = FORMS.EARNINGS_PAYMENT_FORM;
      dispatch(actions.addPaymentRequest({ values, formName }));
      dispatch(removeModal(MODALS.PAYMENT_FORM));
    },
  };
};

const mapStateToProps = state => {
  const accounts = selectors.getAvailableAccounts(state);
  const selectedAccountId = selector(state, 'account_id');
  const selectedAccount = getPaymentAccountById(accounts, selectedAccountId);

  const isWireTransferPayment = isWireTransfer(selectedAccount && selectedAccount.type);
  return {
    isWireTransferPayment,
    initialValues: {
      accounts,
      account_id: get(head(selectors.getAvailableAccounts(state)), 'id'),
      max_amount: selectors.getCurrentBalance(state),
      payment_amount: null,
    },
  };
};

const PayForm = reduxForm({
  form: FORMS.EARNINGS_PAYMENT_FORM,
  enableReinitialize: true,
  validate: maxAllowedPayment,
})(PaymentForm);
const PForm = connect(mapStateToProps, mapDispatchToProps)(PayForm);

export default PForm;
