import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as form } from 'redux-form';
import ui from './ui';
import earnings, { epics as earningsEpics } from './earnings';
import { reducers as global, epics as globalEpics } from './global';
import sidebar, {
  __REDUX_STATE_KEY__ as __SIDEBAR_REDUX_STATE_KEY__,
  epics as sidebarEpics,
} from './sidebar';
import user, { __REDUX_STATE_KEY__ as __USER_REDUX_STATE_KEY__, epics as userEpics } from './user';
import asset, { epics as assetEpics } from './asset';

export default combineReducers({
  form,
  ui,
  global,
  earnings,
  asset,
  [__SIDEBAR_REDUX_STATE_KEY__]: sidebar,
  [__USER_REDUX_STATE_KEY__]: user,
});

export const rootEpic = combineEpics(
  userEpics,
  sidebarEpics,
  globalEpics,
  earningsEpics,
  assetEpics
);
