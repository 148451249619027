import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BaseTextField.scss';

function BaseTextField(props) {
  const { input, padded, meta, disabled, label, aligment, children, ...rest } = props;

  const { error, touched } = meta;
  const hasError = !!error && touched;
  const className = classNames({
    BaseTextField: true,
    'BaseTextField--has-error': hasError,
    'BaseTextField--disabled': disabled,
    'BaseTextField--padded': padded,
    [`BaseTextField--label-${aligment}`]: true,
  });

  return (
    <div className={className}>
      {label && <label htmlFor={input.name}>{label}</label>}
      {typeof children === 'function'
        ? children({
            hasError,
            aligment,
            fieldProps: { ...input, ...rest, disabled, id: input.name },
          })
        : children}
      {hasError && (
        <p className={`BaseTextField__error BaseTextField_error_${aligment}`}>{error}</p>
      )}
    </div>
  );
}

BaseTextField.reduxProps = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

BaseTextField.propTypes = {
  ...BaseTextField.reduxProps,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  disabled: PropTypes.bool,
  padded: PropTypes.bool,
  aligment: PropTypes.string,
};

BaseTextField.defaultProps = {
  disabled: false,
  padded: false,
  aligment: 'top',
};

export default BaseTextField;
