import {
  EARNINGS_BYASSET_LIST_TABLE_VIEW,
  EARNINGS_BYARTIST_LIST_TABLE_VIEW,
  EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW,
  EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW,
  EARNINGS_PAYMENTS_LIST_TABLE_VIEW,
  EARNINGS_SUMMARY_LIST_TABLE_VIEW,
  EARNINGS_REVENUE_LIST_TABLE_VIEW,
  EARNINGS_SUMMARY_FOOTER_TABLE_VIEW,
  EARNINGS_REVENUE_FOOTER_TABLE_VIEW,
} from 'utils/constants';
import {
  ByAssetTableListViewColumnHeaders,
  ByAssetTableListViewColumns,
  ByartistTableListViewColumnHeaders,
  ByArtistTableListViewColumns,
  ByYoutubeChannelTableListViewColumnHeaders,
  ByYoutubeChannelTableListViewColumns,
  ByYoutubeVideoTableListViewColumnHeaders,
  ByYoutubeVideoTableListViewColumns,
  PaymentsTableListViewColumnHeaders,
  PaymentsTableListViewColumns,
  SummaryTableListViewCollumnHeaders,
  SummaryTableListViewColumns,
  RevenueTableListViewCollumnHeaders,
  RevenueTableListViewColumns,
  SummaryFooterTableListViewCollumns,
  RevenueFooterListViewCollumns,
} from './subcomponents/Columns';
import {
  isYoutubeVideo,
  isYoutubeChannel,
  isByAsset,
  isByArtist,
  isPayment,
  isSummary,
  isRevenueStream,
  isRevenueStreamFooter,
} from 'utils/common';

export const tableItems = {
  [EARNINGS_SUMMARY_LIST_TABLE_VIEW]: {
    Columns: SummaryTableListViewColumns,
    ColumnHeaders: SummaryTableListViewCollumnHeaders,
    checker: isSummary,
  },
  [EARNINGS_SUMMARY_FOOTER_TABLE_VIEW]: {
    Columns: SummaryFooterTableListViewCollumns,
    checker: isSummary,
  },
  [EARNINGS_REVENUE_LIST_TABLE_VIEW]: {
    Columns: RevenueTableListViewColumns,
    ColumnHeaders: RevenueTableListViewCollumnHeaders,
    checker: isRevenueStream,
  },
  [EARNINGS_REVENUE_FOOTER_TABLE_VIEW]: {
    Columns: RevenueFooterListViewCollumns,
    checker: isRevenueStreamFooter,
  },
  [EARNINGS_BYASSET_LIST_TABLE_VIEW]: {
    Columns: ByAssetTableListViewColumns,
    ColumnHeaders: ByAssetTableListViewColumnHeaders,
    checker: isByAsset,
  },
  [EARNINGS_BYARTIST_LIST_TABLE_VIEW]: {
    Columns: ByArtistTableListViewColumns,
    ColumnHeaders: ByartistTableListViewColumnHeaders,
    checker: isByArtist,
  },
  [EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW]: {
    Columns: ByYoutubeChannelTableListViewColumns,
    ColumnHeaders: ByYoutubeChannelTableListViewColumnHeaders,
    checker: isYoutubeChannel,
  },
  [EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW]: {
    Columns: ByYoutubeVideoTableListViewColumns,
    ColumnHeaders: ByYoutubeVideoTableListViewColumnHeaders,
    checker: isYoutubeVideo,
  },
  [EARNINGS_PAYMENTS_LIST_TABLE_VIEW]: {
    Columns: PaymentsTableListViewColumns,
    ColumnHeaders: PaymentsTableListViewColumnHeaders,
    checker: isPayment,
  },
};
