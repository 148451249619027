import React from 'react';
import PropTypes from 'prop-types';
import './Base.scss';

const Base = ({ sidebarIsOpen, children }) => (
  <main className={`BasePage${!sidebarIsOpen ? ' BasePage--shifted' : ''}`}>{children}</main>
);

Base.reduxProps = {
  sidebarIsOpen: PropTypes.bool.isRequired,
};

Base.propTypes = {
  ...Base.reduxProps,
  children: PropTypes.node.isRequired,
};

export default Base;
