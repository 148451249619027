import { combineEpics } from 'redux-observable';
import { values } from 'lodash';
import allEpics from './epics';

export { default } from './reducer';
export * from './types';
export * from './actions';
export * from './selectors';

export const epics = combineEpics(...values(allEpics));
