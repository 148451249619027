import { createStore as createReduxStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import ReduxThunk from 'redux-thunk';
import { analytics, filterModalAndOverlayAdditions, hideAppliedFilters } from 'middlewares';
import rootReducer, { rootEpic } from './ducks';

let composeEnhancers;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

// we export them in order to be used in our test utils
export const storeMiddlewares = [
  ReduxThunk,
  filterModalAndOverlayAdditions,
  analytics,
  hideAppliedFilters,
];

// a Generic function that returns a new store configured with the reducers & middlewares of Orfium
export const createStore = (initialState = {}) => {
  // the middleware for the epics
  const epicMiddleware = createEpicMiddleware();

  // create the redux store
  const storeInstance = createReduxStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...storeMiddlewares, epicMiddleware))
  );

  // run the epics in order to start piping the actions
  epicMiddleware.run(rootEpic);

  return storeInstance;
};

// the actual front-end store
const store = createStore();
if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

export default store;
