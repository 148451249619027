import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './BalanceManager.scss';

function BalanceManager({ currentEndBalance, fetchInitialBalance, userID }) {
  useEffect(() => {
    if (userID) {
      fetchInitialBalance();
    }
  }, [userID]);

  return (
    <div className="BalanceManager__box">
      <div className="BalanceManager__title">Current End Balance (USD)</div>
      <div className=" BalanceManager__bigFont">
        {currentEndBalance
          ? currentEndBalance.toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })
          : '-'}
      </div>
      {/*<OrfiumButton*/}
      {/*  type="submit"*/}
      {/*  disabled={accounts.length === 0}*/}
      {/*  onClick={pushPaymentFormModal}*/}
      {/*  className="BalanceManager__button"*/}
      {/*>*/}
      {/*  Request Payment*/}
      {/*</OrfiumButton>*/}
    </div>
  );
}

BalanceManager.defaultProps = {
  userID: undefined,
  currentEndBalance: undefined,
};

BalanceManager.propTypes = {
  fetchInitialBalance: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  pushPaymentFormModal: PropTypes.func.isRequired,
  currentEndBalance: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string,
    })
  ).isRequired,
  userID: PropTypes.number,
};

export default BalanceManager;
