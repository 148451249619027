import * as types from './types';

const initialState = {
  show: false,
  content: {},
};

function appliedFiltersDisplay(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_APPLIEDFILTERDISPLAY: {
      return {
        content: action.payload.content,
        show: true,
      };
    }
    case types.UPDATE_APPLIEDFILTERDISPLAY: {
      return {
        ...state,
        content: {
          ...state.content,
          ...action.payload.content,
        },
      };
    }
    case types.HIDE_APPLIEDFILTERDISPLAY:
      return {
        content: {},
        show: false,
      };
    default:
      return state;
  }
}

export default appliedFiltersDisplay;
