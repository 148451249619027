import { mediaLibAxios as axios } from '../axiosInstances';
import { selectApiSubmitableFields } from '../utils';

export default {
  get: (collectionId, params = {}, cancelToken) =>
    axios
      .get(`/folders/${collectionId}/`, { params, cancelToken })
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  create: asset =>
    axios
      .post('/folders/', selectApiSubmitableFields(asset))
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  update: (collectionId, payload) =>
    axios
      .put(`/folders/${collectionId}/`, payload)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),

  delete: collectionId =>
    axios
      .delete(`/folders/${collectionId}/`)
      .then(({ data }) => data)
      .catch(error => {
        throw error.response ? error.response.data : error;
      }),
};
