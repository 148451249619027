import types from './types';
import actions from './actions';
import selectors from './selectors';
import scopedEpics from 'ducks/asset/factory/scopedEpics';
import { earningsAPI } from 'api';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { startSubmit, stopSubmit } from 'redux-form';
import { EARNINGS_ENDPOINT } from 'utils/constants';

const url = EARNINGS_ENDPOINT.PAYMENT;
const scopedEpicsList = scopedEpics(types, actions, selectors, earningsAPI);

const getPaymentAccountsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_PAYMENT_ACCOUNTS),
    switchMap(() =>
      from(earningsAPI.single.getPaymentAccountsAPI()).pipe(
        map(asset => actions.fetchPaymentsAccountsSuccess(asset)),
        catchError(() => of(actions.fetchPaymentFailure()))
      )
    )
  );

const getUserBalanceEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USER_BALANCE),
    switchMap(() =>
      from(earningsAPI.single.getCurrentBalance()).pipe(
        map(asset => actions.fetchUserBalanceSuccess(asset)),
        catchError(() => of(actions.fetchPaymentFailure()))
      )
    )
  );

const addPaymentRequestEpic = action$ =>
  action$.pipe(
    ofType(types.ADD_PAYMENTS),
    switchMap(({ payload: { values, formName } }) => {
      return concat(
        of(startSubmit(formName)),
        from(earningsAPI.single.createPayment(values.account_id, values.payment_amount)).pipe(
          mergeMap(() => {
            return of(
              stopSubmit(formName),
              actions.resetAssetsPagination(),
              actions.changeRequestedAssetsParams({
                params: {
                  url,
                },
                shouldFetch: true,
              })
            );
          }),
          catchError(() => {
            return of(stopSubmit(formName));
          })
        )
      );
    })
  );
const deletePaymentAccountRequestEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_PAYMENTS_ACCOUNT),
    switchMap(({ payload: { accountId } }) => {
      return concat(
        from(earningsAPI.single.deletePaypalAccount(accountId)).pipe(
          map(() => actions.getPaymentsAccounts()),
          catchError(() => of(actions.fetchPaymentFailure()))
        )
      );
    })
  );
const fetchExportCSVRequestEpic = action$ =>
  action$.pipe(
    ofType(types.FETCH_EXPORT_CSV),
    switchMap(({ payload }) => {
      return from(earningsAPI.single.getExportCSV(payload.params)).pipe(
        map(() => actions.fetchExportCSVSuccss()),
        catchError(() => of(actions.fetchExportCSVFailure()))
      );
    })
  );

export default {
  ...scopedEpicsList,
  addPaymentRequestEpic,
  getPaymentAccountsEpic,
  getUserBalanceEpic,
  deletePaymentAccountRequestEpic,
  fetchExportCSVRequestEpic,
};
