import { ReactComponent as CaretSVG } from 'assets/caret--alt.svg';
import { Popup } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { redirectToLogoutPage } from 'utils/auth';
import { withOrfiumAvatarFallback } from 'utils/common';
import { __ACCOUNT_COOKIE__, __IMPERSONATOR_TOKEN_COOKIE__ } from 'utils/constants';

import cookies from 'utils/cookies';
import StaffUserTools from './StaffUserTools';
import OrganizationSelect from './OrganizationSelect';

import './UserCluster.scss';

function UserCluster(props) {
  const impersonationIsActive = !!cookies.get(__IMPERSONATOR_TOKEN_COOKIE__);

  // get selected account
  const accountUUID = cookies.get(__ACCOUNT_COOKIE__);
  const selectedOrganization = (props.organizations || []).find(
    organization => organization.orfium_uuid === accountUUID
  );

  return (
    <Popup
      trigger={
        <button type="button" className="UserCluster" aria-haspopup="true">
          <img
            alt="Avatar"
            src={withOrfiumAvatarFallback(props.avatar)}
            className="UserCluster__avatar"
          />
          <span className="UserCluster__name">
            {props.name}
            {selectedOrganization && ` (${selectedOrganization.name})`}
          </span>
          <CaretSVG />
        </button>
      }
    >
      <OrganizationSelect
        organizations={props.organizations}
        selectedOrganizationId={accountUUID}
      />
      <StaffUserTools isStaff={props.isStaff} />

      {impersonationIsActive && (
        <button id="cancel-impersonation" onClick={props.cancelImpersonation}>
          Stop impersonation
        </button>
      )}

      <button type="button" onClick={redirectToLogoutPage}>
        Logout
      </button>
    </Popup>
  );
}

UserCluster.reduxProps = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
  isStaff: PropTypes.bool,
  organizations: PropTypes.array,
  pushModal: PropTypes.func,
  cancelImpersonation: PropTypes.func,
  handleOrganizationSelect: PropTypes.func,
};

UserCluster.propTypes = {
  ...UserCluster.reduxProps,
};

// ESLint can't realize that we are spreading the propTypes above, so it thinks that these
// defaultProps are not mapped to anything
/* eslint-disable react/default-props-match-prop-types */
UserCluster.defaultProps = {
  avatar: '',
  name: '',
  username: '',
};
/* eslint-enable react/default-props-match-prop-types */

export default UserCluster;
