import React from 'react';
import { ReactComponent as CaretIconSVG } from 'assets/arrow--fat.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// renders a <th> with some extra handlers
function TableHeader({ label, sortKey, className = '', onSort, activeSortKey, activeSortDir }) {
  const columnIsSortable = Boolean(onSort) && Boolean(sortKey);

  // if sorting is enabled (onSort func is defined)
  if (columnIsSortable) {
    // we render a header with sorting handles and UIs
    const headerIsActivelySorted = sortKey === activeSortKey;
    const iconClassName = `AssetTable__sortable-icon AssetTable__sortable-icon--${activeSortDir}`;
    const headerClassName = classNames({
      [className]: true,
      active: headerIsActivelySorted,
    });

    return (
      <th role="columnheader" className={headerClassName} onClick={() => onSort(sortKey)}>
        {label && <span>{label}</span>}
        {headerIsActivelySorted && <CaretIconSVG className={iconClassName} />}
      </th>
    );
  }

  // if it's not enabled we simply return a normal header
  return (
    <th role="columnheader" className={className || undefined}>
      <span>{label}</span>
    </th>
  );
}

TableHeader.propTypes = {
  label: PropTypes.string,
  onSort: PropTypes.func,
  activeSortKey: PropTypes.string,
  activeSortDir: PropTypes.string,
  sortKey: PropTypes.string,
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  label: null,
  onSort: null,
  activeSortKey: null,
  activeSortDir: null,
  className: '',
  sortKey: null,
};

export default TableHeader;
