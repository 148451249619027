import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BaseToggleField.scss';

function BaseToggleField(props) {
  const { input, padded, disabled, label, labelAlignment, children, ...rest } = props;
  const nonReduxFormProps = omit(rest, 'meta');

  const className = classNames({
    [`BaseToggleField BaseToggleField--${labelAlignment}`]: true,
    'BaseToggleField--disabled': disabled,
    'BaseToggleField--padded': padded,
  });

  return (
    <div className={className}>
      <label htmlFor={input.name}>{label}</label>
      {typeof children === 'function'
        ? children({
            fieldProps: { ...input, ...nonReduxFormProps, disabled, id: input.name },
          })
        : children}
    </div>
  );
}

BaseToggleField.reduxProps = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

BaseToggleField.propTypes = {
  ...BaseToggleField.reduxProps,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  labelAlignment: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  padded: PropTypes.bool,
};

BaseToggleField.defaultProps = {
  labelAlignment: 'left',
  disabled: false,
  padded: false,
};

export default BaseToggleField;
