import types from './types';
import scopedActions from '../factory/scopedActions';
import { collectionAPI } from 'api';
import { simpleAction } from 'ducks/utils';

const allActions = scopedActions(types, collectionAPI);

const uploadAlbumRequest = ({
  asset,
  tracks,
  onSuccess = () => {},
  onError = () => {},
  formName = null,
}) =>
  simpleAction(types.UPLOAD_ALBUM_REQUEST, {
    asset,
    tracks,
    formName,
    onSuccess,
    onError,
  });

const uploadAlbumSuccess = () => simpleAction(types.UPLOAD_ALBUM_SUCCESS);

const uploadAlbumFail = () => simpleAction(types.UPLOAD_ALBUM_FAIL);

export default {
  ...allActions,
  uploadAlbumRequest,
  uploadAlbumSuccess,
  uploadAlbumFail,
};
