/**
 * sends the user to the login page on the main Orfium website
 */
import { __IMPERSONATOR_TOKEN_COOKIE__, __TOKEN_COOKIE__ } from 'utils/constants';
import cookies from 'utils/cookies';

export function redirectToLoginPage() {
  const { href: currentPage } = window.location;
  window.location.href = `${process.env.REACT_APP_ORFIUM_DOMAIN}/accounts/login/?next=${currentPage}`;
}

/**
 * sends the user to the logout page on the main Orfium website
 */
export function redirectToLogoutPage() {
  const { href: currentPage } = window.location;

  cookies.remove(__TOKEN_COOKIE__);
  cookies.remove(__IMPERSONATOR_TOKEN_COOKIE__);

  window.location.href = `${process.env.REACT_APP_ORFIUM_DOMAIN}/accounts/logout/?next=${currentPage}`;
}
