import React from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import BaseTextField from '../BaseTextField';

class Input extends React.Component {
  componentDidMount() {
    this.props.decorateInput(this.html5InputElement);
  }

  handleRef = html5InputElement => {
    this.html5InputElement = html5InputElement;
  };

  render() {
    return (
      <BaseTextField {...omit(this.props, 'decorateInput')}>
        {({ fieldProps }) => <input ref={this.handleRef} {...fieldProps} />}
      </BaseTextField>
    );
  }
}

Input.reduxProps = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

Input.propTypes = {
  ...Input.reduxProps,
  decorateInput: PropTypes.func, // callback for adding autocomplete or other functionalities,
};

Input.defaultProps = {
  decorateInput: () => {},
};

export default Input;
