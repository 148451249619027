import { YoutubeChannelFilterForm } from 'components/Forms/FiltersForm';
import { EarningByBase } from 'pages/utils';
import React, { Fragment } from 'react';
import { ReportIcons } from 'components';
import {
  EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW,
  EARNINGS_ENDPOINT,
  FORMS,
} from 'utils/constants';
import './EarningsByYoutubeChannel.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Earnings By YouTube Channel</div>
    </div>
    <div className="EarningsByYoutubeChannel__center">
      <span className="EarningsByYoutubeChannel__filters">
        <YoutubeChannelFilterForm form={FORMS.EARNINGS_YOUTUBE_CHANNEL} />
      </span>
      <div className="EarningsByYoutubeChannel__report_icons">
        <ReportIcons url={EARNINGS_ENDPOINT.BY_YOUTUBE_CHANNEL} />
      </div>
    </div>
  </Fragment>
);

const EarningsByYoutubeChannel = props => (
  <EarningByBase
    {...props}
    url={EARNINGS_ENDPOINT.BY_YOUTUBE_CHANNEL}
    topBar={<HeaderOfPage />}
    tableViewType={EARNINGS_BYYOUTUBECHANNEL_LIST_TABLE_VIEW}
  />
);

export default EarningsByYoutubeChannel;
