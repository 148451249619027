import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Input, MonthPickerInput, OrfiumButton, Select } from 'components';
import { CheckboxInput } from 'components/Forms/subcomponents';
import { transformStringToDropdownOption } from '../../utils';
import { ADVANCED_FILTERS_EARNINGS } from 'utils/constants';
import { guid } from 'utils/common';
import './AdvancedFilterForm.scss';

class AdvancedFilterForm extends React.PureComponent {
  handleSubmit = values => {
    this.props.onSubmit({ values });
  };

  render() {
    const { handleSubmit, submitting, error, valid } = this.props;

    return (
      <Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className="AdvancedFilterForm__padding">
            <span className="AdvancedFilterForm__assetsType">
              <label className="AdvancedFilterForm__assetsType-label" htmlFor="assetTypes">
                Asset Types
              </label>
              <Field component={CheckboxInput} label="Track" name="earningsAssetTypeTrack" />
              <Field component={CheckboxInput} label="Album" name="earningsAssetTypeAlbum" />
            </span>
            <span className="AdvancedFilterForm__earnings_type">
              <Field
                component={Select}
                label="Earnings Type"
                name="earningsAdvancedFilters"
                options={ADVANCED_FILTERS_EARNINGS}
                transformOption={transformStringToDropdownOption}
                aligment="left"
                padded
              />
            </span>
            <span className="AdvancedFilterForm__earnings">
              <span className="AdvancedFilterForm__earnings__min">
                <Field
                  component={Input}
                  label="Your Earnings"
                  name="earningsReportedEarningsMin"
                  type="number"
                  min="0"
                  placeholder="Min (USD)"
                  aligment="left"
                  padded
                />
              </span>
              <span className="AdvancedFilterForm__earnings__max">
                <Field
                  component={Input}
                  label=""
                  name="earningsReportedEarningsMax"
                  type="number"
                  min="0"
                  placeholder="Max (USD)"
                  aligment="left"
                  padded
                />
              </span>
            </span>
            <span className="AdvancedFilterForm__month_range">
              <span className="AdvancedFilterForm__month_range__min">
                <Field
                  component={MonthPickerInput}
                  name="earningsFrom"
                  placeholder="From"
                  aligment="left"
                  dateFormat="MM/DD/YYYY"
                  label="Month Range"
                  padded
                />
              </span>
              <span className="AdvancedFilterForm__month_range__max">
                <Field
                  component={MonthPickerInput}
                  name="earningsTo"
                  placeholder="To"
                  dateFormat="MM/DD/YYYY"
                  label=""
                  aligment="left"
                  lastDay
                  padded
                />
              </span>
            </span>
            {error && (
              <div className="AdvancedFilterForm__error">
                {error.map(item => (
                  <p key={guid()}>{item}</p>
                ))}
              </div>
            )}
          </div>
          <div className="AdvancedFilterForm__footer">
            <OrfiumButton type="submit" disabled={!valid || submitting}>
              {'Apply Filters'}
            </OrfiumButton>
          </div>
        </Form>
      </Fragment>
    );
  }
}

AdvancedFilterForm.reduxProps = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

AdvancedFilterForm.propTypes = {
  ...AdvancedFilterForm.reduxProps,
  onSubmit: PropTypes.func.isRequired,
};

export default AdvancedFilterForm;
