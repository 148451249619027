import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LoaderSVG } from 'assets/loader.svg';
import './Loader.scss';

function Loader({ position, size, className, ...rest }) {
  return (
    <div className={`Loader Loader--${position} Loader--${size} ${className}`} {...rest}>
      <LoaderSVG />
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['relative', 'fixed']),
  size: PropTypes.oneOf(['small', 'big']),
};

Loader.defaultProps = {
  className: '',
  position: 'fixed',
  size: 'big',
};

export default Loader;
