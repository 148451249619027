export const __REDUX_STATE_KEY__ = 'sidebar';
export const getReduxStateSlice = (state, assetType) => state[__REDUX_STATE_KEY__][assetType];

export const makeGetSidebarAssetParams = assetType => state =>
  getReduxStateSlice(state, assetType).params;

export const makeGetSidebarAssets = assetType => state =>
  getReduxStateSlice(state, assetType).items;

export const makeGetSidebarAssetIsFetching = assetType => state =>
  getReduxStateSlice(state, assetType).isFetching;

export const makeGetSidebarAssetHasMore = assetType => state =>
  getReduxStateSlice(state, assetType).hasMore;

export const makeGetSidebarAssetIsInitialFetch = assetType => state =>
  getReduxStateSlice(state, assetType).isInitialFetch;
