import React from 'react';
import PropTypes from 'prop-types';
import { UserCluster } from '../index';

const HeaderToolkit = ({ cssClass }) => (
  <div className={cssClass}>
    <UserCluster />
  </div>
);

HeaderToolkit.defaultProps = {
  cssClass: 'Header__right',
};

HeaderToolkit.propTypes = {
  cssClass: PropTypes.string,
};

export default HeaderToolkit;
