import {
  SET_USER_INFO,
  FETCH_USER_INFO_REQUEST,
  FETCH_PERMISSIONS,
  SET_PERMISSIONS,
} from './types';
import { simpleAction } from '../utils';

/* We needed a way to add the headers the moment the app boots
if a token exists. The reducer file where `authenticated` state is initialized was an option but i
ended up putting it here because it belongs to an initial async configuration.
 */

export function setUserInfo(payload) {
  return simpleAction(SET_USER_INFO, payload);
}

export function setPermissions(payload) {
  return simpleAction(SET_PERMISSIONS, payload);
}

export const fetchUserInfoRequest = (onSuccess = () => {}, onError = () => {}) =>
  simpleAction(FETCH_USER_INFO_REQUEST, {
    onSuccess,
    onError,
  });

export const fetchPermissions = (onSuccess = () => {}, onError = () => {}) =>
  simpleAction(FETCH_PERMISSIONS, {
    onSuccess,
    onError,
  });
