import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { negativeCSSclass, amountWithCommas } from './utils';

const RevenueFooterListViewCollumns = ({ asset: revenueStream }) => (
  <React.Fragment>
    <td colSpan="2">Overall (All Streams) </td>
    <td className="EarningsTables__cell--centered">
      {amountWithCommas(revenueStream.gross_earnings)}
    </td>
    <td
      className={`EarningsTables__cell--centered ${negativeCSSclass(
        revenueStream.transaction_fees
      )}`}
    >
      {' '}
      {amountWithCommas(-revenueStream.transaction_fees) || 0}
    </td>
    <td
      className={`EarningsTables__cell--centered EarningsTables__lightMediumGrey ${negativeCSSclass(
        revenueStream.orfium_share
      )}`}
    >
      {amountWithCommas(-revenueStream.orfium_share) || 0}
    </td>
    <td className="EarningsTables__cell--right EarningsTables__mediumGrey">
      {amountWithCommas(revenueStream.net_earnings)}
    </td>
  </React.Fragment>
);

RevenueFooterListViewCollumns.propTypes = {
  asset: PropTypes.shape(shapes.byRevenueStreamFooter).isRequired,
};

export default RevenueFooterListViewCollumns;
