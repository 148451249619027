import { ofType } from 'redux-observable';
import { MODALS } from 'utils/constants';
import { catchError, mergeMap, switchMap, takeUntil, map, filter } from 'rxjs/operators';
import { from, of, merge, concat } from 'rxjs';
import { parseQueryParamsFromURL } from 'utils/common';
import { itemAPI } from 'api';
import { clearSelectedAssets } from 'ducks/asset/common/actions';
import { removeOverlay } from 'ducks/ui/overlay';
import { removeModal } from 'ducks/ui/modal';
import { pushInfoSnackbar, pushWarningSnackbar } from 'ducks/ui/snackbar';
import { handleFormErrors } from 'utils/forms';
import { startSubmit, stopSubmit } from 'redux-form';
import { CancelToken } from 'axios';

export default function scopedEpics(types, actions, selectors, api = itemAPI) {
  const fetchAllAssetsEpic = action$ =>
    action$.pipe(
      // filter only this action
      ofType(types.LIST_FETCH_REQUEST),

      // map each action to an API request
      switchMap(({ payload }) => {
        // create a cancel token for the request
        const source = CancelToken.source();
        // create a stream from the Promise
        return from(api.bulk.get(payload.params, source.token)).pipe(
          // map each resolved value and flatten it since we might return 2 values from a single
          // map operator
          mergeMap(({ results, next, total }) => {
            const isNextExists = !!next;
            const successAction = actions.fetchAssetsListSuccess({
              assets: results,
              hasMore: Boolean(isNextExists),
              total: total,
            });

            if (isNextExists) {
              return merge(
                of(
                  actions.changeRequestedAssetsParams({
                    params: parseQueryParamsFromURL(next),
                    shouldFetch: false,
                  })
                ),
                of(successAction)
              );
            }

            return of(successAction);
          }),

          // unsubscribe from the inner stream (API request stream) when one of these actions occurs
          takeUntil(
            action$.pipe(
              ofType(types.LIST_RESET, types.LIST_RESET_PAGINATION_PARAMS),
              map(() => {
                // cancel the request as a side effect
                source.cancel();
              })
            )
          ),

          // handle the error of the API request stream
          catchError(() => of(actions.fetchAssetsListFailure()))
        );
      })
    );

  const changeRequestedAssetsParamsEpic = (action$, store$) =>
    action$.pipe(
      // filter only this action
      ofType(types.LIST_CHANGE_PARAMS),
      filter(({ payload }) => payload.shouldFetch),

      // map each action to an API request
      map(() =>
        actions.fetchAssetsListRequest({
          params: selectors.getParams(store$.value),
          isLoading: false,
        })
      )
    );

  const fetchSingleEpic = action$ =>
    action$.pipe(
      ofType(types.DETAILS_FETCH_REQUEST),
      switchMap(({ payload }) => {
        // create a cancel token for the request
        const source = CancelToken.source();
        return from(api.single.get(payload.assetId, {}, source.token)).pipe(
          map(asset => actions.fetchSingleAssetSuccess(asset)),
          takeUntil(
            action$.pipe(
              ofType(types.DETAILS_RESET),
              map(() => {
                // cancel the request as a side effect
                source.cancel();
              })
            )
          ),
          catchError(() => of(actions.fetchSingleAssetFailure()))
        );
      })
    );

  const deleteAssetsEpic = action$ =>
    action$.pipe(
      ofType(types.DELETE_REQUEST),
      switchMap(({ payload: { ids, assetType, onSuccess, onError } }) =>
        concat(
          of(removeModal(MODALS.DELETE)),
          from(api.bulk.delete(ids)).pipe(
            mergeMap(() => {
              onSuccess();
              return of(
                actions.deleteAssetsActionSuccess(ids),
                clearSelectedAssets(),
                pushInfoSnackbar(`${assetType}${ids.length > 1 ? 's' : ''} deleted successfully`)
              );
            }),
            catchError(errorData => {
              onError(errorData);
              return of(
                pushWarningSnackbar(
                  `Some ${assetType}${ids.length > 1 ? 's' : ''} could not be deleted`
                )
              );
            })
          )
        )
      )
    );

  const createSingleEpic = action$ =>
    action$.pipe(
      ofType(types.CREATE_REQUEST),
      switchMap(({ payload: { assetType, values, onSuccess, formName } }) =>
        concat(
          of(startSubmit(formName)),
          from(api.single.create(values)).pipe(
            mergeMap(asset => {
              onSuccess(asset);
              return of(
                removeOverlay(formName),
                stopSubmit(formName),
                actions.addAssetsAction(asset),
                clearSelectedAssets(),
                pushInfoSnackbar(`${assetType} created successfully`)
              );
            }),
            catchError(errorData => {
              return of(stopSubmit(formName, handleFormErrors(errorData)));
            })
          )
        )
      )
    );

  const shareAssetEpic = action$ =>
    action$.pipe(
      ofType(types.SHARE_REQUEST),
      switchMap(({ payload: { assetType, values, onSuccess, formName } }) =>
        concat(
          of(startSubmit(formName)),
          from(api.single.create(values)).pipe(
            mergeMap(asset => {
              onSuccess(asset);
              return of(
                stopSubmit(formName),
                actions.addAssetsAction(asset),
                clearSelectedAssets()
              );
            }),
            catchError(errorData =>
              of(
                stopSubmit(formName, handleFormErrors(errorData)),
                pushWarningSnackbar(`Failed to create shareable ${assetType}`)
              )
            )
          )
        )
      )
    );

  const updateSingleEpic = action$ =>
    action$.pipe(
      ofType(types.SINGLE_UPDATE_REQUEST),
      switchMap(
        ({ payload: { assetType, assetId, values, onSuccess, skipNotifications, formName } }) =>
          concat(
            of(startSubmit(formName)),
            from(api.single.update(assetId, values)).pipe(
              mergeMap(asset => {
                onSuccess(asset);
                const actionsToFire$ = of(
                  removeOverlay(formName),
                  stopSubmit(formName),
                  actions.updateAssetsAction(asset),
                  clearSelectedAssets()
                );

                return skipNotifications
                  ? actionsToFire$
                  : merge(
                      actionsToFire$,
                      of(pushInfoSnackbar(`${assetType} updated successfully`))
                    );
              }),
              catchError(errorData => {
                if (!skipNotifications) {
                  return of(
                    stopSubmit(formName, handleFormErrors(errorData)),
                    pushWarningSnackbar(`Failed to update ${assetType}`)
                  );
                }
                return of(stopSubmit(formName, handleFormErrors(errorData)));
              })
            )
          )
      )
    );

  const addTracksToAssetEpic = action$ =>
    action$.pipe(
      ofType(types.ADDING_TRACKS_REQUEST),
      switchMap(({ payload: { assetType, assetId, values } }) =>
        from(api.single.update(assetId, values)).pipe(
          mergeMap(asset =>
            of(
              actions.updateAssetsAction(asset),
              clearSelectedAssets(),
              pushInfoSnackbar(`${assetType} updated successfully`)
            )
          ),
          catchError(__errorData => {
            return of(pushWarningSnackbar(`Failed to update ${assetType}`));
          })
        )
      )
    );

  const removeTracksFromAssetEpic = action$ =>
    action$.pipe(
      ofType(types.REMOVING_TRACKS_REQUEST),
      switchMap(({ payload: { assetType, assetId, values } }) =>
        from(api.single.update(assetId, values)).pipe(
          mergeMap(asset =>
            of(
              actions.updateAssetsAction(asset),
              clearSelectedAssets(),
              pushInfoSnackbar(`${assetType} updated successfully`)
            )
          ),
          catchError(__errorData => {
            return of(pushWarningSnackbar(`Failed to update ${assetType}`));
          })
        )
      )
    );

  const updateAssetsEpic = action$ =>
    action$.pipe(
      ofType(types.BULK_UPDATE_REQUEST),
      switchMap(({ payload: { updatedAssets, onSuccess, onError } }) =>
        concat(
          from(api.bulk.update(updatedAssets)).pipe(
            mergeMap(assets => {
              onSuccess(assets);
              return of(actions.updateAssetsAction(assets), clearSelectedAssets());
            }),
            catchError(() => {
              onError();
            })
          )
        )
      )
    );

  const addAssetsEpic = action$ =>
    action$.pipe(
      ofType(types.ADD_REQUEST),
      switchMap(({ payload: { assetType, assets, onSuccess, formName } }) =>
        concat(
          of(startSubmit(formName)),
          from(api.bulk.create(assets)).pipe(
            mergeMap(result => {
              onSuccess();
              return of(
                removeOverlay(formName),
                stopSubmit(formName),
                actions.addAssetsAction(result)
              );
            }),
            catchError(errorData => {
              return of(
                stopSubmit(formName, handleFormErrors(errorData)),
                pushWarningSnackbar(`Failed to save ${assetType}s to the server`)
              );
            })
          )
        )
      )
    );

  return {
    changeRequestedAssetsParamsEpic,
    fetchAllAssetsEpic,
    fetchSingleEpic,
    deleteAssetsEpic,
    createSingleEpic,
    addAssetsEpic,
    updateSingleEpic,
    updateAssetsEpic,
    addTracksToAssetEpic,
    removeTracksFromAssetEpic,
    shareAssetEpic,
  };
}
