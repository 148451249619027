import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { PaymentForm } from 'components/Forms';

const PaymentFormModal = ({ hide, form }) => (
  <Modal title="Retrieve  Payment" onClose={hide} data-testid="paymentform-modal">
    <div className="CustomRangeFilterModal">
      <PaymentForm closeModal={hide} form={form} />
    </div>
  </Modal>
);

PaymentFormModal.propTypes = {
  hide: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
};

export default PaymentFormModal;
