import React, { useEffect, useState } from 'react';

const SVG_RECTS = {
  MID: (
    <>
      <rect x="10" y="45" rx="3" ry="3" width="100%" height="17"></rect>
      <rect x="78" y="115" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="114" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="155" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="78" y="176" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="175" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="216" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="78" y="234" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="233" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="274" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="78" y="295" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="294" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="335" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="78" y="355" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="354" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="395" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="78" y="416" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="415" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="453" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="10" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <rect x="999" y="49" rx="3" ry="3" width="2" height="465"></rect>
      <rect x="78" y="476" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15"></rect>
      <rect x="690" y="475" rx="3" ry="3" width="141" height="15"></rect>
      <rect x="10" y="513" rx="3" ry="3" width="100%" height="2"></rect>
      <rect x="10" y="80" rx="3" ry="3" width="100%" height="17"></rect>
      <rect x="10" y="57" rx="3" ry="3" width="68" height="33"></rect>
      <rect x="222" y="54" rx="3" ry="3" width="141" height="33"></rect>
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33"></rect>
      <rect x="840" y="56" rx="3" ry="3" width="160" height="33"></rect>
    </>
  ),
  LARGE: (
    <>
      <rect x="10" y="45" rx="3" ry="3" width="100%" height="17" />
      <circle cx="1400" cy="123" r="11" />
      <circle cx="1450" cy="123" r="11" />
      <rect x="78" y="115" rx="3" ry="3" width="141" height="15" />
      <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="114" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="155" rx="3" ry="3" width="100%" height="2" />
      <circle cx="1401" cy="184" r="11" />
      <circle cx="1451" cy="184" r="11" />
      <rect x="78" y="176" rx="3" ry="3" width="141" height="15" />
      <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="175" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="216" rx="3" ry="3" width="100%" height="2" />
      <circle cx="1402" cy="242" r="11" />
      <circle cx="1452" cy="242" r="11" />
      <rect x="78" y="234" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="233" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="274" rx="3" ry="3" width="100%" height="2" />
      <circle cx="1403" cy="303" r="11" />
      <circle cx="1453" cy="303" r="11" />
      <rect x="78" y="295" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="294" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="335" rx="3" ry="3" width="100%" height="2" />
      <circle cx="1401" cy="363" r="11" />
      <circle cx="1451" cy="363" r="11" />
      <rect x="78" y="355" rx="3" ry="3" width="141" height="15" />
      <rect x="307" y="354" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="354" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="395" rx="3" ry="3" width="100%" height="2" />
      <circle cx="1402" cy="424" r="11" />
      <circle cx="1452" cy="424" r="11" />
      <rect x="78" y="416" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="415" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="415" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="453" rx="3" ry="3" width="100%" height="2" />
      <rect x="10" y="49" rx="3" ry="3" width="2" height="465" />
      <rect x="1598" y="49" rx="3" ry="3" width="2" height="465" />
      <circle cx="1403" cy="484" r="11" />
      <circle cx="1453" cy="484" r="11" />
      <rect x="78" y="476" rx="3" ry="3" width="141" height="15" />
      <rect x="308" y="475" rx="3" ry="3" width="299" height="15" />
      <rect x="690" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="985" y="475" rx="3" ry="3" width="141" height="15" />
      <rect x="10" y="513" rx="3" ry="3" width="100%" height="2" />
      <rect x="10" y="80" rx="3" ry="3" width="100%" height="17" />
      <rect x="10" y="57" rx="3" ry="3" width="68" height="33" />
      <rect x="222" y="54" rx="3" ry="3" width="141" height="33" />
      <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
      <rect x="840" y="56" rx="3" ry="3" width="172" height="33" />
      <rect x="1100" y="54" rx="3" ry="3" width="200" height="33" />
      <rect x="1550" y="54" rx="3" ry="3" width="100" height="33" />
    </>
  ),
};

const SCREEN_LIMITS = {
  LARGE: 1600,
  MID: 1000,
};

export const usePlaceholderSvgRects = () => {
  const [rects, setRects] = useState('');
  const [placeholderWidth, setPlaceholderWidth] = useState(0);

  useEffect(() => {
    const currentWidth = window && window.innerWidth;
    const { LARGE, MID } = SCREEN_LIMITS;
    const isScreenLarge = currentWidth >= LARGE;
    const viewBoxWidth = isScreenLarge ? LARGE : MID;
    const svgRects = isScreenLarge ? SVG_RECTS.LARGE : SVG_RECTS.MID;

    setRects(svgRects);
    setPlaceholderWidth(viewBoxWidth);
  }, []);

  return { rects, placeholderWidth };
};
