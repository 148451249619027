import { BalanceManager } from 'components';
import React, { Fragment } from 'react';
import { EARNINGS_ENDPOINT, EARNINGS_PAYMENTS_LIST_TABLE_VIEW } from 'utils/constants';
import { EarningByBase } from '../utils';
import './EarningsPaymentPage.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Payments</div>
    </div>
    <div className="EarningsPayment__center">
      <BalanceManager />
      {/*<AvailableAccountsList />*/}
    </div>
    <div className="EarningsPayment__info">
      Payments are delivered to your specified bank account. If you have any problems with receiving
      your payments, or if you need any additional information, please contact your account manager.
    </div>
  </Fragment>
);

const EarningsPaymentPage = props => (
  <EarningByBase
    {...props}
    url={EARNINGS_ENDPOINT.PAYMENT}
    topBar={<HeaderOfPage />}
    tableViewType={EARNINGS_PAYMENTS_LIST_TABLE_VIEW}
  />
);

export default EarningsPaymentPage;
