import * as types from './types';

export const initialState = {
  value: '',
};

function asset(state = initialState, action) {
  switch (action.type) {
    case types.SEARCH:
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
}

export default asset;
