import { ReportIcons } from 'components';
import { YoutubeVideoFilterForm } from 'components/Forms/FiltersForm';
import React, { Fragment } from 'react';
import { EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW, EARNINGS_ENDPOINT, FORMS } from 'utils/constants';
import { EarningByBase } from '../utils';
import './EarningsByYoutubeVideo.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Earnings By Youtube Video</div>
    </div>
    <div className="EarningsByYoutubeVideo__center">
      <span className="EarningsByYoutubeVideo__filters">
        <YoutubeVideoFilterForm form={FORMS.EARNINGS_YOUTUBE_VIDEO} />
      </span>
      <ReportIcons url={EARNINGS_ENDPOINT.EARNINGS_YOUTUBE_VIDEO} />
    </div>
  </Fragment>
);

const EarningsByYoutubeVideo = props => (
  <EarningByBase
    {...props}
    url={EARNINGS_ENDPOINT.BY_YOUTUBE_VIDEO}
    topBar={<HeaderOfPage />}
    tableViewType={EARNINGS_BYYOUTUBEVIDEO_LIST_TABLE_VIEW}
  />
);

export default EarningsByYoutubeVideo;
