import { change } from 'redux-form';
import AssetFilterForm from './AssetFilterForm';
import { MODALS } from 'utils/constants';
import { connect } from 'react-redux';
import { actions } from 'ducks/earnings/results';
import { pushModal } from 'ducks/ui/modal';
import { updateAppliedFiltersDisplay } from 'ducks/ui/appliedFiltersDisplay';
import { hideModal } from 'utils/modals';
import {
  earningsFilterMapStateToProps as mapStateToProps,
  initReinitializedReduxForm,
} from '../utils';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    pushCustomRangeModal: () => {
      dispatch(
        pushModal({
          type: MODALS.CUSTOM_RANGE,
          props: {
            hide: hideModal(dispatch),
            form: ownProps.form,
            url: ownProps.url,
          },
        })
      );
    },
    changeRange: ({ reduxChanges }) => {
      dispatch(change(ownProps.form, 'earningsFrom', reduxChanges[0]));
      dispatch(change(ownProps.form, 'earningsTo', reduxChanges[1]));
      dispatch(
        updateAppliedFiltersDisplay({ earningsFrom: reduxChanges[0], earningsTo: reduxChanges[1] })
      );
    },
    changeParams: ({ params, shouldFetch }) => {
      dispatch(actions.resetAssetsPagination());
      dispatch(
        actions.changeRequestedAssetsParams({
          params: {
            url: ownProps.url,
            ...params,
          },
          shouldFetch,
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(initReinitializedReduxForm(AssetFilterForm));
