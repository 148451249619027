import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseSVG } from 'assets/remove-icon.svg';
import { __INFO__, __WARNING__ } from 'ducks/ui/snackbar';
import './SnackbarItem.scss';

function SnackbarItem({ snackbar, clearSnackbar }) {
  const { id, type, content } = snackbar;
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(clearSnackbar, 6000);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div role="status" className={`SnackbarItem SnackbarItem--${type}`} key={id}>
      <span className="SnackbarItem-content">{content}</span>
      <button type="button" className="SnackbarItem-close" onClick={clearSnackbar} title="Dismiss">
        <CloseSVG />
      </button>
    </div>
  );
}

SnackbarItem.propTypes = {
  snackbar: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    type: PropTypes.oneOf([__INFO__, __WARNING__]).isRequired,
  }).isRequired,
  clearSnackbar: PropTypes.func.isRequired,
};

export default SnackbarItem;
