import dayjs from 'dayjs';
import { EARNINGS_INITIAL_VALUES } from 'utils/constants';

const hasFulfilledInputs = fromProps => {
  const ADVANCED_FILTER_PROPS_TO_VALIDATE = [
    'earningsAssetTypeAlbum',
    'earningsAssetTypeTrack',
    'earningsReportedEarningsMax',
    'earningsReportedEarningsMin',
    'earningsTo',
    'earningsFrom',
  ];
  const earningsTypeIsUpdated =
    fromProps.earningsAdvancedFilters !== EARNINGS_INITIAL_VALUES.earningsAdvancedFilters;
  const fulfilledInputs = ADVANCED_FILTER_PROPS_TO_VALIDATE.filter(prop => fromProps[prop]);
  return Boolean(fulfilledInputs.length) || earningsTypeIsUpdated;
};

export const isRequired = value => (value ? undefined : 'This field is required');

export const isCorrectDateFormatFromTo = values => {
  const errors = {};
  const arrayOfErrors = [];
  if (!hasFulfilledInputs(values)) {
    arrayOfErrors.push(
      'You need to fill at least one of the filters: Month Range, Reported Earnings, Asset Types or Earnings Type'
    );
  }
  if (values.earningsTo && values.earningsFrom) {
    if (dayjs(values.earningsTo).isBefore(dayjs(values.earningsFrom))) {
      arrayOfErrors.push('Start and end dates should not overlap with each other');
    }
  }

  if ((values.earningsTo && !values.earningsFrom) || (!values.earningsTo && values.earningsFrom)) {
    arrayOfErrors.push('You need to fill both start and end dates');
  }
  if (
    (values.earningsReportedEarningsMin && !values.earningsReportedEarningsMax) ||
    (!values.earningsReportedEarningsMin && values.earningsReportedEarningsMax)
  ) {
    arrayOfErrors.push('You need to fill both min and max earnings');
  }
  if (parseInt(values.earningsReportedEarningsMax) < parseInt(values.earningsReportedEarningsMin)) {
    arrayOfErrors.push('Reported earning max value must be bigger than min');
  }
  if (arrayOfErrors.length) {
    errors._error = arrayOfErrors;
  }
  return errors;
};

export const maxAllowedPayment = values => {
  const errors = {};
  if (values.payment_amount > values.max_amount) {
    errors._error = 'You can not request more money that you have';
  }
  return errors;
};

export const hasNoSpecialChars = value =>
  /\\|\/|"|\?|:|\*|\||'/.test(value)
    ? 'The following characters are not allowed:  \\ / : ?  * " |'
    : undefined;

export const isHfaCode = value => {
  if (value && (!/^[a-zA-Z0-9]/.test(value) || value.length !== 6)) {
    return 'Invalid HFA Code. Expected 6-digit alphanumerical entry';
  }

  return undefined;
};

export const isIsrcCode = value => {
  if (value && (!/^[A-Z0-9-]/.test(value) || value.length !== 15)) {
    return 'Invalid IRSC Code';
  }

  return undefined;
};

export const isUrl = value => {
  if (!value) {
    return undefined;
  }

  try {
    new URL(value);
    return undefined;
  } catch (e) {
    return 'Not a valid URL';
  }
};
