import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { guid } from 'utils/common';
import { get } from 'lodash';
import { tableItems } from '../../utils';

function Row({ viewType, asset }) {
  const assetKey = guid();
  const ViewTypeColumns = get(tableItems, [viewType, 'Columns'], 'td');
  const ViewTypeChecker = get(tableItems, [viewType, 'checker']);
  if (!ViewTypeChecker(asset)) {
    return null;
  }
  return (
    <tr key={assetKey} data-testid={assetKey} role="row">
      <ViewTypeColumns asset={asset} />
    </tr>
  );
}

Row.propTypes = {
  asset: PropTypes.oneOfType([
    PropTypes.shape(shapes.byArtist),
    PropTypes.shape(shapes.byAsset),
    PropTypes.shape(), // allow empty array ([]) as a value on this prop
  ]).isRequired,
  viewType: PropTypes.string.isRequired,
};

export default Row;
