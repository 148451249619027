import { combineReducers } from 'redux';
import snackbar, { __REDUX_STATE_KEY__ as __SNACKBAR_REDUX_STATE_KEY__ } from './snackbar';
import sidebar, { __REDUX_STATE_KEY__ as __SIDEBAR_REDUX_STATE_KEY__ } from './sidebar';
import overlays, { __REDUX_STATE_KEY__ as __OVERLAY_REDUX_STATE_KEY__ } from './overlay';
import modals, { __REDUX_STATE_KEY__ as __MODAL_REDUX_STATE_KEY__ } from './modal';
import appliedFiltersDisplay, {
  __REDUX_STATE_KEY__ as __LABEL_REDUX_STATE_KEY__,
} from './appliedFiltersDisplay';

const uiReducer = combineReducers({
  [__SNACKBAR_REDUX_STATE_KEY__]: snackbar,
  [__SIDEBAR_REDUX_STATE_KEY__]: sidebar,
  [__OVERLAY_REDUX_STATE_KEY__]: overlays,
  [__MODAL_REDUX_STATE_KEY__]: modals,
  [__LABEL_REDUX_STATE_KEY__]: appliedFiltersDisplay,
});

export default uiReducer;
