import { AdvancedFilters, ReportIcons } from 'components';
import { AssetFilterForm } from 'components/Forms/FiltersForm';
import { EarningByBase } from 'pages/utils';
import React, { Fragment } from 'react';
import { EARNINGS_BYASSET_LIST_TABLE_VIEW, EARNINGS_ENDPOINT, FORMS } from 'utils/constants';
import './EarningsByAssetPage.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">Earnings By Asset</div>
    </div>
    <div className="EarningsByAssetPage__center">
      <span className="EarningsByAssetPage__filters">
        <AssetFilterForm
          form={FORMS.EARNINGS_BY_ASSET}
          url={EARNINGS_ENDPOINT.BY_ASSET}
          placeholderSearchText="Search by title, external or custom id"
        />
      </span>
      <span className="EarningsByAssetPage__advanced_button">
        <AdvancedFilters url={EARNINGS_ENDPOINT.BY_ASSET} form={FORMS.EARNINGS_BY_ASSET} />
      </span>
      <ReportIcons url={EARNINGS_ENDPOINT.BY_ASSET} />
    </div>
  </Fragment>
);

const EarningsByAssetPage = props => (
  <EarningByBase
    {...props}
    form={FORMS.EARNINGS_BY_ASSET}
    url={EARNINGS_ENDPOINT.BY_ASSET}
    topBar={<HeaderOfPage />}
    tableViewType={EARNINGS_BYASSET_LIST_TABLE_VIEW}
  />
);

export default EarningsByAssetPage;
