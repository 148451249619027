import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { MODALS } from 'utils/constants';
import './PreventUnmountModal.scss';

function PreventUnmountModal({ unmountCallback, removeModal }) {
  const hide = useCallback(() => removeModal(MODALS.PREVENT_UNMOUNT));

  const proceed = useCallback(() => {
    hide();
    unmountCallback();
  }, []);

  return (
    <Modal title="Are you sure you want to leave?" onClose={hide}>
      <div className="PreventUnmount__modal-body">
        <p>Changes you made will not be saved. Are you sure you want to leave?</p>
        <div className="PreventUnmount__modal-footer">
          <button type="button" onClick={hide} autoFocus>
            No
          </button>
          <span>|</span>
          <button type="button" onClick={proceed}>
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

PreventUnmountModal.reduxProps = {
  removeModal: PropTypes.func.isRequired,
};

PreventUnmountModal.propTypes = {
  ...PreventUnmountModal.reduxProps,
  unmountCallback: PropTypes.func.isRequired,
};

PreventUnmountModal.defaultProps = {};

export default PreventUnmountModal;
