import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { amountWithCommas, checkEmptyField } from './utils';

/**
 * By Asset Table Shape
 * Asset | ISRC | Label | UPC | Type | Service | Months | Reported Earnings | Your Earnings
 */

const CSS_CLASS_CELL_CENTERED = 'EarningsTables__cell--centered';

const ByAssetTableListViewColumnHeaders = props => (
  <React.Fragment>
    <TableHeader label="Asset" className="table-header__asset" sortKey="asset" {...props} />
    <TableHeader label="ISRC" sortKey={null} className={CSS_CLASS_CELL_CENTERED} {...props} />
    <TableHeader label="Label" sortKey={null} className={CSS_CLASS_CELL_CENTERED} {...props} />
    <TableHeader label="UPC" sortKey={null} className={CSS_CLASS_CELL_CENTERED} {...props} />
    <TableHeader
      label="Type"
      sortKey={null}
      className={`${CSS_CLASS_CELL_CENTERED} table-header__type`}
      {...props}
    />
    <TableHeader label="Service" sortKey={null} className={CSS_CLASS_CELL_CENTERED} {...props} />
    <TableHeader label="Months" sortKey={null} className={CSS_CLASS_CELL_CENTERED} {...props} />
    <TableHeader
      label="Reported Earnings"
      sortKey="reported_earnings"
      className={CSS_CLASS_CELL_CENTERED}
      {...props}
    />
    <TableHeader
      label="Your Earnings"
      sortKey="your_earnings"
      className="EarningsTables__cell--right EarningsTables__mediumGrey"
      {...props}
    />
  </React.Fragment>
);

const ByAssetTableListViewColumns = ({ asset: byAsset }) => {
  const {
    upc,
    label,
    isrc,
    asset,
    type,
    service,
    period,
    reported_earnings,
    your_earnings,
  } = byAsset;

  return (
    <React.Fragment>
      <td>{checkEmptyField(asset, 'Title')}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{isrc}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{label}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{upc}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{type}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{service}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{period}</td>
      <td className={CSS_CLASS_CELL_CENTERED}>{amountWithCommas(reported_earnings.toFixed(2))}</td>
      <td className="EarningsTables__cell--right EarningsTables__lightGrey">
        {amountWithCommas(your_earnings.toFixed(2))}
      </td>
    </React.Fragment>
  );
};

ByAssetTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.byAsset).isRequired,
};

export { ByAssetTableListViewColumnHeaders, ByAssetTableListViewColumns };
