import { connect } from 'react-redux';
import { openSidebar, closeSidebar, getSidebarOpenState } from 'ducks/ui/sidebar';
import { getUserStaffStatus } from 'ducks/user';
import { pushOverlay } from 'ducks/ui/overlay';
import Header from './Header';

const mapStateToProps = state => ({
  isStaff: getUserStaffStatus(state),
  sidebarIsOpen: getSidebarOpenState(state),
});

const mapDispatchToProps = { openSidebar, closeSidebar, pushOverlay };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
