import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarItem } from './subcomponents';
import { animated, Transition } from 'react-spring/renderprops.cjs';
import styles from './Snackbar.scss';

// the || 0, is a fallback for server-side rendering and testing. It's ignored in normal browsers.
const snackbarItemWrapperHeight =
  parseInt(styles.snackbarItemHeight) + parseInt(styles.snackbarItemVerticalGap) || 0;

function Snackbar({ snackbars, removeSnackbar }) {
  return (
    <div className="Snackbar">
      <Transition
        items={snackbars}
        keys={snackbars.map(({ id }) => id)}
        native
        from={{ display: 'flex', opacity: 0, height: 0 }}
        enter={{ opacity: 1, height: snackbarItemWrapperHeight }}
        leave={{ opacity: 0, height: 0, pointerEvents: 'none' }}
      >
        {snackbar => (transitionStyles /* eslint-disable-line */) => (
          <animated.div style={transitionStyles}>
            <SnackbarItem
              snackbar={snackbar}
              key={snackbar.id}
              clearSnackbar={() => removeSnackbar(snackbar.id)}
            />
          </animated.div>
        )}
      </Transition>
    </div>
  );
}

Snackbar.propTypes = {
  removeSnackbar: PropTypes.func.isRequired,
  snackbars: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Snackbar;
