import React from 'react';
import './NotFoundPage.scss';

function NotFoundPage() {
  return (
    <div className="NotFoundPage">
      <h1>Not all those who wander are lost</h1>
      <h2>( You totally are though )</h2>
    </div>
  );
}

export default NotFoundPage;
