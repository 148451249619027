import scopedSelectors from 'ducks/asset/factory/scopedSelectors';
import { createSelector } from 'reselect';
import { getUpdatedInitialFormValues } from 'utils/filters';

const __REDUX_STATE_KEY__ = 'results';
const scopedSelectorsList = scopedSelectors(state => state.earnings[__REDUX_STATE_KEY__], {
  details: false,
});
const getTotal = state => state.earnings.totalEarnings.total;
const getAvailableAccounts = state => state.earnings.totalEarnings.availableAccounts;
const getCurrentBalance = state => state.earnings.totalEarnings.currentEndBalance;

const getUpdatedFormValues = createSelector(
  scopedSelectorsList.getParams,
  ({ period, service, earnings_type, q }) => {
    return getUpdatedInitialFormValues({
      selectedPeriod: period,
      selectedService: service,
      selectedEarningsType: earnings_type,
      searchValue: q,
    });
  }
);

export default {
  ...scopedSelectorsList,
  __REDUX_STATE_KEY__,
  getTotal,
  getAvailableAccounts,
  getCurrentBalance,
  getUpdatedFormValues,
};
