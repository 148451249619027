function scopedTypes(prefix = '', { details = true, list = true } = {}) {
  const common = {
    ADD_REQUEST: `${prefix}ADD_REQUEST`,
    CREATE_REQUEST: `${prefix}CREATE_REQUEST`,
    UPDATE_SUCCESS: `${prefix}UPDATE_SUCCESS`,
    DELETE_REQUEST: `${prefix}DELETE_REQUEST`,
    DELETE_SUCCESS: `${prefix}DELETE_SUCCESS`,
    SINGLE_UPDATE_REQUEST: `${prefix}SINGLE_UPDATE_REQUEST`,
    BULK_UPDATE_REQUEST: `${prefix}BULK_UPDATE_REQUEST`,
    ADDING_TRACKS_REQUEST: `${prefix}ADDING_TRACKS_REQUEST`,
    REMOVING_TRACKS_REQUEST: `${prefix}REMOVING_TRACKS_REQUEST`,
    SHARE_REQUEST: `${prefix}SHARE_REQUEST`,
  };

  const detailsTypes = {
    DETAILS_FETCH_REQUEST: `${prefix}DETAILS_FETCH_REQUEST`,
    DETAILS_FETCH_SUCCESS: `${prefix}DETAILS_FETCH_SUCCESS`,
    DETAILS_FETCH_FAILURE: `${prefix}DETAILS_FETCH_FAILURE`,
    DETAILS_RESET: `${prefix}DETAILS_RESET`,
  };

  const listTypes = {
    LIST_FETCH_REQUEST: `${prefix}LIST_FETCH_REQUEST`,
    LIST_FETCH_SUCCESS: `${prefix}LIST_FETCH_SUCCESS`,
    LIST_FETCH_FAILURE: `${prefix}LIST_FETCH_FAILURE`,
    LIST_RESET_PAGINATION_PARAMS: `${prefix}LIST_RESET_PAGINATION_PARAMS`,
    LIST_CHANGE_PARAMS: `${prefix}LIST_CHANGE_PARAMS`,
    LIST_ADD: `${prefix}LIST_ADD`,
    LIST_RESET: `${prefix}LIST_RESET`,
  };

  return {
    ...common,
    ...(details ? detailsTypes : {}),
    ...(list ? listTypes : {}),
  };
}

export default scopedTypes;
