import types from './types';
import actions from './actions';
import selectors from './selectors';
import scopedEpics from '../factory/scopedEpics';
import { collectionAPI, itemAPI } from 'api';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import { startSubmit, stopSubmit } from 'redux-form';
import { handleFormErrors } from 'utils/forms';

const scopedEpicsList = scopedEpics(types, actions, selectors, collectionAPI);

const uploadAlbumEpic = action$ =>
  action$.pipe(
    ofType(types.UPLOAD_ALBUM_REQUEST),
    switchMap(({ payload: { asset, tracks, onSuccess, formName } }) =>
      concat(
        of(startSubmit(formName)),
        from(itemAPI.bulk.create(tracks)).pipe(
          mergeMap(() =>
            from(collectionAPI.single.create(asset)).pipe(
              mergeMap(album => {
                onSuccess(album);
                return of(stopSubmit(formName), actions.uploadAlbumSuccess());
              })
            )
          ),
          catchError(errorData => {
            return of(stopSubmit(formName, handleFormErrors(errorData)));
          })
        )
      )
    )
  );

export default {
  ...scopedEpicsList,
  uploadAlbumEpic,
};
