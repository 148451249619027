import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './SidebarApp.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const SidebarApp = ({ children, className = '', ...rest }) => (
  <div className={`SidebarApp ${className}`} {...rest}>
    {children}
  </div>
);

SidebarApp.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

SidebarApp.Icon = ({ children, className = '', color }) => (
  <div className={`SidebarApp__icon ${className}`} style={{ backgroundColor: color }}>
    {children}
  </div>
);

SidebarApp.Label = ({ children, className = '', ...rest }) => (
  <h2 className={`SidebarApp__label ${className}`} {...rest}>
    {children}
  </h2>
);

SidebarApp.Label.propTypes = propTypes;
SidebarApp.Label.defaultProps = defaultProps;

SidebarApp.Link = ({ children, className = '', ...rest }) => (
  <NavLink
    activeClassName="SidebarApp__link--active"
    className={`SidebarApp__link ${className}`}
    {...rest}
  >
    {children}
  </NavLink>
);

SidebarApp.Link.propTypes = propTypes;
SidebarApp.Link.defaultProps = defaultProps;

SidebarApp.Content = ({ children, ...rest }) => (
  <div className="SidebarApp__content" {...rest}>
    {children}
  </div>
);

SidebarApp.Content.propTypes = propTypes;
SidebarApp.Content.defaultProps = defaultProps;

SidebarApp.Icon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

SidebarApp.Icon.defaultProps = {
  className: '',
  color: undefined,
};

SidebarApp.Icon.displayName = 'SidebarApp.Icon';
SidebarApp.Label.displayName = 'SidebarApp.Label';
SidebarApp.Link.displayName = 'SidebarApp.Link';
SidebarApp.Content.displayName = 'SidebarApp.Content';

export default SidebarApp;
