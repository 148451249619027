// when an impersonation is happening and we want to return to the original user that started the
import { mediaLibAxios, earningsAxios, orfiumAxios } from 'api/axiosInstances';
import { pushInfoSnackbar, pushWarningSnackbar } from 'ducks/ui/snackbar';
import { fetchUserInfoRequest } from 'ducks/user/actions';
import {
  __IMPERSONATOR_TOKEN_COOKIE__,
  __TOKEN_COOKIE__,
  __ACCOUNT_COOKIE__,
} from 'utils/constants';
// impersonation then we call this action creator. This supposes that you have logged
import cookies from 'utils/cookies';

function refreshPage() {
  window && window.location.reload(false);
}

function propagateTokenChanges(dispatch, token) {
  // store the token into cookies for SSR
  cookies.set(__TOKEN_COOKIE__, token);

  // add a default Authorization header to every request
  setGlobalHeader('Authorization', `Token ${token}`);

  // clear account header
  setEarningsHeader('Account', null);

  // ask for the user's basic details and as soon as they come back from the server, update
  // the auth token. We should only update the authenticated state when the user's info is
  // also present in the store
  dispatch(fetchUserInfoRequest());
}

function setGlobalHeader(header, value) {
  if (value) {
    mediaLibAxios.defaults.headers.common[header] = value;
    earningsAxios.defaults.headers.common[header] = value;
    orfiumAxios.defaults.headers.common[header] = value;
  } else {
    delete mediaLibAxios.defaults.headers.common[header];
    delete earningsAxios.defaults.headers.common[header];
    delete orfiumAxios.defaults.headers.common[header];
  }
}

export function setEarningsHeader(header, value) {
  if (value) {
    earningsAxios.defaults.headers.common[header] = value;
  } else {
    delete earningsAxios.defaults.headers.common[header];
  }
}

export function cancelImpersonation(
  onSuccess = () => {
    refreshPage();
  },
  onError = () => {}
) {
  return function(dispatch) {
    try {
      // run the code that reverts the`token` cookies to their original state
      propagateTokenChanges(dispatch, cookies.get(__IMPERSONATOR_TOKEN_COOKIE__));

      // we will no longer be impersonating so we don't need this token stored
      cookies.remove(__IMPERSONATOR_TOKEN_COOKIE__);

      // callback for successful cancelling
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };
}

export function impersonate(username) {
  return function(dispatch) {
    return orfiumAxios
      .get(`/impersonate/${username}/`)
      .then(response => {
        // store the original cookie of the one that performed the impersonation
        // only if another impersonator-token does not exist, otherwise we lose the original user
        if (!cookies.get(__IMPERSONATOR_TOKEN_COOKIE__)) {
          cookies.set(__IMPERSONATOR_TOKEN_COOKIE__, cookies.get(__TOKEN_COOKIE__));
        }

        // run the code that replaces the current `token` cookies in order to impersonate
        propagateTokenChanges(dispatch, response.data.key);

        // inform the user that he's now impersonating
        dispatch(pushInfoSnackbar(`✓ Impersonating: ${username}`));
        return response;
      })
      .catch(error => {
        let message = 'An unknown error has occurred during your impersonation.';
        const { response } = error;

        if (response && (response.status === 403 || response.status === 401)) {
          message = `You don't have enough rights to impersonate ${username}`;
        }

        dispatch(pushWarningSnackbar(message, 'error'));
        return error;
      })
      .finally(() => {
        refreshPage();
      });
  };
}

export function selectOrganization(organization) {
  return function(dispatch) {
    if (organization) {
      cookies.set(__ACCOUNT_COOKIE__, organization.orfium_uuid);
      dispatch(pushInfoSnackbar(`✓ Showing data for ${organization.name}`));
    } else {
      cookies.remove(__ACCOUNT_COOKIE__);
    }

    refreshPage();
  };
}
