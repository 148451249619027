import axios from 'axios';

export const mediaLibAxios = axios.create({
  baseURL: process.env.REACT_APP_LIBRARY_API_SERVER_URL,
});

export const orfiumAxios = axios.create({
  baseURL: process.env.REACT_APP_ORFIUM_API_SERVER_URL,
});

export const earningsAxios = axios.create({
  baseURL: process.env.REACT_APP_EARNINGS_API_SERVER_URL,
});
