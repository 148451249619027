import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { CustomRangeFilterForm } from 'components/Forms';
import './CustomRangeFilterModal.scss';

const CustomRangeFilterModal = ({ hide, form, url, shouldResetEarningsPeriodFilter }) => (
  <Modal
    title="Custom Range Filters"
    onClose={() => hide(shouldResetEarningsPeriodFilter, form)}
    data-testid="customrange-modal"
  >
    <div className="CustomRangeFilterModal">
      <CustomRangeFilterForm form={form} url={url} />
    </div>
  </Modal>
);

CustomRangeFilterModal.propTypes = {
  hide: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  shouldResetEarningsPeriodFilter: PropTypes.bool.isRequired,
};

export default CustomRangeFilterModal;
