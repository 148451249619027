import { guid } from 'utils/common';
import { omit, without } from 'lodash';
import * as types from './types';

const initialState = {
  byKeyId: {},
  keys: [],
};

function snackbar(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SNACKBAR: {
      const uniqueId = guid();
      return {
        byKeyId: { ...state.byKeyId, [uniqueId]: action.payload },
        keys: [uniqueId, ...state.keys],
      };
    }
    case types.DELETE_SNACKBAR:
      return {
        byKeyId: omit(state.byKeyId, action.payload),
        keys: without(state.keys, action.payload),
      };
    default:
      return state;
  }
}

export default snackbar;
