import { ReportIcons } from 'components';
import { SummaryRevenueFilterForm } from 'components/Forms/FiltersForm';
import { EarningByBase } from 'pages/utils';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import {
  EARNINGS_ENDPOINT,
  EARNINGS_REVENUE_FOOTER_TABLE_VIEW,
  EARNINGS_REVENUE_LIST_TABLE_VIEW,
  FIXED_EARNINGS_PARAMS,
  FORMS,
} from 'utils/constants';
import './EarningsRevenueStream.scss';

const HeaderOfPage = () => (
  <Fragment>
    <div className="EarningsSummaryPage__titleContainer">
      <div className="EarningsSummaryPage__title">By Revenue Stream</div>
    </div>
    <div className="EarningsRevenueStream__center">
      <span className="EarningsRevenueStream__filters">
        <SummaryRevenueFilterForm
          form={FORMS.EARNINGS_REVENUE_STREAM}
          url={EARNINGS_ENDPOINT.BY_REVENUE}
        />
      </span>
      <span className="EarningsRevenueStream__report_icons">
        <ReportIcons url={EARNINGS_ENDPOINT.BY_REVENUE} />
      </span>
    </div>
  </Fragment>
);

const EarningsRevenueStream = props => {
  // Because I use paginated reducer for no paginated data, I need to pass the initial Params before the the fetchMore function.
  // This is a weird hack. It works. Leave it!!!
  const { changeParams } = props;
  useEffect(() => {
    changeParams({
      params: {
        ...FIXED_EARNINGS_PARAMS,
      },
      shouldFetch: true,
    });
  }, []);
  return (
    <EarningByBase
      {...props}
      form={FORMS.EARNINGS_REVENUE_STREAM}
      url={EARNINGS_ENDPOINT.BY_REVENUE}
      topBar={<HeaderOfPage />}
      tableViewType={EARNINGS_REVENUE_LIST_TABLE_VIEW}
      footerViewType={EARNINGS_REVENUE_FOOTER_TABLE_VIEW}
    />
  );
};

EarningsRevenueStream.propTypes = {
  changeParams: PropTypes.any.isRequired,
};

export default EarningsRevenueStream;
