const urls = {
  earningsDashboard: function() {
    return '/earnings/';
  },
  paypalAuth: function() {
    return '/paypal-auth/';
  },
  earnings: {
    summary() {
      return `${urls.earningsDashboard()}summary/`;
    },
    revenueStream() {
      return `${urls.earningsDashboard()}revenue/`;
    },
    asset() {
      return `${urls.earningsDashboard()}asset/`;
    },
    artist() {
      return `${urls.earningsDashboard()}artist/`;
    },
    youtubeVideo() {
      return `${urls.earningsDashboard()}youtube-video/`;
    },
    youtubeChannel() {
      return `${urls.earningsDashboard()}youtube-channel/`;
    },
    payments() {
      return `${urls.earningsDashboard()}payments/`;
    },
  },
};

export default urls;
