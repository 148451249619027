import React from 'react';
import PropTypes from 'prop-types';
import * as shapes from 'shapes';
import { TableHeader } from 'components/utils/Table';
import { negativeCSSclass, amountWithCommas } from './utils';

const RevenueTableListViewCollumnHeaders = props => (
  <React.Fragment>
    <TableHeader label="Revenue Stream" sortKey={null} {...props} />
    <TableHeader
      label="Period"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Gross Earnings"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Transaction Fees"
      sortKey={null}
      className="EarningsTables__cell--centered"
      {...props}
    />
    <TableHeader
      label="Orfium Share"
      sortKey={null}
      className="EarningsTables__cell--centered EarningsTables__lightMediumGrey"
      {...props}
    />
    <TableHeader
      label="Net Earnings"
      sortKey={null}
      className="EarningsTables__cell--right EarningsTables__mediumGrey"
      {...props}
    />
  </React.Fragment>
);

const RevenueTableListViewColumns = ({ asset: revenueStream }) => (
  <React.Fragment>
    <td>{revenueStream.revenue_stream}</td>
    <td className="EarningsTables__cell--centered">{revenueStream.period}</td>
    <td className="EarningsTables__cell--centered">
      {amountWithCommas(revenueStream.gross_earnings)}
    </td>
    <td
      className={`EarningsTables__cell--centered ${negativeCSSclass(
        revenueStream.transaction_fees
      )}`}
    >
      {amountWithCommas(-revenueStream.transaction_fees) || 0}
    </td>
    <td
      className={`EarningsTables__cell--centered EarningsTables__lighterGrey ${negativeCSSclass(
        revenueStream.orfium_share
      )}`}
    >
      {amountWithCommas(-revenueStream.orfium_share) || 0}
    </td>
    <td className="EarningsTables__cell--right EarningsTables__lightGrey">
      {amountWithCommas(revenueStream.net_earnings)}
    </td>
  </React.Fragment>
);

RevenueTableListViewColumns.propTypes = {
  asset: PropTypes.shape(shapes.byRevenueStream).isRequired,
};

export { RevenueTableListViewCollumnHeaders, RevenueTableListViewColumns };
