import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './SidebarFolder.scss';

const SidebarFolder = ({ children, withContents, ...rest }) => {
  return (
    <div
      className={`SidebarFolder ${withContents ? 'SidebarFolder--with-contents' : ''}`}
      {...rest}
    >
      {children}
    </div>
  );
};

SidebarFolder.Icon = ({ children }) => <div className="SidebarFolder__icon">{children}</div>;

SidebarFolder.Label = ({ children, ...rest }) => (
  <h2 className="SidebarFolder__label" {...rest}>
    {children}
  </h2>
);

SidebarFolder.Content = ({ children, ...rest }) => (
  <div className="SidebarFolder__folders" {...rest}>
    {children}
  </div>
);

SidebarFolder.Link = ({ to, children, ...rest }) => (
  <NavLink
    className="SidebarFolder__link"
    activeClassName="SidebarFolder__link--active"
    to={to}
    exact
    {...rest}
  >
    {children}
  </NavLink>
);

SidebarFolder.Icon.propTypes = {
  children: PropTypes.node.isRequired,
};

SidebarFolder.Content.propTypes = {
  children: PropTypes.node.isRequired,
};

SidebarFolder.Label.propTypes = {
  children: PropTypes.node.isRequired,
};

SidebarFolder.Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SidebarFolder.propTypes = {
  children: PropTypes.node.isRequired,
  withContents: PropTypes.bool,
};

SidebarFolder.defaultProps = {
  withContents: false,
};

SidebarFolder.Icon.displayName = 'SidebarFolder.Icon';
SidebarFolder.Label.displayName = 'SidebarFolder.Label';
SidebarFolder.Content.displayName = 'SidebarFolder.Content';
SidebarFolder.Link.displayName = 'SidebarFolder.Link';

export default SidebarFolder;
