import { connect } from 'react-redux';
import { actions } from 'ducks/earnings/results';
import { selectors } from 'ducks/earnings/results';
import { getAppliedFiltersDisplayShow } from 'ducks/ui/appliedFiltersDisplay';
import EarningsByArtistVideo from './EarningsByArtistVideo';
import { reset } from 'redux-form';
import { FORMS, EARNINGS_ENDPOINT, FIXED_EARNINGS_PARAMS } from 'utils/constants';

const mapStateToProps = state => ({
  isFetching: selectors.getIsFetching(state),
  results: selectors.getItems(state),
  hasMore: selectors.getHasMore(state),
  isInitialFetch: selectors.getIsInitialFetch(state),
  params: selectors.getParams(state),
  shouldDisplayAppliedFilters: getAppliedFiltersDisplayShow(state),
});

const url = EARNINGS_ENDPOINT.BY_ARTIST;

const mapDispatchToProps = dispatch => {
  return {
    hide: () => {
      dispatch(actions.resetAssetsListState());
      dispatch(reset(FORMS.EARNINGS_BY_ARTIST));
    },
    reset: () => dispatch(actions.resetAssetsListState()),
    changeParams: ({ params, shouldFetch }) => {
      dispatch(actions.resetAssetsPagination());
      dispatch(actions.changeRequestedAssetsParams({ params: { url, ...params }, shouldFetch }));
    },
    fetchMore: ({ params, isLoading }) => {
      dispatch(
        actions.fetchAssetsListRequest({
          params: { url, ...FIXED_EARNINGS_PARAMS, ...params },
          isLoading,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EarningsByArtistVideo);
